
import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    logout() {
      this.$store.dispatch("hideShoppingCart");
      this.$store.dispatch("logout");
    },
    login() {
      this.$store.dispatch("hideShoppingCart");
      this.$router.push("/login");
    },
    register() {
      this.$store.dispatch("hideShoppingCart");
      this.$router.push("/registration");
    },
  },
  computed: {
    textSignIn() {
      return this.$store.getters.settings?.textSignIn ?? "Přihlášení";
    },
    textSignInButton() {
      return this.$store.getters.settings?.textSignInButton ?? "Přihlásit se";
    },
    textRegistrationButton() {
      return this.$store.getters.settings?.textRegistrationButton ?? "Registrovat se";
    },
    textSignOut() {
      return this.$store.getters.settings?.textSignOut ?? "Odhlásit se";
    },
    avatarUrl() {
      return this.$store.state.user?.avatarUrl ?? "/assets/images/avatar.jpg";
    },
  },
});

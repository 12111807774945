
import { defineComponent } from "vue";
import { ProductLicenseDetailModel, AddressModel } from "@/services/models";
import Multiselect from "vue-multiselect";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required, email, minLength } from "@vuelidate/validators";
import moment from "moment";

interface LicenseDetailViewModel {
  model: ProductLicenseDetailModel;
  assignerEmail: string;
  showNoResults: boolean;
  noResultsText: string;
  items: Array<AddressModel>;
  isLoading: boolean;
  selectedItems: Array<AddressModel>;
  error: string;
}

export default defineComponent({
  data() {
    return {
      items: [],
      selectedItems: [],
      isLoading: false,
      model: {},
      assignerEmail: "",
      showNoResults: false,
      noResultsText: "",
      error: "",
    } as LicenseDetailViewModel;
  },
  setup() {
    return { v$: useVuelidate() };
  },
  components: { Multiselect },
  async mounted() {
    if (!this.$route.params.id) this.$router.push(`/home`);

    const id = parseInt(this.$route.params.id.toString());

    this.items = await this.$api.licenseService.getAssigners("");
    this.model = await this.$api.licenseService.getById(id);
  },
  methods: {
    moment,
    asyncFind(query: string) {
      this.isLoading = true;

      this.$api.licenseService.getAssigners(query).then((response) => {
        this.items = response;
        this.isLoading = false;
      });
    },
    addAssignee(newAssignee: string) {
      const emailAddress = {
        email: newAssignee,
      };
      this.items.push(emailAddress);
      this.selectedItems.push(emailAddress);
    },
    clearAll() {
      this.selectedItems = [];
    },
    limitText(count: number) {
      return `and ${count} other email address`;
    },
    removeLicense(licenseId: number) {
      this.$api.licenseService
        .removeLicense(licenseId)
        .then((result) => {
          this.model = result;
        })
        .catch(() => {
          //empty
        });
    },
    assignToLicense() {
      // validate email address
      this.v$.$touch();

      if (this.v$.$invalid) return;

      this.$api.licenseService
        .assingLicense(this.model.productId!, this.selectedItems)
        .then((result) => {
          this.model = result;
          this.selectedItems = [];
          console.log("result");
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
          this.$toast.danger("Error", err.title);
        });
    },
  },
  validations() {
    return {
      selectedItems: {
        $each: {
          email: {
            required,
            email,
          },
        },
      },
    };
  },
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "table mt-4 align-middle" }
const _hoisted_2 = { scope: "col" }
const _hoisted_3 = { scope: "col" }
const _hoisted_4 = { scope: "col" }
const _hoisted_5 = { scope: "col" }
const _hoisted_6 = {
  key: 0,
  class: "h4 text-primary text-center mt-3 no-results-text"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_license = _resolveComponent("license")!
  const _component_pagination = _resolveComponent("pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("table", _hoisted_1, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", _hoisted_2, _toDisplayString(_ctx.$store.getters.settings.textCourseName), 1),
          _createElementVNode("th", _hoisted_3, _toDisplayString(_ctx.$store.getters.settings.textTotalLicenses), 1),
          _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.$store.getters.settings.textAvaiableLicenses), 1),
          _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.$store.getters.settings.textAction), 1)
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.licenses, (license) => {
          return (_openBlock(), _createBlock(_component_license, {
            key: license.id,
            license: license
          }, null, 8, ["license"]))
        }), 128))
      ])
    ]),
    (_ctx.showNoResults && _ctx.showNoResultText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("span", { innerHTML: _ctx.noResultsText }, null, 8, _hoisted_7)
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_8, [
      _createVNode(_component_pagination, {
        total: _ctx.total,
        ref: "pagination",
        onReload: _ctx.load,
        pageSize: _ctx.pageSize
      }, null, 8, ["total", "onReload", "pageSize"])
    ], 512), [
      [_vShow, _ctx.licenses.length > 0]
    ])
  ], 64))
}
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Layout from "../views/shared/Layout.vue";
import PublicLayout from "../views/shared/PublicLayout.vue";
import Home from "../views/Home.vue";
import Categories from "../views/Categories.vue";
import Courses from "../views/Courses.vue";
import Study from "../views/Study.vue";
import Progress from "../views/Progress.vue";
import MyCourses from "../views/MyCourses.vue";
import Tutorials from "../views/Tutorials.vue";
import Guides from "../views/Guides.vue";
import Login from "../views/public/Login.vue";
import Registration from "../views/public/Registration.vue";
import PaymentCallback from "../views/PaymentCallback.vue";
import OrderDetail from "../views/order/OrderDetail.vue";
import Orders from "../views/order/Orders.vue";
import LicenseDetail from "../views/order/LicenseDetail.vue";
import Licenses from "../views/order/Licenses.vue";
import ConfirmRegistration from "../views/public/ConfirmRegistration.vue";
import ActivateAccount from "../views/public/ActivateAccount.vue";
import ResetPassword from "../views/public/ResetPassword.vue";
import ForgottenPassword from "../views/public/ForgottenPassword.vue";
import LoginToken from "../views/public/LoginToken.vue";
import TutorialDetail from "../views/TutorialDetail.vue";
import StudyPathDetail from "../views/StudyPathDetail.vue";
import Search from "../views/Search.vue";
import store from "./store";
import ProfileLayout from "../views/profile/ProfileLayout.vue";
import MyProfile from "../views/profile/MyProfile.vue";
import ChangePassword from "../views/profile/ChangePassword.vue";
import Notifications from "../views/profile/Notifications.vue";
import Gamification from "../views/gamification/Gamification.vue";
import GamificationLeaderboard from "../views/gamification/Leaderboard.vue";
import GamificationBadges from "../views/gamification/GamificationBadges.vue";
import Dashboard from "../views/Dashboard.vue";
import CourseDetail from "../views/CourseDetail.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    component: Layout,
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "/courses",
        name: "Courses",
        component: Categories,
      },
      {
        path: "/category/:id",
        name: "Category",
        component: Categories,
      },
      {
        path: "/courses/:id",
        name: "Categories",
        component: Courses,
      },
      {
        path: "/courses/:id/detail/:courseId",
        name: "CourseDetail",
        component: CourseDetail,
      },
      {
        path: "/my-courses/detail/:courseId",
        name: "MyCourseDetail",
        component: CourseDetail,
      },
      {
        path: "/courses/:id/study/:courseId",
        name: "StudyCourses",
        component: Study,
      },
      {
        path: "/my-courses/study/:courseId",
        name: "StudyMyCourses",
        component: Study,
      },
      {
        path: "/study-path/study/:studyPathItemId",
        name: "StudyPath",
        component: Study,
      },
      {
        path: "/my-courses",
        name: "MyCourses",
        component: MyCourses,
      },
      {
        path: "/progress",
        name: "Progress",
        component: Progress,
      },
      {
        path: "/tutorials",
        name: "Tutorials",
        component: Tutorials,
      },
      {
        path: "/guides",
        name: "Guides",
        component: Guides,
      },
      {
        path: "/tutorials/:id",
        name: "TutorialDetail",
        component: TutorialDetail,
      },
      {
        path: "/search",
        name: "Search",
        component: Search,
      },
      {
        path: "/paymentcallback",
        name: "Paymentcallback",
        component: PaymentCallback,
      },
      {
        path: "/orders",
        name: "Orders",
        component: Orders,
      },
      {
        path: "/order/detail/:id",
        name: "OrderDetail",
        component: OrderDetail,
      },
      {
        path: "/my-licenses/license/:id",
        name: "LicenseDetail",
        component: LicenseDetail,
      },
      {
        path: "/licenses",
        name: "Licenses",
        component: Licenses,
      },
      {
        path: "/study-path/:studyPathId",
        name: "StudyPathDetail",
        component: StudyPathDetail,
      },
      {
        path: "",
        component: ProfileLayout,
        children: [
          {
            path: "/profile/my-profile",
            name: "MyProfile",
            component: MyProfile,
          },
          {
            path: "/profile/change-password",
            name: "ChangePassword",
            component: ChangePassword,
          },
          {
            path: "/profile/notifications",
            name: "Notifications",
            component: Notifications,
          },
          {
            path: "/profile/notifications/:id",
            name: "NotificationsDetail",
            component: Notifications,
          },
        ],
      },
      {
        path: "/gamification",
        name: "Gamification",
        component: Gamification,
      },
      {
        path: "/gamification/leaderboard",
        name: "GamificationLeaderboard",
        component: GamificationLeaderboard,
      },
      {
        path: "/gamification/badges",
        name: "GamificationBadges",
        component: GamificationBadges,
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "/events",
        name: "Events",
        component: () => import("@/views/event/Events.vue"),
      },
      {
        path: "/event/:id(\\d+)/:instanceId(\\d+)?",
        name: "EventDetail",
        component: () => import("@/views/event/EventDetail.vue"),
      },
    ],
    meta: {
      authorize: true,
    },
  },
  {
    path: "",
    component: PublicLayout,
    children: [
      {
        path: "/login",
        name: "Login",
        component: Login,
      },
      {
        path: "/registration",
        name: "Registration",
        component: Registration,
      },
      {
        path: "/confirmRegistration/:tokenHash",
        name: "ConfirmRegistration",
        component: ConfirmRegistration,
      },
      {
        path: "/loginToken/:tokenHash",
        name: "LoginToken",
        component: LoginToken,
      },
      {
        path: "/activate/:token",
        name: "ActivateAccount",
        component: ActivateAccount,
      },
      {
        path: "/resetPassword/:token",
        name: "ResetPassword",
        component: ResetPassword,
      },
      {
        path: "/forgottenPassword",
        name: "ForgottenPassword",
        component: ForgottenPassword,
      },
    ],
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/public/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "active",
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (to.meta.authorize && store.state.academy.isPrivate && !store.state.user?.token) {
    next({ name: "Login" });
    return;
  }
  next();
});

export default router;


import { GamificationBadgeModel, SortOrder } from "@/services/models";
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  data() {
    return {
      badges: [] as GamificationBadgeModel[],
    };
  },
  async mounted() {
    await this.load();
  },
  methods: {
    async load() {
      const response = await this.$api.gamificationService.badgesList({
        search: "",
        page: 1,
        pageSize: 5,
        sortBy: "EarnedAt",
        sortOrder: SortOrder.Desc,
      });
      this.badges = response.data!;
    },
  },
});

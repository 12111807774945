
import { GuideModel } from "@/services/models";
import { ListOptions } from "@/utils/common";
import { defineComponent } from "vue";
import Pagination, { PaginationComponent } from "../shared/Pagination.vue";
import Guide from "@/components/guides/Guide.vue";

interface GuideTutorialViewModel {
  guides: GuideModel[];
  loaded: boolean;
  total: number;
  listOptions: ListOptions;
}

export default defineComponent({
  components: { Guide, Pagination },
  emits: ["listLoaded"],
  props: {
    pageSize: Number,
    scrollTop: Boolean,
    showNoResultText: Boolean,
  },
  data() {
    return {
      guides: [],
      loaded: false,
      total: 0,
      listOptions: {},
    } as GuideTutorialViewModel;
  },
  methods: {
    async load(options: ListOptions) {
      this.setOptions(options);
      this.loadInternal();
    },
    async loadInternal() {
      const response = await this.$api.guideService.getList({
        page: this.pagination.getPage(),
        pageSize: this.pagination.getPageSize(),
        search: this.listOptions.search ?? "",
        categoryId: this.listOptions.categoryId,
      });
      this.total = response.total!;
      this.guides = response.data!;
      this.loaded = true;
      this.$emit("listLoaded", this.total);
    },
    setOptions(options: ListOptions) {
      this.listOptions = options;
    },
    reload() {
      this.pagination.setPage(1);
    },
    async paginationLoad() {
      if (this.scrollTop) window.scrollTo(0, 0);
      await this.loadInternal();
    },
    async download(guideId: number) {
      await this.$api.guideService.download(guideId, this.$store.getters.settings.guidesOpenMode === "NewTab");

      this.$api.guideService.downloaded(guideId);
    },
  },
  computed: {
    pagination(): PaginationComponent {
      return this.$refs.pagination as PaginationComponent;
    },
    showNoResults(): boolean {
      return this.guides.length === 0 && this.loaded;
    },
    noResultsText(): string {
      return this.$store.state.academy.settings?.textSearchBoxNoResults ?? "No guides found.";
    },
  },
});

export declare class GuideListComponent {
  load: (options: ListOptions) => void;
  reload: () => void;
  setOptions: (options: ListOptions) => void;
}

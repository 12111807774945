import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0 py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        to: "/",
        class: "nav-link link-secondary"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.settings.textHomeMenuLink), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("li", null, [
      (_ctx.$store.getters.settings.coursesEnabled)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: "/courses",
            class: "nav-link link-dark"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.settings.textCategoriesMenuLink), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("li", null, [
      (_ctx.$store.getters.loggedIn)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: "/my-courses",
            class: "nav-link link-dark"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.settings.textMyCoursesMenuLink), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("li", null, [
      (_ctx.$store.getters.settings.tutorialsEnabled)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: "/tutorials",
            class: "nav-link link-dark"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.settings.textTutorialsMenuLink), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("li", null, [
      (_ctx.$store.getters.settings.guidesEnabled)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: "/guides",
            class: "nav-link link-dark"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.settings.textGuidesMenuLink), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("li", null, [
      (_ctx.$store.getters.loggedIn && _ctx.$store.getters.settings.progressEnabled)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: "/progress",
            class: "nav-link link-dark"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.settings.textProgressMenuLink), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("li", null, [
      (_ctx.$store.getters.loggedIn && _ctx.$store.getters.settings.eventsEnabled)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: "/events",
            class: "nav-link link-dark"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.settings.textEventsMenuLink), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}
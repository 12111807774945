import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "form-signin-wrapper" }
const _hoisted_2 = { class: "form-signin form-registration" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "h2 mb-3 fw-bold title" }
const _hoisted_5 = { class: "row mt-4" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = { class: "col" }
const _hoisted_9 = ["placeholder"]
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_12 = ["placeholder"]
const _hoisted_13 = ["placeholder"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = {
  key: 3,
  class: "text-danger"
}
const _hoisted_16 = { class: "sign-in-link text-center mt-3" }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = {
  class: "h-4 text-primary",
  style: {"text-align":"justify"}
}
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multiselect = _resolveComponent("multiselect")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      (!_ctx.registered)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$store.getters.settings.textRegistrationTitle), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: _normalizeClass(["form-control mb-3", { 'is-invalid': _ctx.v$.model.firstName.$error }]),
                  placeholder: _ctx.$store.getters.settings.textFirstName,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.firstName) = $event))
                }, null, 10, _hoisted_7), [
                  [_vModelText, _ctx.model.firstName]
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: _normalizeClass(["form-control mb-3", { 'is-invalid': _ctx.v$.model.lastName.$error }]),
                  placeholder: _ctx.$store.getters.settings.textLastName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.lastName) = $event))
                }, null, 10, _hoisted_9), [
                  [_vModelText, _ctx.model.lastName]
                ])
              ])
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: _normalizeClass(["form-control mb-3", { 'is-invalid': _ctx.v$.model.email.$error || _ctx.exists }]),
              placeholder: _ctx.$store.getters.settings.textEmail,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.email) = $event))
            }, null, 10, _hoisted_10), [
              [_vModelText, _ctx.model.email]
            ]),
            (_ctx.exists)
              ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$store.getters.settings.textRegistrationUserExists), 1))
              : _createCommentVNode("", true),
            (_ctx.isAllowedDomain)
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 1,
                  type: "password",
                  class: _normalizeClass(["form-control mb-3", {
            'is-invalid': _ctx.v$.model.password && _ctx.v$.model.password.$error,
          }]),
                  placeholder: _ctx.$store.getters.settings.textPassword,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.password) = $event))
                }, null, 10, _hoisted_12)), [
                  [_vModelText, _ctx.model.password]
                ])
              : _createCommentVNode("", true),
            (_ctx.isAllowedDomain)
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 2,
                  type: "password",
                  class: _normalizeClass(["form-control mb-3", {
            'is-invalid': _ctx.v$.model.confirmPassword && _ctx.v$.model.confirmPassword.$error,
          }]),
                  placeholder: _ctx.$store.getters.settings.textConfirmPassword,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.confirmPassword) = $event))
                }, null, 10, _hoisted_13)), [
                  [_vModelText, _ctx.model.confirmPassword]
                ])
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "groups-text",
              innerHTML: _ctx.$store.getters.settings.textRegistrationBeforeCategories
            }, null, 8, _hoisted_14),
            _createVNode(_component_multiselect, {
              modelValue: _ctx.selectedGroups,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedGroups) = $event)),
              options: _ctx.init.groups,
              multiple: true,
              placeholder: _ctx.$store.getters.settings.textRegistrationSelectCategories,
              selectLabel: '',
              closeOnSelect: false,
              class: "mb-3",
              "track-by": "id",
              label: "name"
            }, null, 8, ["modelValue", "options", "placeholder"]),
            (_ctx.error)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.error), 1))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              class: "w-100 btn btn-lg btn-primary text-uppercase mt-2",
              onClick: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args)), ["prevent"]))
            }, _toDisplayString(_ctx.$store.getters.settings.textRegistrationButton), 1),
            _createElementVNode("div", _hoisted_16, [
              _createTextVNode(_toDisplayString(_ctx.$store.getters.settings.textRegistrationAlreadyUser) + " ", 1),
              _createVNode(_component_router_link, {
                to: "/login",
                class: "text-button mb-3"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$store.getters.settings.textSignIn), 1)
                ]),
                _: 1
              })
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              (!_ctx.isAllowedDomain)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    innerHTML: _ctx.settings.textRegistrationSuccessNotAllowedDomain
                  }, null, 8, _hoisted_19))
                : (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    innerHTML: _ctx.settings.textRegistrationSuccess
                  }, null, 8, _hoisted_20))
            ])
          ]))
    ])
  ]))
}
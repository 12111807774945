import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_countdown = _resolveComponent("vue-countdown")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.opensIn || _ctx.closesIn)
    ? (_openBlock(), _createBlock(_component_vue_countdown, {
        key: 0,
        class: "text-center mt-2 fw-bold",
        time: _ctx.opensIn ?? _ctx.closesIn
      }, {
        default: _withCtx(({ days, hours, minutes, seconds }) => [
          _withDirectives(_createElementVNode("i", {
            class: _normalizeClass([_ctx.opensIn ? 'bi-unlock' : 'bi-lock', "bi"])
          }, null, 2), [
            [_directive_tooltip, _ctx.opensIn ? 'Opens in' : 'Closes in']
          ]),
          _createTextVNode(" " + _toDisplayString(days) + "d " + _toDisplayString(hours) + "h " + _toDisplayString(minutes) + "m " + _toDisplayString(seconds) + "s ", 1)
        ]),
        _: 1
      }, 8, ["time"]))
    : _createCommentVNode("", true)
}

import { AcademySettingsModel } from "@/services/models";
import { defineComponent } from "vue";

export default defineComponent({
  computed: {
    settings() {
      return this.$store.state.academy.settings as AcademySettingsModel;
    },
  },
});

import router from "@/init/router";
import { SortOrder } from "@/services/models";

export function delay(ms: number) {
  let timer = 0;
  return function (callback: () => void) {
    window.clearTimeout(timer);
    timer = window.setTimeout(callback, ms);
  };
}
export type delayCallback = (callback: () => void) => void;

export function createQueryString(queryParams: any): string {
  return new URLSearchParams(Object.entries(queryParams)).toString().replaceAll("undefined", "").replaceAll("null", "");
}

export type ListRequest = {
  page: number;
  pageSize: number;
  sortBy?: string;
  sortOrder?: SortOrder;
};

export function saveAs(response: any, fileName?: string | null) {
  const fileURL = window.URL.createObjectURL(new Blob([response.data]));
  const fileLink = document.createElement("a");

  if (!fileName) {
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/g;
    const match = regex.exec(response.headers["content-disposition"]);
    fileName = match![1].replace(/"/g, "").replace(/'/g, "");
  }

  fileLink.href = fileURL;
  fileLink.setAttribute("download", fileName);
  document.body.appendChild(fileLink);

  fileLink.click();
}

export function showFile(response: any) {
  const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: response.headers["content-type"] }));
  window.open(fileURL);
}

export interface ListOptions {
  search?: string;
  tagId?: number | null;
  categoryId?: number | null;
}

export function elementsSameHeight(elementsClass: string) {
  const items = [...document.getElementsByClassName(elementsClass)] as HTMLElement[];
  const maxHeight = Math.max(...items.map((m) => m.clientHeight));
  for (const item of items) {
    item.style.height = `${maxHeight}px`;
  }
}

export function saveBackRoute() {
  localStorage.setItem("backUrlFromStudyPage", router.currentRoute.value.fullPath);
}

export function getBackRoute(): string {
  return localStorage.getItem("backUrlFromStudyPage") ?? "";
}


import { computed, defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useRouteNumberParams } from "@/composables/useRouteNumberParams";
import Tabs from "@/components/shared/tabs/Tabs.vue";
import Tab from "@/components/shared/tabs/Tab.vue";
import EventInstancePanel from "@/components/events/EventInstancePanel.vue";
import { ApiClient } from "@/services/apiClient";
import type { EventDetailModel, EventFileModel } from "@/services/models";
import { useAcademySettings } from "@/init/store";
import { saveAs } from "@/utils/common";
import RatingSummary from "@/components/rating/RatingSummary.vue";
import RatingList, { RatingListComponent } from "@/components/rating/RatingList.vue";
import Axios from "axios";
import appBus from "@/appBus";
import { waitForValue } from "@/utils/waitForValue";

export default defineComponent({
  components: { Tab, Tabs, EventInstancePanel, RatingSummary, RatingList },
  setup() {
    const ratingList = ref(null as RatingListComponent | null);

    const { settings } = useAcademySettings();
    const router = useRouter();
    const { id, instanceId } = useRouteNumberParams("id", "instanceId");

    if (!id) {
      router.push({ name: "Home" });
      return;
    }

    const event = ref(null as null | EventDetailModel);

    const load = async () => {
      try {
        event.value = await ApiClient.eventService.event(id);
      } catch {
        router.push({ name: "Home" });
      }

      waitForValue(ratingList, async (ratings) => {
        if (event.value?.ratings) {
          await ratings.initList(event.value.ratings);
        } else {
          await ratings.load();
        }
      });
    };

    onMounted(load);

    const goToEvents = () => router.push({ name: "Events" });

    const showInstance = computed(() => event.value?.nextInstanceId || instanceId);

    const hasFiles = computed(() => {
      if (!event.value?.files) return false;
      return event.value.files.length > 0;
    });

    const hasContent = computed(() => {
      if (!event.value?.content) return false;
      return event.value.content.length > 0;
    });

    const download = async (file: EventFileModel) => {
      if (!file.url || !file.originalFileName) return;

      const axios = Axios.create();
      try {
        appBus.emit("showOverlay", true);
        const response = await axios.get(file.url, {
          responseType: "blob",
        });

        saveAs(response, file.originalFileName);
      } finally {
        appBus.emit("showOverlay", false);
      }
    };

    return { settings, eventId: id, event, instanceId, showInstance, goToEvents, hasFiles, hasContent, download, ratingList };
  },
});

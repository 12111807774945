
import { defineComponent } from "vue";

export default defineComponent({
  computed: {
    logoUrl() {
      return this.$store.state.academy.logoUrl;
    },
  },
});

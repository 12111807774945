import { AxiosInstance, CancelToken } from "axios";
import { StudyPathModel } from "@/services/models";

export default class StudyPathService {
  private api: AxiosInstance;
  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async get(id: number, cancelToken?: CancelToken): Promise<StudyPathModel> {
    const response = await this.api.get(`/api/v1/studyPaths/${id}`, {
      cancelToken,
    });
    return response.data;
  }
}

import { AxiosInstance, CancelToken } from "axios";
import { CommentModel, CommentModelListResponse, CreateCommentRequest } from "@/services/models";
import { createQueryString, ListRequest } from "@/utils/common";

export default class CommentService {
  private api: AxiosInstance;
  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async getList(request: CommentListRequest, cancelToken?: CancelToken): Promise<CommentModelListResponse> {
    const response = await this.api.get("/api/v1/comments?" + createQueryString(request), {
      cancelToken,
    });
    return response.data;
  }

  async create(request: CreateCommentRequest, cancelToken?: CancelToken): Promise<CommentModel> {
    const response = await this.api.post("/api/v1/comments", request, {
      cancelToken,
    });
    return response.data;
  }
}

interface CommentListRequest extends ListRequest {
  search: string;
  portalProductId?: number | null;
  parentCommentId?: number | null;
}

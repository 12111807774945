import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-4" }
const _hoisted_2 = { class: "card-body d-flex flex-column align-items-center" }
const _hoisted_3 = { class: "h5" }
const _hoisted_4 = { class: "mb-2" }
const _hoisted_5 = { class: "fw-bold" }
const _hoisted_6 = { class: "text-muted ms-2" }
const _hoisted_7 = { class: "w-100" }
const _hoisted_8 = { class: "progress" }
const _hoisted_9 = { class: "levels pt-2 d-flex justify-content-between" }
const _hoisted_10 = { class: "fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$store.getters.fullName), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.model.currentLevel), 1),
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.model.points) + " " + _toDisplayString(_ctx.$getSettings("textGamificationPoints")), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", {
            class: "progress-bar",
            style: _normalizeStyle({ width: _ctx.progressPercent + '%' })
          }, null, 4)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.pointsToCompleteNextLevel) + " " + _toDisplayString(_ctx.$getSettings("textGamificationPoints")) + " " + _toDisplayString(_ctx.$getSettings("textGamificationPointsToNextLevel")), 1),
          _createElementVNode("div", null, _toDisplayString(_ctx.model.nextLevel), 1)
        ])
      ])
    ])
  ]))
}
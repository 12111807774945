import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h4 text-primary text-center mt-3 no-results-text"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-3" }
const _hoisted_4 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_academy_course = _resolveComponent("academy-course")!
  const _component_pagination = _resolveComponent("pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showNoResults && _ctx.showNoResultText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", { innerHTML: _ctx.noResultsText }, null, 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.courses, (course) => {
        return (_openBlock(), _createBlock(_component_academy_course, {
          key: course.id,
          course: course,
          categoryId: this.listOptions.categoryId
        }, null, 8, ["course", "categoryId"]))
      }), 128))
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _createVNode(_component_pagination, {
        ref: "pagination",
        pageSize: _ctx.pageSize,
        onReload: _ctx.paginationLoad
      }, null, 8, ["pageSize", "onReload"])
    ], 512), [
      [_vShow, _ctx.courses.length > 0]
    ])
  ], 64))
}

import UserCourse from "@/components/courses/UserCourse.vue";
import CourseInfoModal, { CourseInfoModalComponent } from "@/components/courses/CourseInfoModal.vue";
import { StudyPathItemModel, StudyPathModel, UserCourseModel } from "@/services/models";
import { sortBy } from "lodash";
import { defineComponent } from "vue";
import { Popover } from "bootstrap";
import CourseCountdown from "@/components/courses/CourseCountdown.vue";
import CircleProgress from "@/components/progress/CircleProgress.vue";
import RatingInfo from "@/components/rating/RatingInfo.vue";
import { elementsSameHeight, getBackRoute } from "@/utils/common";

interface StudyPathViewModel {
  id: number;
  items: StudyPathItemModel[];
  path: StudyPathModel;
  groupInfos: Popover[];
}

export default defineComponent({
  components: {
    UserCourse,
    CourseInfoModal,
    CourseCountdown,
    CircleProgress,
    RatingInfo,
  },
  data() {
    return {
      id: 0,
      path: {},
      items: [],
      groupInfos: [],
    } as StudyPathViewModel;
  },
  async mounted() {
    const id = this.$route.params.studyPathId;
    if (!id) {
      this.backToCourses();
      return;
    }

    this.id = parseInt(id.toString());

    this.path = await this.$api.studyPathService.get(this.id);
    this.items = sortBy(this.path.items, (m) => m.displayOrder);

    this.items.forEach((item) => {
      item.items = sortBy(item.items, (m) => m.displayOrder);
    });

    this.$nextTick(() => {
      this.initPopovers();
      elementsSameHeight("course-item");
    });
  },
  unmounted() {
    this.groupInfos.forEach((item) => item.dispose());
  },
  methods: {
    backToCourses() {
      const backUrl = getBackRoute();

      if (backUrl) {
        this.$router.push(backUrl);
        return;
      }

      this.$router.push({
        name: this.$store.getters.loggedIn ? "MyCourses" : "Courses",
      });
    },
    infoShown(course: UserCourseModel, pathItem: StudyPathItemModel | null) {
      this.courseInfoModal.init(course, pathItem);
      this.courseInfoModal.show();
    },
    initPopovers() {
      const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
      this.groupInfos = popoverTriggerList.map(function (popoverTriggerEl) {
        return new Popover(popoverTriggerEl);
      });
    },
  },
  computed: {
    courseInfoModal(): CourseInfoModalComponent {
      return this.$refs.courseInfoModal as CourseInfoModalComponent;
    },
  },
});

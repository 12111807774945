
import { defineComponent } from "vue";
// import SearchList from "@/components/shared/SearchList.vue";
import { delay } from "@/utils/common";
import { Carousel, Modal } from "bootstrap";

export default defineComponent({
  data() {
    return {
      search: "",
      delaySearch: delay(450),
      modalInstance: null as Modal | null,
      show: false,
      carousel: null as Carousel | null,
    };
  },
  components: {},
  mounted() {
    const modalElement = document.getElementById("modalIntroVideo");
    if (modalElement) {
      this.modalInstance = new Modal(modalElement);
      modalElement.addEventListener("hide.bs.modal", () => {
        this.show = false;
      });
    }

    if (this.headerObjectType === "Carousel") {
      this.$nextTick(() => {
        var myCarousel = document.getElementById("headerCarousel");
        this.carousel = new Carousel(myCarousel!, {
          interval: parseInt(this.$store.getters.settings.headerCarouselInterval),
        });
        this.carousel.cycle();
      });
    }
  },
  unmounted() {
    this.carousel?.dispose();
  },
  methods: {
    redirectToCourses() {
      this.delaySearch(() => {
        if (this.search)
          this.$router.push({
            name: "Courses",
            query: { search: this.search },
          });
      });
    },
    showModal() {
      if (this.modalInstance) {
        this.show = true;
        this.modalInstance.show();
      }
    },
  },
  computed: {
    verticalLayout(): boolean {
      return this.$store.getters.settings.headerLayoutType === "Vertical";
    },
    academyName() {
      return this.$store.state.academy.settings?.textHeaderTitle ?? "";
    },
    academyDesc() {
      return this.$store.state.academy.settings?.textHeader ?? "";
    },
    videoThumbnail() {
      return this.$store.state.academy.settings?.introThumbnail ?? "";
    },
    videoUrl() {
      return this.$store.state.academy.settings?.introVideoUrl ?? "";
    },
    videoTitle() {
      return this.$store.state.academy.settings?.introVideoTitle ?? "";
    },
    videoSubTitle() {
      return this.$store.state.academy.settings?.introVideoSubTitle ?? "";
    },
    videoDesc() {
      return this.$store.state.academy.settings?.introVideoDesc ?? "";
    },
    headerObjectType() {
      return this.$store.getters.settings.headerObjectType ?? "";
    },
    headerCarouselItems(): Array<string> {
      return this.$store.getters.settings.headerCarouselItems ?? [];
    },
  },
});

import { AxiosInstance, AxiosResponse, CancelToken } from "axios";
import {
  AcademyCourseModelListResponse,
  ProductRatingCreateRequest,
  RatingBaseModel,
  ProductState,
  UserCourseModelListResponse,
  UserStudyModel,
  UserOrderModelListResponse,
  UserLicenseModelListResponse,
  ProductTypes,
  CheckProductModel,
} from "@/services/models";
import { createQueryString, ListRequest } from "@/utils/common";

export default class UserService {
  private api: AxiosInstance;
  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async getCoursesList(request: CoursesListRequest, cancelToken?: CancelToken): Promise<UserCourseModelListResponse> {
    const response = await this.api.get("/api/v1/user/courses?" + createQueryString(request), {
      cancelToken,
    });
    return response.data;
  }

  async getRelatedCoursesList(request: RelatedCoursesListRequest, cancelToken?: CancelToken): Promise<AcademyCourseModelListResponse> {
    const response = await this.api.get("/api/v1/user/relatedCourses?" + createQueryString(request), {
      cancelToken,
    });
    return response.data;
  }

  async getOrdersList(request: UserOrdersListRequest, cancelToken?: CancelToken): Promise<UserOrderModelListResponse> {
    const response = await this.api.get("/api/v1/user/orders?" + createQueryString(request), {
      cancelToken,
    });
    return response.data;
  }

  async getLicenseList(request: UserLicensesListRequest, cancelToken?: CancelToken): Promise<UserLicenseModelListResponse> {
    const response = await this.api.get("/api/v1/user/licenses?" + createQueryString(request), {
      cancelToken,
    });
    return response.data;
  }

  async getProgress(cancelToken?: CancelToken): Promise<UserOrderModelListResponse> {
    const response = await this.api.get("/api/v1/user/progress", {
      cancelToken,
    });
    return response.data;
  }

  async study(id: number, cancelToken?: CancelToken): Promise<UserStudyModel> {
    const response = await this.api.get(`/api/v1/user/study/${id}`, {
      cancelToken,
    });
    return response.data;
  }

  async studyStudyPathItem(id: number, cancelToken?: CancelToken): Promise<UserStudyModel> {
    const response = await this.api.get(`/api/v1/user/studyPath/study/${id}`, {
      cancelToken,
    });
    return response.data;
  }

  async check(id: number, cancelToken?: CancelToken): Promise<CheckProductModel> {
    const response = await this.api.get(`/api/v1/user/study/${id}/check`, {
      cancelToken,
    });
    return response.data;
  }

  async rate(id: number, request: ProductRatingCreateRequest, cancelToken?: CancelToken): Promise<RatingBaseModel> {
    const response = await this.api.post(`/api/v1/user/study/${id}/rate`, request, {
      cancelToken,
    });
    return response.data;
  }

  async downloadCertificate(userProductId: number): Promise<AxiosResponse<any>> {
    return await this.api.get(`/api/v1/user/studio-certificates/${userProductId}`, {
      responseType: "blob",
    });
  }
}

interface CoursesListRequest extends ListRequest {
  search: string;
  categoryId?: number | null;
  states?: ProductState[];
  type?: ProductTypes;
}

type RelatedCoursesListRequest = ListRequest;

interface UserOrdersListRequest extends ListRequest {
  // search: string;
  PaymentStatusIds?: number[];
  OrderStatusIds?: number[];
  search?: string;
}

interface UserLicensesListRequest extends ListRequest {
  search: string;
}

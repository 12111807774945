
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    rating: {
      type: Number,
      required: true,
    },
    starsCount: {
      type: Number,
      default: 5,
    },
    size: {
      type: String,
      default: "md",
      required: false,
    },
    noRatingText: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const stars = computed(() => {
      const stars: string[] = [];
      for (let i = 1; i <= props.starsCount; i++) {
        let icon = "bi-star";
        if (props.rating >= i) icon = "bi-star-fill";
        else if (props.rating >= i - 0.5) icon = "bi-star-half";
        stars.push(icon);
      }
      return stars;
    });

    const starSize = computed(() => (props.size === "lg" ? "fs-3" : "fs-6"));
    const ratingText = computed(() => (props.rating === 0 ? props.noRatingText : ""));

    return { stars, starSize, ratingText };
  },
});

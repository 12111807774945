
import { defineComponent } from "vue";
import { AcademySettingsModel } from "@/services/models";
import LicenseList, { LicenseListComponent } from "@/components/orders/LicenseList.vue";
import { delay, delayCallback, ListOptions } from "@/utils/common";
import SearchList from "@/components/shared/SearchList.vue";

interface LicensesViewModel {
  listOptions: ListOptions;
  search: string;
  searchDelay: delayCallback;
}

export default defineComponent({
  components: {
    LicenseList,
    SearchList,
  },
  data() {
    return {
      listOptions: { search: "" },
      searchDelay: delay(450),
    } as LicensesViewModel;
  },
  async mounted() {
    if (this.$route.query.search) this.listOptions.search = this.$route.query.search.toString();

    await this.load();
  },
  methods: {
    async load() {
      await this.licenseList.load(this.listOptions);
    },
    searchLicense() {
      this.searchDelay(() => {
        this.licenseList.setOptions(this.listOptions);
        this.licenseList.reload();
      });
    },
  },
  computed: {
    licenseList(): LicenseListComponent {
      return this.$refs.licenseList as LicenseListComponent;
    },
    settings() {
      return this.$store.state.academy.settings as AcademySettingsModel;
    },
  },
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id", "data-backdrop"]
const _hoisted_2 = {
  id: "modal-content",
  class: "modal-content"
}
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = { class: "modal-title" }
const _hoisted_5 = {
  key: 0,
  type: "button",
  class: "btn-close",
  "data-bs-dismiss": "modal",
  "aria-label": "Close"
}
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = {
  key: 0,
  class: "modal-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: "modal fade",
    role: "dialog",
    "data-backdrop": _ctx.closeOnOutsideClick ? 'true' : 'static'
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal-dialog modal-dialog-centered", _ctx.dialogClass]),
      role: "document"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.title), 1),
          (!_ctx.hideCloseButton)
            ? (_openBlock(), _createElementBlock("button", _hoisted_5))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "modal-body")
        ]),
        (_ctx.showFooter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _renderSlot(_ctx.$slots, "modal-footer")
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ], 8, _hoisted_1))
}
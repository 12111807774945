
import { defineComponent } from "vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import PageFooter from "@/components/layout/PageFooter.vue";
import UserMenu from "@/components/layout/UserMenu.vue";
import TopMenu from "@/components/layout/TopMenu.vue";
import SearchDropdown from "@/components/layout/SearchDropdown.vue";
import NotificationsDropdown from "@/components/layout/NotificationsDropdown.vue";
import ShoppingCart from "@/components/shoppingCart/shoppingCart.vue";
import ShoppingCartHeaderPreview from "@/components/shoppingCart/ShoppingCartHeaderPreview.vue";
import { layoutOptions } from "@/init/layout";

export default defineComponent({
  components: {
    PageHeader,
    PageFooter,
    UserMenu,
    TopMenu,
    SearchDropdown,
    NotificationsDropdown,
    ShoppingCartHeaderPreview,
    ShoppingCart,
  },
  data: () => ({ layoutOptions }),
  async mounted() {
    await this.$store.dispatch("getCartItems");
  },
});

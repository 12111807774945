
import { defineComponent } from "vue";
import PlayerMenu from "@/components/study/PlayerMenu.vue";
import { PlayerTocItem } from "@/services/models";
import { uniqueId } from "lodash";

interface ResponsivePlayerMenuViewModel {
  id: string;
  showMobileMenu: boolean;
  framesetManager: any | null;
  frameLearnTask: HTMLIFrameElement | null;
  frameRoot: HTMLIFrameElement | null;
}

export default defineComponent({
  data() {
    return {
      id: uniqueId("responsive-player-menu-offcanvas-"),
      showMobileMenu: false,
      framesetManager: null,
      frameLearnTask: null,
      frameRoot: null,
    } as ResponsivePlayerMenuViewModel;
  },
  components: { PlayerMenu },
  props: {
    toc: Object as () => PlayerTocItem[],
    showHideMenu: Boolean,
    hideMenu: Boolean,
  },
  mounted() {
    this.resizeMenu();
    window.addEventListener("resize", this.resizeMenu);
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeMenu);
  },
  methods: {
    init(framesetManager: any, frameRoot: HTMLIFrameElement, frameLearnTask: HTMLIFrameElement) {
      this.framesetManager = framesetManager;
      this.frameLearnTask = frameLearnTask;
      this.frameRoot = frameRoot;

      this.initMenu();
    },
    initMenu() {
      if (this.framesetManager && this.frameRoot && this.frameLearnTask) {
        (this.$refs.playerMenu as ResponsivePlayerMenuComponent).init(this.framesetManager, this.frameRoot, this.frameLearnTask);
      }
    },
    resizeMenu() {
      this.showMobileMenu = window.innerWidth < 1200;
      this.$emit("update:showHideMenu", !this.showMobileMenu);
      this.$emit("update:hideMenu", false);
    },
    menuLoaded() {
      this.$emit("menuLoaded");
    },
  },
  watch: {
    showMobileMenu() {
      this.$nextTick(() => {
        this.initMenu();
      });
    },
    hideMenu() {
      this.showMobileMenu = this.hideMenu;
    },
  },
});

export declare class ResponsivePlayerMenuComponent {
  init: (frmManager: any, frameRoot: HTMLIFrameElement, frameLearnTask: HTMLIFrameElement) => void;
}

import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "shoppingCartModal" }
const _hoisted_2 = { class: "mb-3 d-flex flex-row align-items-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "product-name px-2" }
const _hoisted_5 = { class: "flex-fill" }
const _hoisted_6 = { class: "footer-buttons d-flex justify-content-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_modal, {
      title: _ctx.title,
      modelValue: _ctx.showModal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showModal) = $event)),
      loading: _ctx.loading,
      closeOnOutsideClick: false
    }, {
      "modal-body": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.imageOrDefaultUrl,
            class: "shopping-cart-modal-img"
          }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.productName), 1)
        ])
      ]),
      "modal-footer": _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.continueWithShopping()), ["prevent"])),
              class: "btn btn-primary font-weight-bold"
            }, _toDisplayString(_ctx.$store.getters.settings.textContinueWithShopping), 1),
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.checkoutOrder()), ["prevent"])),
              class: "btn btn-primary font-weight-bold"
            }, _toDisplayString(_ctx.$store.getters.settings.textCheckout), 1)
          ])
        ])
      ]),
      _: 1
    }, 8, ["title", "modelValue", "loading"])
  ]))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "rating" }
const _hoisted_2 = ["onMouseenter", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stars, (star) => {
      return (_openBlock(), _createElementBlock("span", {
        class: _normalizeClass(["star", [_ctx.starSize, { 'star-readonly': _ctx.readonly }]]),
        key: star.index
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(star.halfs, (half, index) => {
          return (_openBlock(), _createElementBlock("span", {
            key: index,
            class: "star-half",
            onMouseenter: ($event: any) => (_ctx.halfEnter(half)),
            onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.halfLeave())),
            onClick: ($event: any) => (_ctx.halfClick(half))
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: half.icon,
              class: _normalizeClass(half.class)
            }, null, 8, ["icon", "class"])
          ], 40, _hoisted_2))
        }), 128))
      ], 2))
    }), 128))
  ]))
}
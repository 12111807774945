
import { defineComponent, ref } from "vue";
import Modal, { ModalComponent } from "@/components/shared/Modal.vue";
import EventInstancePanel from "./EventInstancePanel.vue";
import { useAcademySettings } from "@/init/store";

export default defineComponent({
  components: {
    Modal,
    EventInstancePanel,
  },
  setup() {
    const { settings } = useAcademySettings();

    const modal = ref(null as null | ModalComponent);

    const id = ref(0);
    const instanceId = ref(0);

    const init = (params: { id: number; instanceId: number }) => {
      id.value = params.id;
      instanceId.value = params.instanceId;
    };

    const show = () => modal.value?.showModal();

    return { settings, id, instanceId, modal, init, show, close, confirm };
  },
});

export declare class EventModalComponent {
  show: () => void;
  init: (params: { id: number; instanceId: number }) => void;
}

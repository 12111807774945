import { forEach } from "lodash";
import { useRoute } from "vue-router";

export const useRouteNumberParams = <T extends string>(...names: T[]) => {
  const { params } = useRoute();

  const result = {} as Record<T, number | null>;

  forEach(names, (name) => {
    const param = params[name];

    if (typeof param === "number") result[name] = param;
    else if (typeof param === "string") {
      const numberParam = parseInt(param);
      result[name] = isNaN(numberParam) ? null : numberParam;
    } else result[name] = null;
  });

  return Object.freeze(result);
};

import moment from "moment";

export const formatDateTimeRange = (date: { startDate?: string; endDate?: string; allDayEvent?: boolean }) => {
  if (!date.startDate || !date.endDate) return "";

  let start = "";
  let end = "";
  if (date.allDayEvent) {
    start = moment(date.startDate).format("DD.MM.YYYY");
    end = moment(date.endDate).format("DD.MM.YYYY");
  } else {
    start = moment(date.startDate).format("hh:mm DD.MM.YYYY");
    end = moment(date.endDate).format("hh:mm DD.MM.YYYY");
  }

  if (start === end) return start;
  else return `${start} - ${end}`;
};


import { UserOrderModel } from "@/services/models";
import moment from "moment";
import { ListOptions } from "@/utils/common";
import { defineComponent } from "vue";
import Pagination, { PaginationComponent } from "../shared/Pagination.vue";
import Order from "@/components/orders/Order.vue";

interface OrderListViewModel {
  orders: UserOrderModel[];
  total: number;
  loaded: boolean;
  listOptions: ListOptions;
}

export default defineComponent({
  components: { Pagination, Order },
  emits: ["listLoaded"],
  props: {
    pageSize: Number,
    scrollTop: Boolean,
    showNoResultText: Boolean,
  },
  data() {
    return {
      orders: [],
      total: 0,
      loaded: false,
      listOptions: { search: "" },
    } as OrderListViewModel;
  },
  methods: {
    async load(options: ListOptions) {
      this.setOptions(options);
      this.loadInternal();
    },
    async loadInternal() {
      const response = await this.$api.userService.getOrdersList({
        page: this.pagination.getPage(),
        pageSize: this.pagination.getPageSize(),
        search: this.listOptions.search ?? "",
      });
      this.total = response.total!;
      this.orders = response.data!;
      this.loaded = true;
      this.$emit("listLoaded", this.total);
    },
    setOptions(options: ListOptions) {
      if (options !== null && options !== undefined) {
        this.listOptions = options;
      }
    },
    reload() {
      this.pagination.setPage(1);
    },
    async paginationLoad() {
      if (this.scrollTop) window.scrollTo(0, 0);
      await this.loadInternal();
    },
  },
  computed: {
    pagination(): PaginationComponent {
      return this.$refs.pagination as PaginationComponent;
    },
    showNoResults(): boolean {
      return this.orders.length === 0 && this.loaded;
    },
    noResultsText(): string {
      return this.$store.state.academy.settings?.textSearchBoxNoResults ?? "No orders found.";
    },
  },
});

export declare class OrderListComponent {
  load: (options: ListOptions) => void;
  reload: () => void;
  setOptions: (options: ListOptions) => void;
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "section-courses mt-4"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row align-items-stretch" }
const _hoisted_4 = { class: "h2 ms-3 pb-3" }
const _hoisted_5 = { class: "h2 ms-3 pb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_academy_course = _resolveComponent("academy-course")!

  return (!_ctx.hideSection)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.$store.getters.settings.homepageSectionAssignedCoursesEnabled)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(_ctx.showTwoColumn ? 'col-md-6' : 'col-md-12')
                }, [
                  _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$store.getters.settings.textHomepageSectionAssignedCoursesTitle), 1),
                  _createElementVNode("div", {
                    class: _normalizeClass(["row row-cols-1 row-cols-sm-1 align-items-stretch g-3", _ctx.showTwoColumn ? 'row-cols-md-2 row-cols-lg-2' : 'row-cols-md-2 row-cols-lg-4'])
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assignedCourses, (course) => {
                      return (_openBlock(), _createBlock(_component_academy_course, {
                        key: course.id,
                        course: course
                      }, null, 8, ["course"]))
                    }), 128))
                  ], 2)
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.$store.getters.settings.homepageSectionNewCoursesEnabled)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(_ctx.showTwoColumn ? 'col-md-6' : 'col-md-12')
                }, [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$store.getters.settings.textHomepageSectionNewCoursesTitle), 1),
                  _createElementVNode("div", {
                    class: _normalizeClass(["row row-cols-1 row-cols-sm-1 align-items-stretch g-3", _ctx.showTwoColumn ? 'row-cols-md-2 row-cols-lg-2' : 'row-cols-md-2 row-cols-lg-4'])
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newCourses, (course) => {
                      return (_openBlock(), _createBlock(_component_academy_course, {
                        key: course.id,
                        course: course
                      }, null, 8, ["course"]))
                    }), 128))
                  ], 2)
                ], 2))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
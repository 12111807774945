
import { defineComponent, PropType } from "vue";
import type { RatingSummaryModel } from "@/services/models";
import RatingInfo from "@/components/rating/RatingInfo.vue";

export default defineComponent({
  components: { RatingInfo },
  props: {
    summary: {
      type: Object as PropType<RatingSummaryModel>,
      required: true,
    },
    orientation: {
      type: String as PropType<"horizontal" | "vertical">,
      required: false,
      default: "vertical",
      validator: (value: string) => ["horizontal", "vertical"].includes(value),
    },
    noRatingText: {
      type: String,
      required: true,
    },
  },
});


import { CourseCategoryModel } from "@/services/models";
import { ListOptions } from "@/utils/common";
import { defineComponent } from "vue";
import Pagination, { PaginationComponent } from "../shared/Pagination.vue";
import Category from "@/components/courses/Category.vue";

interface CategoryListViewModel {
  categories: CourseCategoryModel[];
  loaded: boolean;
  listOption: ListOptions;
  parentId: number | null;
}

export default defineComponent({
  components: { Pagination, Category },
  emits: ["listLoaded"],
  props: {
    pageSize: Number,
    scrollTop: Boolean,
    showNoResultText: Boolean,
  },
  data() {
    return {
      categories: [],
      total: 0,
      loaded: false,
      listOption: { search: "" },
      parentId: null,
    } as CategoryListViewModel;
  },
  methods: {
    async load(options: ListOptions, parentId: number | null) {
      this.parentId = parentId;
      this.setOptions(options);
      this.loadInternal();
    },
    async loadInternal() {
      const response = await this.$api.academyService.getCourseCategories({
        page: this.pagination.getPage(),
        pageSize: this.pagination.getPageSize(),
        search: this.listOption.search ?? "",
        parentCategoryId: this.parentId,
      });
      this.pagination.setTotal(response.total!);
      this.categories = response.data!;
      this.loaded = true;
      this.$emit("listLoaded", response.total!);
    },
    setOptions(options: ListOptions) {
      this.listOption = options;
    },
    reload(parentId: number | null) {
      this.parentId = parentId;
      this.pagination.setPage(1);
    },
    async paginationLoad() {
      if (this.scrollTop) window.scrollTo(0, 0);
      await this.loadInternal();
    },
    selectCategory(category: CourseCategoryModel) {
      if (category.hasGroups) {
        this.$router.push("/category/" + category.id);
        return;
      }
      this.$router.push("/courses/" + category.id);
    },
  },
  computed: {
    pagination(): PaginationComponent {
      return this.$refs.pagination as PaginationComponent;
    },
    showNoResults(): boolean {
      return this.categories.length === 0 && this.loaded;
    },
    noResultsText(): string {
      return this.$store.state.academy.settings?.textSearchBoxNoResults ?? "No categories found.";
    },
  },
});

export declare class CategoryListComponent {
  load: (options: ListOptions, parentId: number | null) => void;
  reload: (parentId: number | null) => void;
  setOptions: (options: ListOptions) => void;
}

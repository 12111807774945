
import { ProductTypes, SortOrder, UserCourseModel } from "@/services/models";
import { defineComponent } from "vue";
import UserCourse from "../courses/UserCourse.vue";

export default defineComponent({
  components: { UserCourse },
  data() {
    return {
      courses: [] as UserCourseModel[],
    };
  },
  async mounted() {
    await this.loadCourses();
  },
  methods: {
    async loadCourses() {
      const response = await this.$api.userService.getCoursesList({
        type: ProductTypes.Path,
        page: 1,
        pageSize: 2,
        sortBy: "CreateDate",
        sortOrder: SortOrder.Desc,
        search: "",
      });
      this.courses = response.data!;
    },
  },
});

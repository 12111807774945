import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withModifiers as _withModifiers, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "form-signin-wrapper" }
const _hoisted_2 = { class: "form-signin" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "h2 mb-3 fw-bold title" }
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = { class: "text-center mt-3" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  class: "h-4 text-primary",
  style: {"text-align":"justify"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      (!_ctx.sent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$store.getters.settings.textForgottenPasswordTitle), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$store.getters.settings.textForgottenPasswordText), 1),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: _normalizeClass(["form-control mt-3", { 'is-invalid': _ctx.v$.form.email.$error || _ctx.exists }]),
              placeholder: _ctx.$store.getters.settings.textEmail,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.email) = $event))
            }, null, 10, _hoisted_5), [
              [_vModelText, _ctx.form.email]
            ]),
            _createElementVNode("button", {
              class: "w-100 btn btn-lg btn-primary text-uppercase mt-4",
              onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args)), ["prevent"]))
            }, _toDisplayString(_ctx.$store.getters.settings.textForgottenPasswordSubmit), 1),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_router_link, {
                class: "text-button",
                to: "/"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$store.getters.settings.textForgottenPasswordBackToSignIn), 1)
                ]),
                _: 1
              })
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$store.getters.settings.textForgottenPasswordLinkSent), 1)
            ])
          ]))
    ])
  ]))
}
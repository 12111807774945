
import { computed, defineComponent, ref } from "vue";
import Modal, { ModalComponent } from "@/components/shared/Modal.vue";
import { EventInstanceModel } from "@/services/models";
import { formatDateTimeRange } from "./common";
import { ApiClient } from "@/services/apiClient";
import { useRouter } from "vue-router";
import { useAcademySettings } from "@/init/store";

export default defineComponent({
  components: {
    Modal,
  },
  emits: ["registered"],
  setup(_, { emit }) {
    const { settings } = useAcademySettings();
    const router = useRouter();

    const modal = ref(null as null | ModalComponent);
    const data = ref(null as null | EventInstanceModel);
    const success = ref(false);

    const init = (instance: EventInstanceModel) => {
      success.value = false;
      data.value = instance;
    };

    const show = () => modal.value?.showModal();
    const close = () => modal.value?.hideModal();

    const confirm = async () => {
      const id = data.value?.id;
      if (!id) return;

      try {
        await ApiClient.eventService.register(id);
        success.value = true;
        emit("registered");
      } catch {
        //
      }
    };

    const formattedDate = computed(() => {
      if (!data.value) return null;
      return formatDateTimeRange(data.value);
    });

    const goToEvents = () => router.push({ name: "Events" });

    return { settings, modal, init, show, data, formattedDate, success, close, confirm, goToEvents };
  },
});

export declare class EventRegisterModalComponent {
  show: () => void;
  init: (instance: EventInstanceModel) => void;
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "data-backdrop"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_shopping_cart_address = _resolveComponent("shopping-cart-address")!
  const _component_shopping_cart_item_list = _resolveComponent("shopping-cart-item-list")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: "modal fade",
    role: "dialog",
    "data-backdrop": _ctx.closeOnOutsideClick ? 'true' : 'static'
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal-dialog d-flex", 'modal-' + _ctx.size]),
      role: "document"
    }, [
      _createVNode(_component_shopping_cart_address, { ref: "shoppingCartAddress" }, null, 512),
      _createVNode(_component_shopping_cart_item_list, {
        onSendOrder: _ctx.sendOrder,
        onMultipleLicences: _ctx.multipleLicences
      }, null, 8, ["onSendOrder", "onMultipleLicences"])
    ], 2)
  ], 8, _hoisted_1))
}
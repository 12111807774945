
import { CourseCategoryModel } from "@/services/models";
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      data: [] as CourseCategoryModel[],
    };
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Number as () => number | null,
      required: true,
    },
  },
  computed: {
    categoryId: {
      set(value: number | null) {
        this.$emit("update:modelValue", value);
      },
      get(): number | null {
        return this.modelValue;
      },
    },
  },
  async mounted() {
    const response = await this.$api.academyService.getCourseCategories({
      pageSize: 1000,
      page: 1,
      search: "",
      parentCategoryId: null,
    });
    this.data = response.data!;
  },
});

import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["r", "cx", "cy"]
const _hoisted_3 = ["r", "cx", "cy"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["progress-ring", ['progress-ring-' + _ctx.type, { 'ms-auto me-auto': _ctx.center }]]),
    style: _normalizeStyle(_ctx.containerStyle)
  }, [
    (_openBlock(), _createElementBlock("svg", {
      width: _ctx.diameter,
      height: _ctx.diameter
    }, [
      _createElementVNode("circle", {
        ref: "ringBackground",
        class: "progress-ring__background",
        fill: "transparent",
        r: _ctx.innerCircleRadius,
        cx: _ctx.radius,
        cy: _ctx.radius,
        style: _normalizeStyle({
          width: `${_ctx.diameter}px`,
          height: `${_ctx.diameter}px`,
          strokeWidth: `${_ctx.strokeWidth}px`,
        })
      }, null, 12, _hoisted_2),
      _createElementVNode("circle", {
        ref: "ringCircle",
        class: "progress-ring__circle",
        fill: "transparent",
        r: _ctx.innerCircleRadius,
        cx: _ctx.radius,
        cy: _ctx.radius,
        style: _normalizeStyle({
          width: `${_ctx.diameter}px`,
          height: `${_ctx.diameter}px`,
          strokeWidth: `${_ctx.strokeWidth}px`,
        })
      }, null, 12, _hoisted_3)
    ], 8, _hoisted_1)),
    _createElementVNode("div", {
      class: "progress-ring-inner",
      style: _normalizeStyle(_ctx.innerCircleStyle)
    }, _toDisplayString(_ctx.percent) + "%", 5)
  ], 6))
}
import type { Ref } from "vue";
import { watch } from "vue";

export const waitForValue = <T extends Ref<V | null | undefined>, V = T extends Ref<infer V | null | undefined> ? V : never>(
  source: T,
  callback: (value: V) => void,
) => {
  if (source?.value !== null && typeof source?.value !== "undefined") {
    callback(source.value);
    return;
  }

  const stopWatch = watch(source, (value) => {
    if (value === null || typeof value === "undefined") return;

    stopWatch();
    callback(value);
  });
};


import { GamificationLeaderboardItemModel, SortOrder } from "@/services/models";
import { defineComponent } from "vue";
import SearchList from "@/components/shared/SearchList.vue";
import Pagination, { PaginationComponent } from "@/components/shared/Pagination.vue";
import { delay } from "@/utils/common";

export default defineComponent({
  components: { Pagination, SearchList },
  data() {
    return {
      items: [] as GamificationLeaderboardItemModel[],
      filter: "",
      searchDelay: delay(450),
    };
  },
  async mounted() {
    if (!this.$getSettings("gamificationEnabled")) {
      this.$router.push("/");
      return;
    }

    await this.load();
  },
  methods: {
    async load() {
      const response = await this.$api.gamificationService.leaderboard({
        search: this.filter,
        page: this.pagination.getPage(),
        pageSize: this.pagination.getPageSize(),
        sortBy: "PointsEarned",
        sortOrder: SortOrder.Desc,
      });
      this.items = response.data!;
      this.pagination.setTotal(response.total!);
    },
    back() {
      this.$router.push({ name: "Gamification" });
    },
    search() {
      this.searchDelay(() => {
        this.pagination.setPage(1);
      });
    },
  },
  computed: {
    pagination() {
      return this.$refs.pagination as PaginationComponent;
    },
  },
});

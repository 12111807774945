import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card-header"
}
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "card-comments-wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "text-center my-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_comment = _resolveComponent("comment")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_create_comment = _resolveComponent("create-comment")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", { 'mt-2': _ctx.parentCommentId, 'course-comment-answers': _ctx.parentCommentId, 'course-comments': !_ctx.parentCommentId }])
  }, [
    (!_ctx.parentCommentId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.total) + " " + _toDisplayString(_ctx.$getSettings("textComments")), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.total === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$getSettings("textNoComments")), 1))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comments, (comment) => {
          return (_openBlock(), _createBlock(_component_comment, {
            key: comment.id,
            comment: comment,
            portalProductId: _ctx.portalProductId
          }, null, 8, ["comment", "portalProductId"]))
        }), 128)),
        _createVNode(_component_pagination, {
          ref: "pagination",
          onReload: _ctx.load,
          pageSize: 1000
        }, null, 8, ["onReload"])
      ]),
      (!_ctx.parentCommentId)
        ? (_openBlock(), _createBlock(_component_create_comment, {
            key: 0,
            portalProductId: _ctx.portalProductId,
            parentCommentId: _ctx.parentCommentId,
            onCommentCreated: _ctx.commentCreated
          }, null, 8, ["portalProductId", "parentCommentId", "onCommentCreated"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
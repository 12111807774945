
import { ApiClient } from "@/services/apiClient";
import { EventCategoryModel } from "@/services/models";
import { ref, onMounted, computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: Number,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const data = ref([] as EventCategoryModel[]);

    onMounted(async () => {
      const response = await ApiClient.academyService.getEventCategories({
        pageSize: 1000,
        page: 1,
        search: "",
      });

      data.value = response.data!;
    });

    const categoryId = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit("update:modelValue", value ?? null);
      },
    });

    return { data, categoryId };
  },
});


import { defineComponent } from "vue";
import { Modal } from "bootstrap";
import _ from "lodash";

export default defineComponent({
  data() {
    return {
      id: _.uniqueId("modal"),
      modalInstance: null as Modal | null,
    };
  },
  props: {
    title: String,
    modelValue: Boolean,
    hideCloseButton: Boolean,
    customDialogClass: String,
    size: {
      type: String as () => "sm" | "lg" | "xl" | "",
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    closeOnOutsideClick: {
      type: Boolean,
      default: true,
    },
    showFooter: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  mounted() {
    const modalElement = document.getElementById(this.id);

    if (modalElement) {
      this.modalInstance = new Modal(modalElement);
      modalElement.addEventListener("hide.bs.modal", () => {
        this.show = false;
      });
    }
  },
  computed: {
    show: {
      get(): boolean {
        return this.modelValue;
      },
      set(value: boolean) {
        this.$emit("update:modelValue", value);
      },
    },
    dialogClass() {
      return ["modal-" + this.size, this.customDialogClass];
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.showModal();
      } else {
        this.hideModal();
      }
    },
  },
  methods: {
    showModal() {
      this.modalInstance?.show();
    },
    hideModal() {
      this.modalInstance?.hide();
    },
  },
  unmounted() {
    this.modalInstance?.dispose();
  },
});

export declare class ModalComponent {
  showModal: () => void;
  hideModal: () => void;
}

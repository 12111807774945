
import { NotificationModel } from "@/services/models";
import { defineComponent } from "vue";
import Pagination, { PaginationComponent } from "@/components/shared/Pagination.vue";
import { dateTimeUtc } from "@/utils/filters";
import NotificationModal, { NotificationModalComponent } from "./NotificationModal.vue";

export default defineComponent({
  components: { Pagination, NotificationModal },
  props: {
    opened: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      data: [] as NotificationModel[],
    };
  },
  mounted() {
    this.loadNotifications();
    this.showNotificationByRouteId();
  },
  methods: {
    dateTimeUtc,
    async loadNotifications() {
      const response = await this.$api.notificationService.getList({
        page: this.pagination.getPage(),
        pageSize: this.pagination.getPageSize(),
        opened: this.opened,
      });
      this.data = response.data!;
      this.pagination.setTotal(response.total!);
    },
    async showNotification(notificationId: number, redirectToList?: boolean) {
      this.modal.show(notificationId, redirectToList);

      if (!this.opened) {
        await this.$api.notificationService.markAsRead(notificationId);
        await this.loadNotifications();
      }
    },
    showNotificationByRouteId() {
      const routeId = this.$route.params.id;
      if (routeId) {
        const id = parseInt(routeId.toString());

        this.$nextTick(() => {
          this.showNotification(id, true);
        });
      }
    },
  },
  computed: {
    pagination(): PaginationComponent {
      return this.$refs.pagination as PaginationComponent;
    },
    modal(): NotificationModalComponent {
      return this.$refs.notificationModal as NotificationModalComponent;
    },
  },
});

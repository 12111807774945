import { createQueryString, ListRequest } from "@/utils/common";
import { AxiosInstance, CancelToken } from "axios";
import {
  GamificationBadgeModelListResponse,
  GamificationLeaderboardItemModelListResponse,
  UserGamificationObjectiveModel,
  UserGamificationObjectiveItemModelListResponse,
  GamificationCurrentLevelModel,
  GamificationObjectiveType,
  ProductTypes,
  GamificationStateModel,
} from "./models";

interface UserGamificationObjectiveListRequest extends ListRequest {
  search: string;
  completed?: boolean;
  expired?: boolean;
  types?: Array<GamificationObjectiveType>;
  categoryId?: number;
}

interface GamificationBadgeListRequest extends ListRequest {
  search: string;
}

interface GamificationLeaderboardRequest extends ListRequest {
  search: string;
}

export default class GamificationService {
  private api: AxiosInstance;
  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async objectiveList(
    request: UserGamificationObjectiveListRequest,
    cancelToken?: CancelToken,
  ): Promise<UserGamificationObjectiveItemModelListResponse> {
    const { types, ...requestNew } = request;

    let queryString = createQueryString(requestNew);

    if (request.types && request.types.length > 0) {
      queryString = `${queryString}${request.types
        .map((type) => {
          return `&types=${type}`;
        })
        .join("")}`;
    }

    const response = await this.api.get("/api/v1/gamification/objectives?" + queryString, { cancelToken });
    return response.data;
  }

  async objective(id: number, cancelToken?: CancelToken): Promise<UserGamificationObjectiveModel> {
    const response = await this.api.get("/api/v1/gamification/objectives/" + id, { cancelToken });
    return response.data;
  }

  async badgesList(request: GamificationBadgeListRequest, cancelToken?: CancelToken): Promise<GamificationBadgeModelListResponse> {
    const response = await this.api.get("/api/v1/gamification/badges?" + createQueryString(request), { cancelToken });
    return response.data;
  }

  async leaderboard(
    request: GamificationLeaderboardRequest,
    cancelToken?: CancelToken,
  ): Promise<GamificationLeaderboardItemModelListResponse> {
    const response = await this.api.get("/api/v1/gamification/leaderboard?" + createQueryString(request), { cancelToken });
    return response.data;
  }

  async currentLevel(cancelToken?: CancelToken): Promise<GamificationCurrentLevelModel> {
    const response = await this.api.get("/api/v1/gamification/currentLevel", {
      cancelToken,
    });
    return response.data;
  }

  async state(cancelToken?: CancelToken): Promise<GamificationStateModel> {
    const response = await this.api.get("/api/v1/gamification/state", {
      cancelToken,
    });
    return response.data;
  }
}


import Pagination, { PaginationComponent } from "@/components/shared/Pagination.vue";
import { GamificationBadgeModel } from "@/services/models";
import { defineComponent } from "vue";
import { dateTimeUtc } from "@/utils/filters";

export default defineComponent({
  components: { Pagination },
  data() {
    return {
      badges: [] as GamificationBadgeModel[],
    };
  },
  async mounted() {
    if (!this.$getSettings("gamificationEnabled")) {
      this.$router.push("/");
      return;
    }
    await this.load();
  },
  methods: {
    dateTimeUtc,
    async load() {
      const response = await this.$api.gamificationService.badgesList({
        search: "",
        page: this.pagination.getPage(),
        pageSize: this.pagination.getPageSize(),
      });
      this.badges = response.data!;
    },
    back() {
      this.$router.push({ name: "Gamification" });
    },
  },
  computed: {
    pagination() {
      return this.$refs.pagination as PaginationComponent;
    },
  },
});

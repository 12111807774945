
import { defineComponent } from "vue";
import StatsItem from "@/components/progress/StatsItem.vue";
import CircleProgress from "@/components/progress/CircleProgress.vue";

import type { ChartOptions, ChartData } from "chart.js";
import { Chart, registerables } from "chart.js";
import { UserProgressModel } from "@/services/models";
Chart.register(...registerables);

export default defineComponent({
  data() {
    return {
      chart: null as Chart<"bar", unknown> | null,
    };
  },
  components: { StatsItem, CircleProgress },
  async mounted() {
    if (!this.$store.getters.settings.progressEnabled) {
      this.$router.push({ name: "Home" });
      return;
    }

    if (!this.progress) {
      await this.$store.dispatch("loadUserProgress");
    }
    this.initChart();

    window.addEventListener("resize", this.resizeChart);
  },
  unmounted() {
    if (this.chart) {
      this.chart.destroy();
    }
    window.removeEventListener("resize", this.resizeChart);
  },
  methods: {
    resizeChart() {
      console.log("resize");
      if (this.chart) {
        this.chart.resize();
      }
    },
    initChart() {
      this.$nextTick(() => {
        var element = document.getElementById("time-progress-chart") as HTMLCanvasElement | null;
        if (element) {
          const options: ChartOptions<"bar"> = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  callback: function (value) {
                    return value + " min";
                  },
                },
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: (context) => {
                    const time = this.progress.chart[context.dataIndex].time;
                    return `${time.hours}h ${time.minutes}m ${time.seconds}s`;
                  },
                },
              },
            },
          };

          const data: ChartData<"bar"> = {
            labels: this.progress.chart.map((item) => item.month),
            datasets: [
              {
                label: "This year",
                data: this.progress.chart.map((item) => item.time.totalMinutes),
                backgroundColor: this.$store.state.academy?.settings?.colorSecondary ?? "",
                borderColor: this.$store.state.academy?.settings?.colorSecondary ?? "",
                borderWidth: 1,
              },
            ],
          };

          // this breaks TS
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.chart = new Chart(element, {
            type: "bar",
            data,
            options,
          });
        }
      });
    },
  },
  computed: {
    progress() {
      return this.$store.state.progress as Defined<UserProgressModel>;
    },
    settings() {
      return this.$store.state.academy.settings;
    },
    yLabels(): string[] {
      return this.progress.chart.map((item) => `${item.time.minutes}`);
    },
  },
});

import { reactive } from "vue";
import { onBeforeRouteLeave } from "vue-router";

const layoutOptions = reactive({ maximizeContent: false });

const useLayoutOptions = () => {
  onBeforeRouteLeave(() => {
    layoutOptions.maximizeContent = false;
  });

  return { layoutOptions };
};

export { layoutOptions, useLayoutOptions };


import { PaymentMethodModel } from "@/services/models";
import { defineComponent } from "vue";

interface PaymentMethodViewModel {
  item: PaymentMethodModel;
  selected: boolean;
}

export default defineComponent({
  emits: ["methodSelected"],
  props: {
    item: Object as () => PaymentMethodModel,
  },
  data() {
    return {
      item: this.item,
      selected: false,
    } as PaymentMethodViewModel;
  },
  methods: {
    //empty
  },
  computed: {
    imageOrDefaultUrl(): string {
      return this.item?.logoUrl ? this.item.logoUrl : "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs=";
    },
  },
});


import { defineComponent } from "vue";
import AppLoading from "@/components/layout/AppLoading.vue";
import appBus from "./appBus";

export default defineComponent({
  components: { AppLoading },
  data() {
    return { loading: false, loadingOverlay: false };
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("initAcademy");
    this.loading = false;

    const customCss = this.$store.state.academy.settings?.customCss ?? "";
    const styleTag = document.createElement("style");
    styleTag.appendChild(document.createTextNode(customCss));
    document.head.appendChild(styleTag);

    appBus.on("showOverlay", this.showOverlay);

    if (
      !this.$store.getters.loggedIn &&
      this.$store.state.academy.isPrivate &&
      this.$route.name !== "Login" &&
      this.$route.meta.authorize
    ) {
      this.$router.push("/login");
    }
  },
  unmounted() {
    appBus.off("showOverlay", this.showOverlay);
  },
  methods: {
    showOverlay(loading: boolean | undefined) {
      this.loadingOverlay = loading ?? false;
    },
  },
});

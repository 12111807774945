import { AxiosInstance, CancelToken } from "axios";
import { AddressModel, CreateOrderRequest, OrderConfirmModel, OrderDetailModel, PaymentCallbackResult } from "@/services/models";
import { createQueryString, ListRequest, saveAs } from "@/utils/common";

export default class OrderService {
  private api: AxiosInstance;
  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async placeOrder(request: CreateOrderRequest, cancelToken?: CancelToken): Promise<OrderConfirmModel> {
    const response = await this.api.post("/api/v1/order/", request, {
      cancelToken,
    });
    return response.data;
  }

  async rePostPayment(orderId: number, cancelToken?: CancelToken): Promise<OrderConfirmModel> {
    const response = await this.api.post("/api/v1/order/repostpayment/" + orderId, {
      cancelToken,
    });
    return response.data;
  }

  async getById(id: number, cancelToken?: CancelToken): Promise<OrderDetailModel> {
    const response = await this.api.get("/api/v1/order/" + id, {
      cancelToken,
    });
    return response.data;
  }
  async lastOrderAddress(cancelToken?: CancelToken): Promise<AddressModel> {
    const response = await this.api.get("/api/v1/userAddresses/lastOrderAddress", {
      cancelToken,
    });
    return response.data;
  }

  async getAresCompanyInfo(taxId: string, cancelToken?: CancelToken): Promise<AddressModel> {
    const response = await this.api.get("/api/v1/userAddresses/getAddressByTaxId/?taxId=" + taxId, {
      cancelToken,
    });
    return response.data;
  }

  async PaymentCallback(id: number, payId: string, cancelToken?: CancelToken): Promise<PaymentCallbackResult> {
    const response = await this.api.post("/api/v1/order/paymentcallback/" + id + "/?payId=" + payId, {
      cancelToken,
    });
    return response.data;
  }

  async downloadInvoice(id: number, cancelToken?: CancelToken): Promise<void> {
    const response = await this.api.get(`/api/v1/order/invoice/${id}`, {
      responseType: "blob",
      cancelToken,
    });

    saveAs(response);
  }
}


import { UserLicenseModel } from "@/services/models";
import { ListOptions } from "@/utils/common";
import { defineComponent } from "vue";
import Pagination, { PaginationComponent } from "../shared/Pagination.vue";
import License from "@/components/orders/License.vue";

interface LicenseListViewModel {
  licenses: UserLicenseModel[];
  total: number;
  loaded: boolean;
  listOptions: ListOptions;
}

export default defineComponent({
  components: { Pagination, License },
  emits: ["listLoaded"],
  props: {
    pageSize: Number,
    scrollTop: Boolean,
    showNoResultText: Boolean,
  },
  data() {
    return {
      licenses: [],
      total: 0,
      loaded: false,
      listOptions: { search: "" },
    } as LicenseListViewModel;
  },
  methods: {
    async load(options: ListOptions) {
      this.setOptions(options);
      this.loadInternal();
    },
    async loadInternal() {
      const response = await this.$api.userService.getLicenseList({
        page: this.pagination.getPage(),
        pageSize: this.pagination.getPageSize(),
        search: this.listOptions.search ?? "",
      });
      this.total = response.total!;
      this.licenses = response.data!;
      this.loaded = true;
      this.$emit("listLoaded", this.total);
    },
    setOptions(options: ListOptions) {
      if (options !== null && options !== undefined) {
        this.listOptions = options;
      }
    },
    reload() {
      this.pagination.setPage(1);
    },
    async paginationLoad() {
      if (this.scrollTop) window.scrollTo(0, 0);
      await this.loadInternal();
    },
  },
  computed: {
    pagination(): PaginationComponent {
      return this.$refs.pagination as PaginationComponent;
    },
    showNoResults(): boolean {
      return this.licenses.length === 0 && this.loaded;
    },
    noResultsText(): string {
      return this.$store.state.academy.settings?.textSearchBoxNoResults ?? "No licenses found.";
    },
  },
});

export declare class LicenseListComponent {
  load: (options: ListOptions) => void;
  reload: () => void;
  setOptions: (options: ListOptions) => void;
}

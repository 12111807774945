
import { EmailTemplateTypes, NotificationModel } from "@/services/models";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    notification: {
      type: Object as () => NotificationModel,
      required: true,
    },
  },
  computed: {
    iconClass() {
      if (this.notification.productMessageId) return "bi-chat-text";

      switch (this.notification.type) {
        case EmailTemplateTypes.GamificationBadgeEarned:
          return "bi-star";
        default:
          return "bi-envelope";
      }
    },
  },
});


import { defineComponent } from "vue";
import Tabs from "@/components/shared/tabs/Tabs.vue";
import Tab from "@/components/shared/tabs/Tab.vue";
import NotificationsList from "@/components/profile/NotificationsList.vue";

export default defineComponent({
  components: { Tabs, Tab, NotificationsList },
  data() {
    return {};
  },
});

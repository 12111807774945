import { AxiosInstance, CancelToken } from "axios";
import { ListRequest } from "@/utils/common";
import { RatingBaseModelListResponse } from "@/services/models";

export default class RatingService {
  private api: AxiosInstance;
  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async getList(request: RatingListRequest, cancelToken?: CancelToken) {
    const response = await this.api.get<RatingBaseModelListResponse>("/api/v1/ratings", {
      cancelToken,
      params: request,
    });
    return response.data;
  }
}

export type RatingListRequest = ListRequest & {
  search?: string;
} & (
    | {
        portalProductId: number;
        eventId?: never;
      }
    | {
        portalProductId?: never;
        eventId: number;
      }
  );


import { defineComponent } from "vue";
import Modal from "@/components/shared/Modal.vue";
import { AcademyCourseModel } from "@/services/models";

interface ShoppingCartModalViewModel {
  id?: number;
  loading: boolean;
  showModal: boolean;
  success: boolean;
  productName?: string | null;
  title?: string | null;
  quantity?: number;
  imageUrl?: string | null;
}

export default defineComponent({
  components: {
    Modal,
  },
  data() {
    return {
      showModal: false,
      loading: false,
      success: false,
      productName: "",
      title: "",
      imageUrl: "",
      quantity: 1,
    } as ShoppingCartModalViewModel;
  },
  methods: {
    checkoutOrder() {
      this.hide();
      this.$store.dispatch("showShoppingCart");
    },
    continueWithShopping() {
      this.hide();
    },
    show(course: AcademyCourseModel, name: string, success: boolean) {
      this.id = course?.id;
      this.imageUrl = course?.imageUrl;
      this.showModal = true;
      this.success = success;
      this.productName = course.name;
      this.title = name;
    },
    hide() {
      this.showModal = false;
    },
  },

  computed: {
    imageOrDefaultUrl(): string {
      return this.imageUrl ? this.imageUrl : "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs=";
    },
  },
});

export declare class ShoppingCartModalComponent {
  show: (course: AcademyCourseModel, name: string, success: boolean) => void;
  hide: () => void;
}


import { CommentModel, CommentModelListResponse } from "@/services/models";
import { defineComponent } from "vue";
import CreateComment from "./CreateComment.vue";
import Comment from "./Comment.vue";
import Pagination, { PaginationComponent } from "../shared/Pagination.vue";

export default defineComponent({
  components: {
    CreateComment,
    Pagination,
    Comment,
  },
  props: {
    loadOnMount: {
      type: Boolean,
      required: false,
    },
    portalProductId: {
      type: Number,
      required: true,
    },
    parentCommentId: {
      type: Number,
    },
  },
  data() {
    return {
      comments: [] as Array<CommentModel>,
      total: 0,
    };
  },
  mounted() {
    if (this.loadOnMount) {
      this.load();
    }
  },
  methods: {
    initList(list: CommentModelListResponse) {
      this.comments = list.data!;
      this.total = list.total!;
      this.pagination.setTotal(this.total);
    },
    async load() {
      const response = await this.$api.commentService.getList({
        pageSize: this.pagination.getPageSize(),
        page: this.pagination.getPage(),
        search: "",
        portalProductId: this.portalProductId,
        parentCommentId: this.parentCommentId,
      });

      this.comments = response.data!;
      this.total = response.total!;
    },
    async commentCreated(comment: CommentModel) {
      this.appendComment(comment);

      await this.$nextTick();
      const commentsWrapper = document.getElementsByClassName("card-comments-wrapper")[0];
      commentsWrapper.scrollTop = commentsWrapper.scrollHeight;
    },
    appendComment(comment: CommentModel) {
      this.comments.push(comment);
      this.total++;
    },
  },
  computed: {
    pagination(): PaginationComponent {
      return this.$refs.pagination as PaginationComponent;
    },
  },
});

export declare class CourseCommentsComponent {
  initList: (list: CommentModelListResponse) => Promise<void>;
  load: () => Promise<void>;
  appendComment: (comment: CommentModel) => Promise<void>;
}

export type CourseCommentsComponentType = {};

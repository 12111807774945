import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "col-md-6 d-flex justify-content-center align-items-center" }
const _hoisted_3 = { class: "col-md-6" }
const _hoisted_4 = { class: "d-flex flex-column" }
const _hoisted_5 = { class: "fs-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RatingSummaryInfo = _resolveComponent("RatingSummaryInfo")!
  const _component_RatingInfo = _resolveComponent("RatingInfo")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!

  return (_ctx.summary)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_RatingSummaryInfo, { summary: _ctx.summary }, null, 8, ["summary"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summary.items, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "d-flex align-items-center my-1",
                key: item.score
              }, [
                _createVNode(_component_RatingInfo, {
                  noRatingText: _ctx.noRatingText,
                  rating: item.score
                }, null, 8, ["noRatingText", "rating"]),
                _createVNode(_component_ProgressBar, {
                  progress: item.percents,
                  color: 'primary',
                  class: "mx-2 w-100px"
                }, null, 8, ["progress"]),
                _createElementVNode("span", _hoisted_5, _toDisplayString(item.count) + "x", 1)
              ]))
            }), 128))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
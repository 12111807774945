import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-3 mb-3 top-menu" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start top-menu-container" }
const _hoisted_4 = {
  href: "/",
  class: "d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none mt-4 mt-lg-0 academy-logo"
}
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", _hoisted_4, [
          _createElementVNode("img", {
            class: "me-2 academy-logo-img",
            src: _ctx.logoUrl,
            alt: "Academy logo"
          }, null, 8, _hoisted_5)
        ]),
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-4" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "d-flex align-items-center justify-content-around" }
const _hoisted_4 = { class: "fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_circle_progress = _resolveComponent("circle-progress")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_circle_progress, {
          percent: _ctx.percents,
          diameter: 100,
          strokeWidth: 11,
          type: 'primary',
          center: false
        }, null, 8, ["percent"]),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$getSettings("textFinishedCourses")), 1)
      ])
    ])
  ]))
}
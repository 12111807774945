import Moment from "moment";

export function truncate(value: string | null, length: number, clamp?: string) {
  if (!value) return "";
  if (length === 0) return value;
  const node = document.createElement("div");
  node.innerHTML = value;
  const text = node.textContent || node.innerText || "";
  return text.length > length ? text.slice(0, length) + (clamp || "...") : text;
}

export function date(value: string | null) {
  return value !== null ? Moment(value).format("DD.MM.YYYY") : "-";
}

export function dateTime(value: string | null) {
  return value !== null ? Moment(value).format("DD.MM.YYYY HH:mm") : "-";
}

export function dateUtc(value: string | null) {
  return value !== null ? Moment.utc(value).local().format("DD.MM.YYYY") : "-";
}

export function dateTimeUtc(value: string | null) {
  return value !== null ? Moment.utc(value).local().format("DD.MM.YYYY HH:mm") : "-";
}

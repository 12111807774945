
import { Toast } from "bootstrap";
import { defineComponent, PropType, render } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
    type: {
      type: String as PropType<"success" | "danger" | "default">,
      requred: true,
    },
  },
  mounted() {
    const element = this.$el as HTMLElement;
    const wrapper = element.parentElement!;

    const toast = new Toast(element);

    element.addEventListener("hidden.bs.toast", () => {
      toast?.dispose();
      render(null, wrapper);
      wrapper.remove();
    });

    toast.show();
  },
  computed: {
    classColor(): string {
      switch (this.type) {
        case "success":
          return "bg-success text-white";
        case "danger":
          return "bg-danger text-white";
        default:
          return "";
      }
    },
  },
});

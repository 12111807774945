
import moment from "moment";
import { EventModel } from "@/services/models";
import { defineComponent, PropType } from "vue";
import { truncate } from "lodash";
import { useRouter } from "vue-router";
import { useAcademySettings } from "@/init/store";
import RatingInfo from "@/components/rating/RatingInfo.vue";

export default defineComponent({
  components: { RatingInfo },
  props: {
    event: {
      type: Object as PropType<EventModel>,
      required: true,
    },
  },
  emits: ["register"],
  setup(props, { emit }) {
    const router = useRouter();

    const { settings } = useAcademySettings();

    const formatDate = (date: string) => moment(date).format("DD.MM.YYYY");
    const formatDescription = (description: string) => truncate(description, { length: 100 });

    const formatInstancesCount = (count: number) => {
      // subtract current instance
      count--;

      if (count === 1) return ` + 1 ${settings.textEventsInstance_N1}`;
      else if (count >= 2 && count <= 4) return ` + ${count} ${settings.textEventsInstance_N2_N4}`;
      else if (count >= 5) return ` + ${count} ${settings.textEventsInstance_N5}`;
      else return "";
    };

    const params = { id: props.event.id, instanceId: props.event.nextInstance?.id };

    const goToEventInstance = () => router.push({ name: "EventDetail", params });
    const register = () => emit("register", params);

    return { settings, formatDate, formatDescription, formatInstancesCount, goToEventInstance, register };
  },
});

import { App } from "vue";
import { ApiClient } from "@/services/apiClient";
import store from "@/init/store";
import { ToastServiceInterface, createInstance as createInstanceToast } from "@/utils/toastService";

export default {
  install(app: App) {
    app.config.globalProperties.$api = ApiClient;
    app.config.globalProperties.$toast = createInstanceToast(app);
    app.config.globalProperties.$getSettings = (key: string): string => {
      const settings = (store.state.academy?.settings ?? {}) as Record<string, string>;

      if (key in settings) return settings[key];

      console.error(`Missing setting '${key}'!`);
      return "";
    };
  },
};

declare module "@vue/runtime-core" {
  export interface ComponentCustomProperties {
    $api: Readonly<typeof ApiClient>;
    $getSettings: (key: string) => string;
    $toast: Readonly<ToastServiceInterface>;
  }
}

<template>
  <div class="container profile-page my-5">
    <div class="row">
      <div class="col-md-3">
        <nav class="nav nav-pills flex-column mb-3">
          <router-link class="nav-link" :to="{ name: 'MyProfile' }">
            <i class="bi bi-person-square"></i>
            {{ $store.getters.settings.textMyProfile }}
          </router-link>
          <router-link class="nav-link" :to="{ name: 'Notifications' }">
            <i class="bi bi-bell"></i>
            {{ $store.getters.settings.textNotifications }}
          </router-link>
          <router-link class="nav-link" :to="{ name: 'ChangePassword' }">
            <i class="bi bi-key"></i>
            {{ $store.getters.settings.textChangePassword }}
          </router-link>
        </nav>
      </div>
      <div class="col-md-9 px-5">
        <router-view />
      </div>
    </div>
  </div>
</template>


import { defineComponent } from "vue";
import AcademyCourse from "@/components/courses/AcademyCourse.vue";
import { AcademyCourseModel } from "@/services/models";
import Pagination, { PaginationComponent } from "@/components/shared/Pagination.vue";
import CoursePlaceholder from "@/components/courses/CoursePlaceholder.vue";

export default defineComponent({
  components: { AcademyCourse, Pagination, CoursePlaceholder },
  data() {
    return {
      courses: [] as AcademyCourseModel[],
    };
  },
  props: {
    renderAsCard: Boolean,
    maxCols: {
      type: Number,
      default: 4,
    },
    showPlaceholders: Boolean,
  },
  async mounted() {
    await this.load();
  },
  methods: {
    async load() {
      const response = await this.$api.userService.getRelatedCoursesList({
        page: this.pagination.getPage(),
        pageSize: this.pagination.getPageSize(),
      });
      this.pagination.setTotal(response.total!);
      this.courses = response.data!;
    },
  },
  computed: {
    pagination(): PaginationComponent {
      return this.$refs.pagination as PaginationComponent;
    },
    placeholdersCount() {
      if (!this.showPlaceholders) return 0;
      const count = this.maxCols - this.courses.length;
      if (count > 0) return count;
      return 0;
    },
  },
});

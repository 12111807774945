
import { defineComponent } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { ForgottenPasswordModel } from "@/services/models";

interface ForgottenPasswordViewModel {
  sent: boolean;
  form: ForgottenPasswordModel;
  errorMessage: string;
}

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      sent: false,
      errorMessage: "",
      form: {},
    } as ForgottenPasswordViewModel;
  },
  methods: {
    send() {
      this.v$.$touch();

      if (!this.v$.$invalid) {
        this.$api.accountService
          .forgottenPassword(this.form)
          .then(() => {
            this.sent = true;
            this.v$.$reset();
          })
          .catch((err) => {
            this.errorMessage = "An error has occured";
          });
      }
    },
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
});


import { AcademyCourseDetailModel, AcademyCourseModel, CreateShoppingCartItem, ProductTypes } from "@/services/models";
import { defineComponent } from "vue";
import ShoppingCartModal, { ShoppingCartModalComponent } from "../shoppingCart/shoppingCartModal.vue";
export default defineComponent({
  components: { ShoppingCartModal },
  props: {
    course: Object as () => AcademyCourseModel | AcademyCourseDetailModel,
  },
  methods: {
    async addToCart() {
      var product = {
        userId: this.$store.getters.loggedUserId,
        productId: this.course?.id,
        portalId: this.$store.getters.currentPortalId,
        quantity: 1,
      } as CreateShoppingCartItem;
      const cartModal = this.$refs.cartModal as ShoppingCartModalComponent;
      try {
        const response = await this.$api.shoppingCartService.addToCart(product);
        this.$store.dispatch("addCartItem", response);

        cartModal.show(this.course!, this.$store.getters.settings.textAddedToCart, true);
      } catch (e) {
        cartModal.show(this.course!, this.$store.getters.settings.textCannotAddToCart, false);
      }
    },
    study(id: number) {
      if (this.course!.type === ProductTypes.Path) {
        this.$router.push(`/study-path/${id}`);
        return;
      }

      this.$router.push(`/my-courses/study/${id}`);
    },
  },
  computed: {
    inInCart(): boolean {
      return this.$store.getters.isProductInCart(this.course?.id);
    },
  },
});


import { PlayerCourseOverviewModel, UserStudyModel } from "@/services/models";
import { defineComponent } from "vue";
import ResponsivePlayerMenu, { ResponsivePlayerMenuComponent } from "@/components/study/ResponsivePlayerMenu.vue";
import { join } from "lodash";
import RateCourseModal, { RateProductModalComponent } from "@/components/courses/RateCourseModal.vue";
import PlayerLoading from "@/components/study/PlayerLoading.vue";
import { Popover } from "bootstrap";
import { getBackRoute } from "@/utils/common";
import { layoutOptions } from "@/init/layout";

var waitingForExit = false;

export default defineComponent({
  components: { ResponsivePlayerMenu, RateCourseModal, PlayerLoading },
  data() {
    return {
      courseId: null as number | null,
      model: { tagNames: [] } as UserStudyModel,
      framesetManager: null as any,
      rateChecked: false,
      hideMenuButton: false,
      showHideMenuButton: true,
      loading: false,
      saving: false,
      studyPathItemId: null as number | null,
      overviewsPopovers: [] as Popover[],
      layoutOptions,
    };
  },
  async mounted() {
    if (!this.$route.params.courseId && !this.$route.params.studyPathItemId) {
      this.$router.push(`/courses`);
      return;
    }

    if (this.$route.params.courseId) {
      this.courseId = parseInt(this.$route.params.courseId.toString());
      await this.study();
    }

    if (this.$route.params.studyPathItemId) {
      this.studyPathItemId = parseInt(this.$route.params.studyPathItemId.toString());
      await this.studyStudyPathItem();
    }

    this.loading = true;

    this.$nextTick(() => {
      this.initPopovers();

      const frameRoot = document.getElementById("scorm-player-iframe") as HTMLIFrameElement;

      frameRoot.onload = () => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const frameLearnTask = frameRoot.contentWindow!.document.getElementById("frameLearnTask") as HTMLIFrameElement;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const windowFunc = frameRoot.contentWindow as any;

        const framesetManager = windowFunc.API_GetFramesetManager();
        this.framesetManager = framesetManager;

        framesetManager.ShowRatingForm = () => {
          if (this.rateChecked || this.saving) return;

          // todo: check if rating is enabled

          this.$api.userService.check(this.model.userProductId!).then(async (result) => {
            //this.rateChecked = result.canRateProduct!.rated === true;
            if (result.canRateProduct!.canRate) {
              const courseRatingModal = this.$refs.courseRatingModal as RateProductModalComponent;
              courseRatingModal.show(this.model.userProductId!, this.model.name!);
              this.rateChecked = true;
            }

            this.model.nextStudyPathItem = result.nextStudyPathItem;
            this.model.previousStudyPathItem = result.previousStudyPathItem;

            await this.$nextTick();

            this.initPopovers();
          });
        };

        // load menu
        (this.$refs.playerMenu as ResponsivePlayerMenuComponent).init(framesetManager, frameRoot, frameLearnTask);
      };
    });
  },
  unmounted() {
    this.overviewsPopovers.forEach((item) => item.dispose());
  },
  computed: {
    tagNamesString(): string {
      return join(this.model.tagNames, ", ");
    },
    hideMenu(): boolean {
      if (!this.model.toc) return true;
      return this.model.toc[0]?.items?.length == 1;
    },
  },
  beforeRouteLeave(to, from, next) {
    this.layoutOptions.maximizeContent = false;

    if (!waitingForExit && this.model.url) {
      this.saving = true;
      this.close();
      setTimeout(() => {
        waitingForExit = true;
        this.$router.push(to);
      }, 2000);
      return next(false);
    }
    next();
  },
  methods: {
    toggleMaximizeContent() {
      this.layoutOptions.maximizeContent = !this.layoutOptions.maximizeContent;
    },
    back() {
      if (this.model.studyPathId) {
        this.$router.push(`/study-path/${this.model.studyPathId}`);
        return;
      }

      const backUrl = getBackRoute();

      if (backUrl) {
        this.$router.push(backUrl);
        return;
      }

      this.$router.push({
        name: this.$store.getters.loggedIn ? "MyCourses" : "Courses",
      });
    },
    close() {
      if (this.framesetManager) {
        try {
          this.framesetManager.IsClosing(true);
          this.framesetManager.ClearContentFrameAndPost("S", null);
        } catch (error) {
          // do nothing
        }
      }
    },
    async study() {
      if (!this.courseId) return;
      this.model = await this.$api.userService.study(this.courseId);
    },
    async studyStudyPathItem() {
      if (!this.studyPathItemId) return;
      this.model = await this.$api.userService.studyStudyPathItem(this.studyPathItemId);
    },
    menuLoaded() {
      this.loading = false;
    },
    signIn() {
      this.$router.push("/login");
    },
    getOverviewHtml(course: PlayerCourseOverviewModel) {
      return (
        `<div class="player-course-overview">` +
        `<img src="${course.imageUrl}" />` +
        `<h5>${course.locked ? `<i class="bi bi-lock-fill"></i> ` : ``}${course.name}</h5>` +
        `</div>`
      );
    },
    initPopovers() {
      const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
      this.overviewsPopovers = popoverTriggerList.map(function (popoverTriggerEl) {
        return new Popover(popoverTriggerEl, {
          trigger: "hover",
          placement: "bottom",
          html: true,
        });
      });
    },
  },
});


import { TutorialModel } from "@/services/models";
import { ListOptions } from "@/utils/common";
import { defineComponent } from "vue";
import Pagination, { PaginationComponent } from "../shared/Pagination.vue";
import Tutorial from "@/components/tutorials/Tutorial.vue";
import { Modal } from "bootstrap";

interface TutorialListViewModel {
  tutorials: TutorialModel[];
  loaded: boolean;
  total: number;
  listOptions: ListOptions;
  modalVideoUrl: string;
  modalVideoInstance: Modal | null;
}

export default defineComponent({
  components: { Pagination, Tutorial },
  emits: ["listLoaded"],
  props: {
    pageSize: Number,
    scrollTop: Boolean,
    showNoResultText: Boolean,
  },
  data() {
    return {
      tutorials: [],
      loaded: false,
      listOptions: {},
      total: 0,
      modalVideoUrl: "",
      modalVideoInstance: null,
    } as TutorialListViewModel;
  },
  mounted() {
    if (this.$store.getters.settings.tutorialsOpenIn === "Modal") {
      this.modalVideoInstance = new Modal(this.modalTutorialElement);

      this.modalTutorialElement.addEventListener("hidden.bs.modal", () => {
        this.modalVideoUrl = "";
      });
    }
  },
  methods: {
    async load(options: ListOptions) {
      this.setOptions(options);
      this.loadInternal();
    },
    async loadInternal() {
      const response = await this.$api.tutorialService.getList({
        page: this.pagination.getPage(),
        pageSize: this.pagination.getPageSize(),
        search: this.listOptions.search ?? "",
        categoryId: this.listOptions.categoryId,
      });
      this.total = response.total!;
      this.tutorials = response.data!;
      this.loaded = true;
      this.$emit("listLoaded", this.total);
    },
    setOptions(options: ListOptions) {
      this.listOptions = options;
    },
    reload() {
      this.pagination.setPage(1);
    },
    async paginationLoad() {
      if (this.scrollTop) window.scrollTo(0, 0);
      await this.loadInternal();
    },
    selectTutorial(tutorial: TutorialModel) {
      if (this.$store.getters.settings.tutorialsOpenIn === "Page") {
        this.$router.push("/tutorials/" + tutorial.id);
        return;
      }

      if (this.modalVideoInstance) {
        this.modalVideoUrl = tutorial.videoUrl ?? "";
        this.modalVideoInstance.show();
        this.$api.tutorialService.viewed(tutorial.id!);
      }
    },
  },
  computed: {
    pagination(): PaginationComponent {
      return this.$refs.pagination as PaginationComponent;
    },
    showNoResults(): boolean {
      return this.tutorials.length === 0 && this.loaded;
    },
    noResultsText(): string {
      return this.$store.state.academy.settings?.textSearchBoxNoResults ?? "No tutorials found.";
    },
    modalTutorialElement(): HTMLElement {
      return document.getElementById("modalTutorial") as HTMLElement;
    },
  },
});

export declare class TutorialListComponent {
  load: (options: ListOptions) => void;
  reload: () => void;
  setOptions: (options: ListOptions) => void;
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { scope: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("th", _hoisted_1, _toDisplayString(_ctx.license.productName), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.license.licenses), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.license.avaiableLicenses), 1),
    _createElementVNode("td", null, [
      _createElementVNode("button", {
        class: "btn btn-primary",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.licenseDetail(_ctx.license.productId)), ["stop"]))
      }, _toDisplayString(_ctx.$store.getters.settings.textLicenseDetailButton), 1)
    ])
  ]))
}
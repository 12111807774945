
import { ShoppingCartItemModel, UpdateShoppingCartItem } from "@/services/models";
import { defineComponent } from "vue";
import { truncate } from "@/utils/filters";

interface ShoppingCartItemViewModel {
  item: ShoppingCartItemModel;
  allowChangeQuantity: boolean;
}

export default defineComponent({
  props: {
    item: Object as () => ShoppingCartItemModel,
    allowChangeQuantity: Object as () => boolean,
  },
  data() {
    return {
      item: this.item,
      allowChangeQuantity: this.allowChangeQuantity,
    } as ShoppingCartItemViewModel;
  },

  computed: {
    imageOrDefaultUrl(): string {
      return this.item?.imageUrl ? this.item.imageUrl : "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs=";
    },
  },
  methods: {
    truncate,
    removeFromCart(productId: number) {
      this.$store.dispatch("removeCartItem", productId);
    },
    async updateCart(productId: number, quantity: number) {
      var product = {
        productId: productId,
        quantity: quantity,
        forceDelete: false,
      } as UpdateShoppingCartItem;

      const response = await this.$api.shoppingCartService
        .updateCart(product)
        .then((result) => {
          this.$store.dispatch("addCartItem", result);
        })
        .catch(() => {
          //empty
        });
    },
  },
});

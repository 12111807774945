import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "display-4 fw-bold text-primary" }
const _hoisted_2 = { class: "d-flex flex-column align-items-center justify-content-center" }
const _hoisted_3 = { class: "fs-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RatingInfo = _resolveComponent("RatingInfo")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-flex align-items-center justify-content-center", {
      'flex-column': _ctx.orientation === 'vertical',
      'justify-content-center': _ctx.orientation === 'vertical',
      'justify-content-between': _ctx.orientation === 'horizontal',
    }])
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.summary.percents) + "%", 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_RatingInfo, {
        noRatingText: _ctx.noRatingText,
        rating: _ctx.summary.score,
        size: 'lg',
        class: "my-2"
      }, null, 8, ["noRatingText", "rating"]),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.summary.total) + " " + _toDisplayString(_ctx.$getSettings("textRatings")), 1)
    ])
  ], 2))
}

import { defineComponent } from "vue";
import { PaymentMethodModel, ShoppingCartItemModel, UpdateShoppingCartItem } from "@/services/models";
import { Tab } from "bootstrap";
import ShoppingItem from "@/components/shoppingCart/ShoppingCartItem.vue";
import PaymentMethodComponent from "@/components/shoppingCart/PaymentMethod.vue";
import _, { forEach } from "lodash";

export default defineComponent({
  components: { ShoppingItem, PaymentMethodComponent },
  emits: ["sendOrder", "multipleLicences"],
  data() {
    return {
      id: _.uniqueId("nav-tabs"),
      tabInstance: null as Tab | null,
      isPaymentMethodSelected: false,
      selectedPaymentMethod: null as PaymentMethodModel | null,
    };
  },
  methods: {
    async Choose(multipleLicences: boolean) {
      let allCartItems: ShoppingCartItemModel[] = this.cart;

      const asyncRes = await Promise.all(
        allCartItems.map(async (i) => {
          if (i.quantity! > 1) {
            var product = {
              productId: i.productId,
              quantity: 1,
              forceDelete: false,
            } as UpdateShoppingCartItem;

            const response = await this.$api.shoppingCartService
              .updateCart(product)
              .then((result) => {
                this.$store.dispatch("addCartItem", result);
              })
              .catch(() => {
                //empty
              });
          }
        }),
      );

      this.$emit("multipleLicences", multipleLicences);
    },
    SendOrder() {
      let method = new Object() as PaymentMethodModel;
      method.id = this.$data.selectedPaymentMethod?.id;
      method.name = this.$data.selectedPaymentMethod?.name;
      this.$emit("sendOrder", method);
    },

    PaymentMethodSelected(paymentMethodId: number) {
      this.$data.isPaymentMethodSelected = true;

      const method = this.$store.getters.paymentMethods.find((i: PaymentMethodModel) => i.id === paymentMethodId) as PaymentMethodModel;

      this.$data.selectedPaymentMethod = method;
    },
  },
  computed: {
    cart(): ShoppingCartItemModel[] {
      return this.$store.getters.shoppingCart.items as ShoppingCartItemModel[];
    },
    paymentMethods(): PaymentMethodModel[] {
      return this.$store.getters.paymentMethods as PaymentMethodModel[];
    },

    textTotalExclTax() {
      return this.$store.getters.settings?.textTotalExclTax ?? "Celkem bez DPH";
    },
    textTotalInclTax() {
      return this.$store.getters.settings?.textTotalInclTax ?? "Celkem s DPH";
    },
    textConfirmOrderButton() {
      return this.$store.getters.settings?.textConfirmOrderButton ?? "Zaplatit";
    },
    textBuyMultipleLicense() {
      return this.$store.getters.settings?.textBuyMultipleLicense ?? "Kupuji pro ostatní";
    },
    textBuyOneLicense() {
      return this.$store.getters.settings?.textBuyOneLicense ?? "Kupuji pro sebe";
    },

    totalPriceInclVat(): string {
      return `${Number(this.$store.getters.shoppingCart.totalPriceInclTax).toFixed(2)} ${this.$store.getters.shoppingCart.currencyCode}`;
    },
    totalPriceExclVat(): string {
      return `${Number(this.$store.getters.shoppingCart.totalPriceExclTax).toFixed(2)} ${this.$store.getters.shoppingCart.currencyCode}`;
    },
  },
});

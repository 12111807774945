
import ShoppingCartLogin from "@/components/shoppingCart/ShoppingCartLogin.vue";
import { defineComponent } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, requiredIf } from "@vuelidate/validators";
import { AddressModel } from "@/services/models";

interface ShoppingcartAddressViewModel {
  isCompany: boolean;
  model: AddressModel;
  isValid: boolean;
  purchaseOrder: string | null;
  note: string | null;
}

export default defineComponent({
  components: { ShoppingCartLogin },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isCompany: false,
      model: {},
      isValid: false,
      purchaseOrder: null,
      note: null,
    } as ShoppingcartAddressViewModel;
  },
  async mounted() {
    await this.$store.dispatch("lastOrderAddress");
    this.$data.model = this.$store.state.address;
  },
  props: {
    size: {
      type: String as () => "sm" | "lg" | "xl" | "",
      default: "lg",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    textCustomerAddress() {
      return this.$store.getters.settings?.textCustomerAddress ?? "Fakturační údaje";
    },
    textCompanyCustomer() {
      return this.$store.getters.settings?.textCompanyCustomer ?? "Nákup na firmu";
    },
    textCompanyName() {
      return this.$store.getters.settings?.textCompanyName ?? "Název společnosti";
    },
    textCompanyIdentificationNumber() {
      return this.$store.getters.settings?.textCompanyIdentificationNumber ?? "IČO";
    },
    textVatNumber() {
      return this.$store.getters.settings?.textVatNumber ?? "DIČ";
    },
    textFirstName() {
      return this.$store.getters.settings?.textFirstName ?? "Jméno";
    },
    textLastName() {
      return this.$store.getters.settings?.textLastName ?? "Příjmení";
    },
    textStreet() {
      return this.$store.getters.settings?.textStreet ?? "Adresa";
    },
    textCity() {
      return this.$store.getters.settings?.textCity ?? "Město";
    },
    textZip() {
      return this.$store.getters.settings?.textZip ?? "PSČ";
    },
    textState() {
      return this.$store.getters.settings?.textState ?? "Země";
    },
    textChooseState() {
      return this.$store.getters.settings?.textChooseState ?? "Zvolte zemi";
    },
    textContinueWithShopping() {
      return this.$store.getters.settings?.textContinueWithShopping ?? "Pokračovat v nákupu";
    },

    Firstname: {
      get(): string {
        return this.$store.state.address.firstName!;
      },
      set(value: string) {
        const address = this.$store.state.address;
        this.model.firstName = value;
        address.firstName = value;
        this.$store.commit("updateAddress", address);
      },
    },
    Lastname: {
      get(): string {
        return this.$store.state.address.lastName!;
      },
      set(value: string) {
        const address = this.$store.state.address;
        address.lastName = value;
        this.model.lastName = value;
        this.$store.commit("updateAddress", address);
      },
    },
    Street: {
      get(): string {
        return this.$store.state.address.address!;
      },
      set(value: string) {
        const address = this.$store.state.address;
        address.address = value;
        this.model.address = value;
        this.$store.commit("updateAddress", address);
      },
    },
    City: {
      get(): string {
        return this.$store.state.address.city!;
      },
      set(value: string) {
        const address = this.$store.state.address;
        address.city = value;
        this.model.city = value;
        this.$store.commit("updateAddress", address);
      },
    },
    Zip: {
      get(): string {
        return this.$store.state.address.zipPostalCode!;
      },
      set(value: string) {
        const address = this.$store.state.address;
        address.zipPostalCode = value;
        this.model.zipPostalCode = value;
        this.$store.commit("updateAddress", address);
      },
    },
    Company: {
      get(): string {
        return this.$store.state.address.company!;
      },
      set(value: string) {
        const address = this.$store.state.address;
        address.company = value;
        this.model.company = value;
        this.$store.commit("updateAddress", address);
      },
    },
    CompanyIdentificationNumber: {
      get(): string {
        return this.$store.state.address.companyIdentificationNumber!;
      },
      set(value: string) {
        const address = this.$store.state.address;
        address.companyIdentificationNumber = value;
        this.model.companyIdentificationNumber = value;
        this.$store.commit("updateAddress", address);
      },
    },
    VatNumber: {
      get(): string {
        return this.$store.state.address.vatNumber!;
      },
      set(value: string) {
        const address = this.$store.state.address;
        address.vatNumber = value;
        this.model.vatNumber = value;
        this.$store.commit("updateAddress", address);
      },
    },
  },
  methods: {
    ContinueShopping() {
      this.$store.dispatch("hideShoppingCart");
    },
    async GetAddressFromAddress() {
      const response = await this.$api.orderService
        .getAresCompanyInfo(this.model.companyIdentificationNumber!)
        .then((result) => {
          this.$store.commit("updateAddress", result);
        })
        .catch(() => {
          //empty
        });
    },
    getPurchaseOrder() {
      return this.$data.purchaseOrder;
    },
    getOrderNote() {
      return this.$data.note;
    },
    validateAddress() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        //empty
        return true;
      } else {
        return false;
      }
    },
    ClearCompanyInfo() {
      if (!this.isCompany) {
        const address = this.$store.state.address;
        address.companyIdentificationNumber = "";
        address.vatNumber = "";
        address.company = "";
        this.$store.commit("updateAddress", address);
      }
    },
  },
  validations() {
    return {
      model: {
        city: { required },
        countryId: { required },
        firstName: {
          required: requiredIf(() => {
            return !this.isCompany;
          }),
        },
        lastName: {
          required: requiredIf(() => {
            return !this.isCompany;
          }),
        },
        address: { required },
        zipPostalCode: { required },
        vatNumber: {
          required: requiredIf(() => {
            return this.isCompany;
          }),
        },
        companyIdentificationNumber: {
          required: requiredIf(() => {
            return this.isCompany;
          }),
        },
        company: {
          required: requiredIf(() => {
            return this.isCompany;
          }),
        },
      },
    };
  },
});

export declare class ShoppingCartAddressComponent {
  validateAddress: () => boolean;
  getPurchaseOrder: () => string;
  getOrderNote: () => string;
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notifications_list = _resolveComponent("notifications-list")!
  const _component_tab = _resolveComponent("tab")!
  const _component_tabs = _resolveComponent("tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tabs, { class: "mb-3" }, {
      default: _withCtx(() => [
        _createVNode(_component_tab, {
          title: _ctx.$store.getters.settings.textNewNotifications,
          icon: 'bi bi-bell-fill'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_notifications_list, { opened: false })
          ]),
          _: 1
        }, 8, ["title"]),
        _createVNode(_component_tab, {
          title: _ctx.$store.getters.settings.textOpenedNotifications,
          icon: 'bi bi-bell'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_notifications_list, { opened: true })
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _: 1
    })
  ]))
}
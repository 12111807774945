
import { defineComponent } from "vue";
import { AcademySettingsModel, ProductState, UserCourseModel } from "@/services/models";
import UserCourse from "@/components/courses/UserCourse.vue";
import Pagination, { PaginationComponent } from "@/components/shared/Pagination.vue";
import SearchList from "@/components/shared/SearchList.vue";
import CategorySelect from "@/components/shared/CategorySelect.vue";
// import CourseStateSelect from "@/components/shared/CourseStateSelect.vue";
import RelatedCourses from "@/components/courses/RelatedCourses.vue";
import { saveBackRoute } from "@/utils/common";

interface MyCoursesViewModel {
  courses: UserCourseModel[];
  search: string;
  filterStates: ProductState[];
  filterCategoryId: number | null;
  loaded: boolean;
}

export default defineComponent({
  components: {
    UserCourse,
    Pagination,
    SearchList,
    CategorySelect,
    RelatedCourses,
  },
  data() {
    return {
      search: "",
      courses: [],
      filterStates: [],
      filterCategoryId: null,
      loaded: false,
    } as MyCoursesViewModel;
  },
  async mounted() {
    if (this.$route.query.search) this.search = this.$route.query.search.toString();

    saveBackRoute();

    await this.load();
  },
  methods: {
    async load() {
      const response = await this.$api.userService.getCoursesList({
        page: this.pagination.getPage(),
        pageSize: this.pagination.getPageSize(),
        search: this.search,
        categoryId: this.filterCategoryId,
      });

      this.pagination.setTotal(response.total!);
      this.courses = response.data!;
      this.loaded = true;
    },
    async reload() {
      this.pagination.setPage(1);
    },
    async paginationLoad() {
      window.scrollTo(0, 0);
      await this.load();
    },
  },
  computed: {
    pagination(): PaginationComponent {
      return this.$refs.pagination as PaginationComponent;
    },
    noResultsText(): string {
      return this.$store.state.academy.settings?.textSearchBoxNoResults ?? "No courses found.";
    },
    showNoResults(): boolean {
      return this.courses.length === 0 && this.loaded;
    },
    settings() {
      return this.$store.state.academy.settings as AcademySettingsModel;
    },
  },
  watch: {
    filterCategoryId() {
      this.reload();
    },
  },
});

import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "pagination justify-content-center mt-3" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
        _createElementVNode("ul", _hoisted_2, [
          _createElementVNode("li", {
            class: _normalizeClass(["page-item", { disabled: _ctx.previousDisabled }])
          }, [
            _createElementVNode("a", {
              class: "page-link",
              href: "#",
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.previous && _ctx.previous(...args)), ["prevent"]))
            }, "Previous")
          ], 2),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalPages, (pageIndex) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: pageIndex }, [
              ((_ctx.page - 3 <= pageIndex && _ctx.page + 3 >= pageIndex) || pageIndex == _ctx.page || pageIndex == _ctx.totalPages || pageIndex == 1)
                ? (_openBlock(), _createElementBlock("li", {
                    key: 0,
                    class: _normalizeClass([{ active: pageIndex == _ctx.page }, "page-item"])
                  }, [
                    _createElementVNode("a", {
                      class: "page-link",
                      href: "#",
                      onClick: _withModifiers(($event: any) => (_ctx.setPage(pageIndex)), ["prevent"])
                    }, _toDisplayString(pageIndex), 9, _hoisted_3)
                  ], 2))
                : (pageIndex === 2)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
                      _createElementVNode("a", {
                        class: "page-link",
                        href: "#",
                        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.setPage(_ctx.page - 4)), ["prevent"]))
                      }, "...")
                    ]))
                  : (pageIndex === _ctx.totalPages - 1)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                        _createElementVNode("a", {
                          class: "page-link",
                          href: "#",
                          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.setPage(_ctx.page + 4)), ["prevent"]))
                        }, "...")
                      ]))
                    : _createCommentVNode("", true)
            ], 64))
          }), 128)),
          _createElementVNode("li", {
            class: _normalizeClass(["page-item", { disabled: _ctx.nextDisabled }])
          }, [
            _createElementVNode("a", {
              class: "page-link",
              href: "#",
              onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)), ["prevent"]))
            }, "Next")
          ], 2)
        ])
      ]))
    : _createCommentVNode("", true)
}

import { GamificationObjectiveType, UserGamificationObjectiveItemModel, UserGamificationObjectiveModel } from "@/services/models";
import { defineComponent } from "vue";
import ObjectiveModal, { ObjectiveModalComponent } from "@/components/gamification/ObjectiveModal.vue";
import Pagination, { PaginationComponent } from "@/components/shared/Pagination.vue";

export type ObjectiveListFilterType = "all" | "active" | "completed" | "notcompleted" | "expired";

export default defineComponent({
  components: {
    ObjectiveModal,
    Pagination,
  },
  props: {
    types: {
      type: Array as () => Array<GamificationObjectiveType>,
      default: () => [],
    },
    filterType: String as () => ObjectiveListFilterType,
    noDataText: {
      type: String,
      default: () => "No data",
    },
    categoryId: Number,
  },
  data() {
    return {
      data: [] as UserGamificationObjectiveModel[],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      let completed = undefined;
      let expired = undefined;

      switch (this.filterType) {
        case "active":
          expired = false;
          break;
        case "completed":
          completed = true;
          expired = false;
          break;
        case "notcompleted":
          completed = false;
          expired = false;
          break;
        case "expired": {
          expired = true;
        }
      }

      const response = await this.$api.gamificationService.objectiveList({
        page: this.pagination.getPage(),
        pageSize: this.pagination.getPageSize(),
        search: "",
        completed: completed,
        expired: expired,
        types: this.types,
        categoryId: this.categoryId,
      });
      this.data = response.data!;
      this.pagination.setTotal(response.total!);
    },
    openObjective(item: UserGamificationObjectiveItemModel) {
      this.objectiveModal.show(item.id!);
    },
  },
  computed: {
    objectiveModal() {
      return this.$refs.objectiveModal as ObjectiveModalComponent;
    },
    pagination() {
      return this.$refs.pagination as PaginationComponent;
    },
  },
  watch: {
    async filterType() {
      await this.load();
    },
    async categoryId() {
      await this.load();
    },
  },
});


import { defineComponent } from "vue";
import LeaderboardPanel from "@/components/gamification/LeaderboardPanel.vue";
import ObjectiveList, { ObjectiveListFilterType } from "@/components/gamification/ObjectiveList.vue";
import CurrentLevel from "@/components/gamification/CurrentLevel.vue";
import BadgePanel from "@/components/gamification/BadgePanel.vue";
import { GamificationObjectiveType, GamificationStateModel } from "@/services/models";
import Tabs from "@/components/shared/tabs/Tabs.vue";
import Tab from "@/components/shared/tabs/Tab.vue";
import CategorySelect from "@/components/shared/CategorySelect.vue";

export default defineComponent({
  data() {
    return {
      filterType: "active" as ObjectiveListFilterType,
      categoryId: null as number | null,
      state: {
        showObjectives: false,
        showChallenges: false,
        showStreaks: false,
      } as GamificationStateModel,
    };
  },
  components: { LeaderboardPanel, ObjectiveList, CurrentLevel, BadgePanel, Tabs, Tab, CategorySelect },
  async mounted() {
    if (!this.$getSettings("gamificationEnabled")) {
      this.$router.push("/");
      return;
    }

    this.state = await this.$api.gamificationService.state();
  },
  computed: {
    accordionItems() {
      const list = [];

      if (this.state.showObjectives) {
        list.push({
          icon: "bi bi-bullseye",
          name: this.$getSettings("textGamificationObjectives"),
          types: [GamificationObjectiveType.Single, GamificationObjectiveType.Multiple],
          noData: this.$getSettings("textGamificationObjectivesNoData"),
        });
      }

      if (this.state.showChallenges) {
        list.push({
          icon: "bi bi-flag",
          name: this.$getSettings("textGamificationChallenges"),
          types: [GamificationObjectiveType.Challenge],
          noData: this.$getSettings("textGamificationChallengesNoData"),
        });
      }

      if (this.state.showStreaks) {
        list.push({
          icon: "bi bi-award",
          name: this.$getSettings("textGamificationStreaks"),
          types: [GamificationObjectiveType.Streak],
          noData: this.$getSettings("textGamificationStreaksNoData"),
        });
      }

      return list;
    },
  },
});

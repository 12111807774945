
import { defineComponent } from "vue";
import { AcademySettingsModel, ProductState, UserCourseModel } from "@/services/models";

export default defineComponent({
  components: {},
  data() {
    return {
      payId: "",
      orderId: 0,
    };
  },
  async mounted() {
    if (this.$route.query.payId) this.payId = this.$route.query.payId.toString();

    if (this.$route.query.orderId) this.orderId = parseInt(this.$route.query.orderId.toString(), 10);

    this.load();
  },
  methods: {
    async load() {
      //empty
      const response = await this.$api.orderService.PaymentCallback(this.orderId, this.payId);

      if (response.success) {
        this.$router.push("/order/detail/" + response.orderId);
      }
    },
  },
});

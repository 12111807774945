import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "col d-flex align-items-stretch" }
const _hoisted_2 = { class: "card guide-item flex-fill mx-3 mb-4" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "card-body d-flex flex-column" }
const _hoisted_5 = {
  key: 0,
  class: "card-title"
}
const _hoisted_6 = { class: "card-text" }
const _hoisted_7 = {
  key: 1,
  class: "card-subtitle mb-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.imageOrDefaultUrl,
        class: "card-img-top"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.$store.getters.settings.guideBoxShowTitle)
          ? (_openBlock(), _createElementBlock("h5", _hoisted_5, _toDisplayString(_ctx.guide.name), 1))
          : _createCommentVNode("", true),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.guide.description), 1),
        (_ctx.guide.estimatedTime)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.guide.estimatedTime), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('selectGuide', _ctx.guide.id)), ["stop"])),
          class: "btn btn-primary mt-auto"
        }, _toDisplayString(_ctx.$store.getters.settings.textGuidesDownload), 1)
      ])
    ])
  ]))
}
import { AxiosInstance, CancelToken } from "axios";
import {
  AcademyModel,
  CourseCategoryModel,
  CourseCategoryModelListResponse,
  CourseTagModel,
  EventCategoryModelListResponse,
} from "@/services/models";
import { createQueryString, ListRequest } from "@/utils/common";

export default class AcademyService {
  private api: AxiosInstance;
  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async init(cancelToken?: CancelToken): Promise<AcademyModel> {
    const response = await this.api.get("/api/v1/academy", { cancelToken });
    return response.data;
  }

  async getCourseCategories(request: CategoriesListRequest, cancelToken?: CancelToken): Promise<CourseCategoryModelListResponse> {
    const response = await this.api.get("/api/v1/academy/courseCategories?" + createQueryString(request), {
      cancelToken,
    });
    return response.data;
  }

  async getCourseCategory(id: number, cancelToken?: CancelToken): Promise<CourseCategoryModel> {
    const response = await this.api.get("/api/v1/academy/courseCategories/" + id, {
      cancelToken,
    });
    return response.data;
  }

  async getCourseTags(cancelToken?: CancelToken): Promise<CourseTagModel[]> {
    const response = await this.api.get("/api/v1/academy/courseTags", {
      cancelToken,
    });
    return response.data;
  }

  async getEventCategories(params: EventCoursesListRequest, cancelToken?: CancelToken) {
    const response = await this.api.get<EventCategoryModelListResponse>("/api/v1/academy/eventCategories", {
      cancelToken,
      params,
    });
    return response.data;
  }
}

interface EventCoursesListRequest extends ListRequest {
  search: string;
  categoryId?: number | null;
}

interface CategoriesListRequest extends ListRequest {
  search: string;
  parentCategoryId: number | null;
}

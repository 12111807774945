import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-signin-wrapper" }
const _hoisted_2 = { class: "form-signin" }
const _hoisted_3 = { class: "h2 mb-3 fw-bold title" }
const _hoisted_4 = {
  key: 0,
  class: "text-danger fw-bold"
}
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "forgotten-password text-end mb-2" }
const _hoisted_8 = { class: "text-center login-links" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "separator-text" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["href"]
const _hoisted_13 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      _createElementVNode("form", null, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$store.getters.settings.textSignIn), 1),
        (_ctx.error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$store.getters.settings.textWrongEmailOrPassword), 1))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("input", {
          type: "email",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
          class: "form-control my-3",
          placeholder: _ctx.$store.getters.settings.textEmail
        }, null, 8, _hoisted_5), [
          [_vModelText, _ctx.email]
        ]),
        _withDirectives(_createElementVNode("input", {
          type: "password",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
          class: "form-control mt-3 mb-2",
          placeholder: _ctx.$store.getters.settings.textPassword
        }, null, 8, _hoisted_6), [
          [_vModelText, _ctx.password]
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_router_link, {
            to: "/forgottenPassword",
            class: "link"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$store.getters.settings.textForgottenPassword), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("button", {
          class: "w-100 btn btn-lg btn-primary text-uppercase mt-2",
          onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args)), ["prevent"]))
        }, _toDisplayString(_ctx.$store.getters.settings.textSignInButton), 1),
        _createElementVNode("div", _hoisted_8, [
          (_ctx.showRegistrationLink)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: "/registration",
                class: "create-account h5 mt-3"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$store.getters.settings.textRegistration), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.ssoLoginUrl)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$store.getters.settings.textOrSignWith), 1)
                ]),
                (_ctx.$store.getters.settings.textLoginBeforeSso)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "sso-text mt-2 mb-4",
                      innerHTML: _ctx.$store.getters.settings.textLoginBeforeSso
                    }, null, 8, _hoisted_11))
                  : _createCommentVNode("", true),
                _createElementVNode("a", {
                  href: _ctx.ssoLoginUrl,
                  class: "sso-link h5"
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: "key" })
                ], 8, _hoisted_12)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "help-text mt-4",
            innerHTML: _ctx.$store.getters.settings.textLoginAfterForm
          }, null, 8, _hoisted_13)
        ])
      ])
    ])
  ]))
}
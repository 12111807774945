
import { defineComponent } from "vue";
export default defineComponent({
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    orderHistory() {
      this.$router.push("/orders");
    },
    licenseHistory() {
      this.$router.push("/licenses");
    },
  },
  computed: {
    avatarUrl() {
      return this.$store.state.user?.avatarUrl ?? "/assets/images/avatar.jpg";
    },
  },
});

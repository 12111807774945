import Toast from "@/components/shared/Toast.vue";
import { App, AppContext, createVNode, render } from "vue";

export function createInstance(app: App) {
  return new ToastServiceInstance(app._context);
}

export interface ToastServiceInterface {
  success: (title: string, body: string) => void;
  danger: (title: string, body: string) => void;
  default: (title: string, body: string) => void;
}

class ToastServiceInstance implements ToastServiceInterface {
  private _appContext: AppContext;

  constructor(appContext: AppContext) {
    this._appContext = appContext;
  }

  private showToast(title: string, body: string, type: "success" | "danger" | "default"): void {
    const componentWrapper = document.createElement("div");

    const vnode = createVNode(Toast, {
      title: title,
      body: body,
      type: type,
    });

    vnode.appContext = { ...this._appContext };

    const toastContainer = document.getElementsByClassName("toast-container")[0];
    if (!toastContainer) throw Error(".toast-container not exists");

    render(vnode, componentWrapper);
    toastContainer.appendChild(componentWrapper);
  }

  success(title: string, body: string) {
    this.showToast(title, body, "success");
  }
  danger(title: string, body: string) {
    this.showToast(title, body, "danger");
  }
  default(title: string, body: string) {
    this.showToast(title, body, "default");
  }
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    ref: "modal",
    title: _ctx.success ? _ctx.settings.textEventsRegisterSuccessTitle : _ctx.settings.textEventsRegisterConfirmTitle,
    size: 'lg',
    closeOnOutsideClick: true,
    hideCloseButton: true,
    customDialogClass: _ctx.success ? 'success' : 'confirm',
    class: "eventRegisterModal"
  }, _createSlots({
    "modal-body": _withCtx(() => [
      (_ctx.data)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", null, [
              _createElementVNode("b", null, _toDisplayString(_ctx.data.eventName), 1)
            ]),
            (_ctx.formattedDate)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("b", null, _toDisplayString(_ctx.formattedDate), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.data.lector)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("b", null, _toDisplayString(_ctx.settings.textEventsLector) + ":", 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.data.lector.firstName) + " " + _toDisplayString(_ctx.data.lector.lastName), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.data.eventLocation)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("b", null, _toDisplayString(_ctx.settings.textEventsLocation) + ":", 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.data.eventLocation.location), 1)
                ]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (_ctx.success)
      ? {
          name: "modal-footer",
          fn: _withCtx(() => [
            _createElementVNode("button", {
              class: "btn btn-secondary uppercase",
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.goToEvents && _ctx.goToEvents(...args)), ["stop"]))
            }, _toDisplayString(_ctx.settings.textEventsGoToEventsPage), 1),
            _createElementVNode("button", {
              class: "btn btn-primary uppercase",
              onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["stop"]))
            }, _toDisplayString(_ctx.settings.textEventsRegisterClose), 1)
          ])
        }
      : {
          name: "modal-footer",
          fn: _withCtx(() => [
            _createElementVNode("button", {
              class: "btn btn-secondary uppercase",
              onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["stop"]))
            }, _toDisplayString(_ctx.settings.textEventsRegisterNo), 1),
            _createElementVNode("button", {
              class: "btn btn-primary uppercase",
              onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args)), ["stop"]))
            }, _toDisplayString(_ctx.settings.textEventsRegisterYes), 1)
          ])
        }
  ]), 1032, ["title", "customDialogClass"]))
}

import { defineComponent } from "vue";

export default defineComponent({
  emits: ["commentCreated"],
  props: {
    portalProductId: {
      type: Number,
      required: true,
    },
    parentCommentId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      text: "",
    };
  },
  methods: {
    async send() {
      if (!this.text) return;
      const response = await this.$api.commentService.create({
        text: this.text,
        parentCommentId: this.parentCommentId,
        portalProductId: this.portalProductId,
      });
      this.text = "";
      this.$emit("commentCreated", response);
    },
  },
});

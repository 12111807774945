import type { AxiosInstance, CancelToken } from "axios";
import type { ListRequest } from "@/utils/common";
import type {
  EventInstanceShow,
  MyEventInstanceModelListResponse,
  EventDetailModel,
  EventInstanceModel,
  EventInstanceDateModelListResponse,
  EventInstanceModelListResponse,
  EventModelListResponse,
} from "./models";

interface MyEventInstanceListRequest extends ListRequest {
  show: EventInstanceShow;
}

interface EventInstanceListRequest extends ListRequest {
  show: EventInstanceShow;
  includeParticipants: boolean;
}

interface EventInstanceDateListRequest extends ListRequest {
  eventId?: number;
  show: EventInstanceShow;
}

interface EventListRequest extends ListRequest {
  search?: string | null;
  categoryId?: number | null;
}

export default class EventService {
  private api: AxiosInstance;
  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async event(id: number, cancelToken?: CancelToken) {
    const response = await this.api.get<EventDetailModel>(`/api/v1/event/detail/${id}`, { cancelToken });
    return response.data;
  }

  async events(params: EventListRequest, cancelToken?: CancelToken) {
    const response = await this.api.get<EventModelListResponse>(`/api/v1/event/list`, { cancelToken, params });
    return response.data;
  }

  async myInstanceList(params: MyEventInstanceListRequest, cancelToken?: CancelToken) {
    const response = await this.api.get<Defined<MyEventInstanceModelListResponse>>("/api/v1/event/myInstances", { cancelToken, params });
    return response.data;
  }

  async instance(id: number, cancelToken?: CancelToken) {
    const response = await this.api.get<Id<EventInstanceModel>>(`/api/v1/event/instances/${id}`, { cancelToken });
    return response.data;
  }

  async instances(params: EventInstanceListRequest, cancelToken?: CancelToken) {
    const response = await this.api.get<Id<EventInstanceModelListResponse>>("/api/v1/event/instances", { cancelToken, params });
    return response.data;
  }

  async instancesDates(params: EventInstanceDateListRequest, cancelToken?: CancelToken) {
    const response = await this.api.get<Id<EventInstanceDateModelListResponse>>("/api/v1/event/instancesDates", { cancelToken, params });
    return response.data;
  }

  async register(id: number, cancelToken?: CancelToken) {
    await this.api.post(`/api/v1/event/instances/${id}/register`, null, { cancelToken });
  }

  async unregister(id: number, cancelToken?: CancelToken) {
    await this.api.post(`/api/v1/event/instances/${id}/unregister`, null, { cancelToken });
  }
}

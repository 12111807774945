import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col stats-item my-3 d-flex flex-column" }
const _hoisted_2 = { class: "icon flex-fill" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "value" }
const _hoisted_5 = { class: "desc mt-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", { src: _ctx.iconSrc }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.value), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.text), 1)
  ]))
}
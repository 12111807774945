
import { defineComponent, PropType } from "vue";
import type { RatingSummaryModel } from "@/services/models";
import RatingInfo from "@/components/rating/RatingInfo.vue";
import RatingSummaryInfo from "@/components/rating/RatingSummaryInfo.vue";
import ProgressBar from "@/components/shared/ProgressBar.vue";

export default defineComponent({
  components: { RatingInfo, ProgressBar, RatingSummaryInfo },
  props: {
    summary: {
      type: Object as PropType<RatingSummaryModel>,
      required: true,
    },
    noRatingText: {
      type: String,
      required: true,
    },
  },
});

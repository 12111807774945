
import { defineComponent } from "vue";
import IconComingSoon from "@/components/shared/icons/IconComingSoon.vue";
export default defineComponent({
  computed: {
    progress() {
      return this.$store.state.progress;
    },
  },
  components: { IconComingSoon },
  mounted() {
    if (this.$store.getters.loggedIn && !this.progress) {
      this.$store.dispatch("loadUserProgress");
    }
  },
});


import { defineComponent } from "vue";

interface StarHalfViewModel {
  value: number;
  icon: string[];
  class: string;
}

interface StarViewModel {
  index: number;
  halfs: StarHalfViewModel[];
}

export default defineComponent({
  data() {
    return {
      hoverValue: 0,
    };
  },
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    starsCount: {
      type: Number,
      default: 5,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String as () => "md" | "lg",
      default: "md",
    },
  },
  computed: {
    ratingValue: {
      get(): number {
        return this.modelValue;
      },
      set(modelValue: number) {
        this.$emit("update:modelValue", modelValue);
      },
    },
    stars(): StarViewModel[] {
      const stars = [] as StarViewModel[];
      for (let i = 1; i <= this.starsCount; i++) {
        const halfs = [] as StarHalfViewModel[];
        halfs.push({
          value: i - 0.5,
          icon: [this.halfFilled(i - 0.5), "star-half"],
          class: "",
        });
        halfs.push({
          value: i,
          icon: [this.halfFilled(i), "star-half"],
          class: "fa-flip-horizontal",
        });
        stars.push({ index: i, halfs: halfs });
      }
      return stars;
    },
    starSize(): string {
      return "star-" + this.size;
    },
  },
  methods: {
    halfFilled(value: number): string {
      const ratingValue = this.hoverValue > 0 ? this.hoverValue : this.ratingValue;
      return ratingValue >= value ? "fas" : "far";
    },
    halfEnter(half: StarHalfViewModel) {
      if (this.readonly) return;
      this.hoverValue = half.value;
    },
    halfLeave(half: StarHalfViewModel) {
      if (this.readonly) return;
      this.hoverValue = this.ratingValue;
    },
    halfClick(half: StarHalfViewModel) {
      if (this.readonly) return;
      this.ratingValue = half.value;
    },
  },
});

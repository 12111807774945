import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_intro = _resolveComponent("section-intro")!
  const _component_section_courses = _resolveComponent("section-courses")!
  const _component_section_progress = _resolveComponent("section-progress")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_section_intro),
    _createVNode(_component_section_courses),
    _createVNode(_component_section_progress)
  ], 64))
}
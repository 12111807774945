import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "player-menu"
}
const _hoisted_2 = { class: "lessons" }
const _hoisted_3 = { class: "nav flex-column" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "bi bi-check-circle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.rootItem)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.$store.getters.settings.textPlayerLesson) + ": ", 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.currentIndex) + "/" + _toDisplayString(_ctx.rootItem.items.length), 1)
        ]),
        _createElementVNode("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rootItem.items, (item) => {
            return (_openBlock(), _createElementBlock("li", {
              class: _normalizeClass(["nav-item", {
          active: item.activityId === _ctx.currentActivityId,
        }]),
              key: item.activityId
            }, [
              _createElementVNode("a", {
                class: "nav-link",
                onClick: _withModifiers(($event: any) => (_ctx.menuItemClick(item.activityId)), ["stop"]),
                href: "#"
              }, [
                (item.status === 'success')
                  ? (_openBlock(), _createElementBlock("i", _hoisted_5))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(item.title), 1)
              ], 8, _hoisted_4)
            ], 2))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}
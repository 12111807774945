
import { defineComponent } from "vue";
import Modal from "@/components/shared/Modal.vue";
import { NotificationModel } from "@/services/models";
import { dateTimeUtc } from "@/utils/filters";

export default defineComponent({
  components: { Modal },
  data() {
    return {
      showModal: false,
      notification: {} as NotificationModel,
      redirectToList: false,
    };
  },
  methods: {
    dateTimeUtc,
    async show(notificationId: number, redirectToList?: boolean) {
      this.notification = await this.$api.notificationService.getDetailById(notificationId);
      this.showModal = true;
      this.redirectToList = redirectToList ?? false;
    },
  },
  watch: {
    showModal() {
      if (!this.showModal && this.redirectToList) {
        this.$router.replace({ name: "Notifications" });
      }
    },
  },
});

export declare class NotificationModalComponent {
  show: (notificationId: number, redirectToList?: boolean) => void;
}

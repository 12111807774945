import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "card badge-panel mb-4" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "fw-bold" }
const _hoisted_7 = {
  key: 1,
  class: "d-flex h-100 flex-column align-items-center justify-content-center"
}
const _hoisted_8 = { class: "fs-5" }
const _hoisted_9 = { class: "fs-6" }
const _hoisted_10 = {
  key: 0,
  class: "card-footer text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$getSettings("textGamificationBadges")), 1),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.badges.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.badges, (badge) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "badge",
                key: badge.id
              }, [
                _createVNode(_component_v_tooltip, null, {
                  popper: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(badge.name), 1),
                    _createElementVNode("div", null, _toDisplayString(badge.description), 1)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: badge.badgeUrl
                    }, null, 8, _hoisted_5)
                  ]),
                  _: 2
                }, 1024)
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$getSettings("textGamificationBadgesNoData")), 1),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$getSettings("textEarnGamificationBadgesText")), 1)
          ]))
    ]),
    (_ctx.badges.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(_component_router_link, { to: { name: 'GamificationBadges' } }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$getSettings("textShowMore")), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}
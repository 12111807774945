
import { UserOrderModel } from "@/services/models";
import { defineComponent } from "vue";
import moment from "moment";

export default defineComponent({
  props: {
    order: Object as () => UserOrderModel,
  },
  methods: {
    moment,
    orderDetail(id: number) {
      this.$router.push("/order/detail/" + id);
    },
  },
});

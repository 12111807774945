import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-3" }
const _hoisted_2 = {
  key: 0,
  class: "h4 text-primary text-center mt-3 no-results-text"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_guide = _resolveComponent("guide")!
  const _component_pagination = _resolveComponent("pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.guides, (guide) => {
        return (_openBlock(), _createBlock(_component_guide, {
          key: guide.id,
          guide: guide,
          onSelectGuide: _ctx.download
        }, null, 8, ["guide", "onSelectGuide"]))
      }), 128))
    ]),
    (_ctx.showNoResults && _ctx.showNoResultText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", { innerHTML: _ctx.noResultsText }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _createVNode(_component_pagination, {
        total: _ctx.total,
        ref: "pagination",
        onReload: _ctx.paginationLoad,
        pageSize: _ctx.pageSize
      }, null, 8, ["total", "onReload", "pageSize"])
    ], 512), [
      [_vShow, _ctx.guides.length > 0]
    ])
  ], 64))
}
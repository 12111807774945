
import { GuideModel } from "@/services/models";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    guide: Object as () => GuideModel,
  },
  computed: {
    imageOrDefaultUrl(): string {
      return this.guide?.imageUrl ? this.guide.imageUrl : "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs=";
    },
    iconName(): string {
      return this.$store.getters.settings.guidesOpenMode === "NewTab" ? "external-link-alt" : "cloud-download-alt";
    },
  },
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "section-progress"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "h2 title" }
const _hoisted_4 = { class: "subtitle" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col stats-item mt-5" }
const _hoisted_7 = { class: "value" }
const _hoisted_8 = { class: "desc" }
const _hoisted_9 = { class: "col stats-item mt-5" }
const _hoisted_10 = { class: "value" }
const _hoisted_11 = { class: "desc" }
const _hoisted_12 = { class: "col stats-item mt-5" }
const _hoisted_13 = { class: "value" }
const _hoisted_14 = { class: "desc" }
const _hoisted_15 = { class: "col stats-item mt-5 coming-soon" }
const _hoisted_16 = { class: "value" }
const _hoisted_17 = { class: "desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_coming_soon = _resolveComponent("icon-coming-soon")!

  return (_ctx.progress && _ctx.$store.getters.settings.homepageSectionProgressEnabled)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$store.getters.settings.textHomepageSectionProgressTitle), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$store.getters.settings.textHomepageSectionProgressSubtitle), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.progress.finished), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$store.getters.settings.textHomepageSectionProgressCoursesCompleted), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.progress.totalTime), 1),
              _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$store.getters.settings.textHomepageSectionProgressStudyTime), 1)
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.progress.courses - _ctx.progress.finished), 1),
              _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$store.getters.settings.textHomepageSectionProgressCoursesInProgress), 1)
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_icon_coming_soon)
              ]),
              _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$store.getters.settings.textHomepageSectionProgressAwardsEarned), 1)
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
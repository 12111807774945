
import { ResetPasswordModel } from "@/services/models";
import { defineComponent } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, sameAs } from "@vuelidate/validators";

interface ResetPasswordViewModel {
  passwordChanged: boolean;
  model: ResetPasswordModel;
  errorMessage: string;
  isValid: boolean;
  email: string;
}

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      passwordChanged: false,
      model: {},
      errorMessage: "",
      isValid: false,
    } as ResetPasswordViewModel;
  },
  async mounted() {
    if (!this.$route.params.token) {
      return;
    }

    try {
      const data = await this.$api.accountService.verifyResetPasswordLink(this.$route.params.token.toString());

      this.isValid = true;
      this.email = data.email!;
      this.model.secureDataHash = this.$route.params.token.toString();
    } catch {
      this.isValid = false;
    }
  },
  methods: {
    send() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.$api.accountService
          .resetPassword(this.model)
          .then(() => {
            this.passwordChanged = true;
          })
          .catch(() => {
            this.errorMessage = "Err..";
          });
      }
    },
  },
  validations() {
    return {
      model: {
        password: {
          required,
          minLength: minLength(6),
        },
        passwordAgain: {
          required,
          sameAsPassword: sameAs(this.model.password),
        },
      },
    };
  },
});


import { defineComponent } from "vue";
import PageHeader from "@/components/layout/PageHeader.vue";
export default defineComponent({
  components: { PageHeader },
  mounted() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("login-page");
  },
  unmounted() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("login-page");
  },
});

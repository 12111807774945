import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createBlock(_component_modal, {
    title: _ctx.notification.title,
    modelValue: _ctx.showModal,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showModal) = $event)),
    size: 'lg'
  }, {
    "modal-body": _withCtx(() => [
      _createElementVNode("div", {
        innerHTML: _ctx.notification.description
      }, null, 8, _hoisted_1)
    ]),
    "modal-footer": _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.dateTimeUtc(_ctx.notification.createdDate)), 1)
    ]),
    _: 1
  }, 8, ["title", "modelValue"]))
}
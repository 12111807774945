
import { defineComponent } from "vue";
import CurrentLevel from "@/components/gamification/CurrentLevel.vue";
import BadgePanel from "@/components/gamification/BadgePanel.vue";
import RelatedCourses from "@/components/courses/RelatedCourses.vue";
import { UserProgressModel } from "@/services/models";
import FinishedCoursesStat from "@/components/dashboard/FinishedCoursesStat.vue";
import AverageScoreStat from "@/components/dashboard/AverageScoreStat.vue";
import EventsPanel from "@/components/dashboard/EventsPanel.vue";
import StudyPathsPanel from "@/components/dashboard/StudyPathsPanel.vue";
import MyCoursesPanel from "@/components/dashboard/MyCoursesPanel.vue";
import StudyTimeChart from "@/components/dashboard/StudyTimeChart.vue";

export default defineComponent({
  components: {
    CurrentLevel,
    BadgePanel,
    RelatedCourses,
    FinishedCoursesStat,
    AverageScoreStat,
    EventsPanel,
    StudyPathsPanel,
    MyCoursesPanel,
    StudyTimeChart,
  },
  async mounted() {
    if (!this.$getSettings("dashboardEnabled")) {
      this.$router.push("/");
      return;
    }
    if (!this.progress) {
      await this.$store.dispatch("loadUserProgress");
    }
  },
  computed: {
    progress(): UserProgressModel | null {
      return this.$store.state.progress;
    },
  },
});


import { defineComponent } from "vue";
import type { RatingBaseModel, RatingBaseModelListResponse } from "@/services/models";
import type { RatingListRequest } from "@/services/ratingService";
import RatingInfo from "./RatingInfo.vue";
import Pagination, { PaginationComponent } from "../shared/Pagination.vue";
import { dateTimeUtc } from "@/utils/filters";

export default defineComponent({
  components: { RatingInfo, Pagination },
  data() {
    return {
      ratings: [] as Array<RatingBaseModel>,
      total: 0,
    };
  },
  props: {
    portalProductId: {
      type: Number,
      required: false,
    },
    eventId: {
      type: Number,
      required: false,
    },
    hideNoData: Boolean,
    noRatingText: {
      type: String,
      required: true,
    },
  },
  methods: {
    dateTimeUtc,
    async load() {
      let request: RatingListRequest | null = null;

      if (this.portalProductId) {
        request = { pageSize: this.pagination.getPageSize(), page: this.pagination.getPage(), portalProductId: this.portalProductId };
      } else if (this.eventId) {
        request = { pageSize: this.pagination.getPageSize(), page: this.pagination.getPage(), eventId: this.eventId };
      }

      if (!request) return;

      const response = await this.$api.ratingService.getList(request);
      this.total = response.total ?? 0;
      this.ratings = response.data ?? [];
    },
    initList(list: RatingBaseModelListResponse) {
      this.total = list.total ?? 0;
      this.ratings = list.data ?? [];

      this.pagination.setTotal(this.total);
    },
  },
  computed: {
    pagination(): PaginationComponent {
      return this.$refs.pagination as PaginationComponent;
    },
  },
});

export declare class RatingListComponent {
  initList: (list: RatingBaseModelListResponse) => Promise<void>;
  load: () => Promise<void>;
}


import { defineComponent } from "vue";
import { AcademySettingsModel, UserOrderModel } from "@/services/models";
import SearchList from "@/components/shared/SearchList.vue";
import OrderList, { OrderListComponent } from "@/components/orders/OrderList.vue";
import { delay, delayCallback, ListOptions } from "@/utils/common";

interface OrdersViewModel {
  listOptions: ListOptions;
  search: string;
  searchDelay: delayCallback;
}

export default defineComponent({
  components: {
    OrderList,
    SearchList,
  },
  data() {
    return {
      listOptions: { search: "" },
      searchDelay: delay(450),
    } as OrdersViewModel;
  },
  async mounted() {
    if (this.$route.query.search) this.listOptions.search = this.$route.query.search.toString();

    await this.load();
  },
  methods: {
    async load() {
      await this.orderList.load(this.listOptions);
    },
    searchOrder() {
      this.searchDelay(() => {
        this.orderList.setOptions(this.listOptions);
        this.orderList.reload();
      });
    },
  },
  computed: {
    orderList(): OrderListComponent {
      return this.$refs.orderList as OrderListComponent;
    },
    settings() {
      return this.$store.state.academy.settings as AcademySettingsModel;
    },
  },
});

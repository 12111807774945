
import { StudyTimeItem } from "@/services/models";
import { Chart, ChartData, ChartOptions } from "chart.js";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      chart: null as Chart<"bar", unknown> | null,
    };
  },
  props: {
    chartData: {
      type: Array as () => StudyTimeItem[],
      required: true,
    },
  },
  async mounted() {
    await this.initChart();
    window.addEventListener("resize", this.resizeChart);
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeChart);
  },
  methods: {
    resizeChart() {
      if (this.chart) {
        this.chart.resize();
      }
    },
    async initChart() {
      await this.$nextTick();

      var element = (this.$el as HTMLElement).getElementsByTagName("canvas")[0] as HTMLCanvasElement;
      if (element) {
        const options: ChartOptions<"bar"> = {
          responsive: true,
          maintainAspectRatio: true,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                callback: function (value) {
                  return value + " min";
                },
              },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: (context) => {
                  const time = this.chartData[context.dataIndex].time!;
                  return `${time.hours}h ${time.minutes}m ${time.seconds}s`;
                },
              },
            },
          },
        };

        const data: ChartData<"bar"> = {
          labels: this.chartData.map((item) => item.month),
          datasets: [
            {
              label: "This year",
              data: this.chartData.map((item) => item.time!.totalMinutes!),
              backgroundColor: this.$store.state.academy?.settings?.colorSecondary ?? "",
              borderColor: this.$store.state.academy?.settings?.colorSecondary ?? "",
              borderWidth: 1,
            },
          ],
        };

        // this breaks TS
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.chart = new Chart(element, {
          type: "bar",
          data,
          options,
        });
      }
    },
  },
});


import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      email: "",
      password: "",
      remember: false,
      error: false,
    };
  },
  methods: {
    send() {
      this.$api.accountService
        .login({ ...this.$data })
        .then((result) => {
          localStorage.setItem("authUser", JSON.stringify(result));
          this.$store.commit("loginSuccess", result);
          this.$store.dispatch("loadUserProgress");
          this.$router.push({ name: "Home" });
        })
        .catch((err) => {
          this.error = true;
        });
    },
  },
  computed: {
    showRegistrationLink() {
      return this.$store.state.academy.allowRegistration;
    },
    ssoLoginUrl() {
      return this.$store.state.academy.ssoLoginUrl;
    },
  },
});


import { ProductTypes, StudyPathItemModel, UserCourseModel } from "@/services/models";
import { defineComponent } from "vue";
import RatingInfo from "@/components/rating/RatingInfo.vue";
import CircleProgress from "../progress/CircleProgress.vue";
import { truncate } from "@/utils/filters";
import UserCourseButton from "@/components/courses/UserCourseButton.vue";
import CourseCountdown from "@/components/courses/CourseCountdown.vue";

export default defineComponent({
  components: {
    RatingInfo,
    CircleProgress,
    UserCourseButton,
    CourseCountdown,
  },
  emits: ["infoShown"],
  props: {
    course: Object as () => UserCourseModel,
    pathItem: Object() as () => StudyPathItemModel | null,
    showInfo: Boolean,
  },
  computed: {
    imageOrDefaultUrl(): string {
      return this.course?.imageUrl ? this.course.imageUrl : "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs=";
    },
    detailButtonEnabled() {
      return this.$getSettings("courseDetailEnabled") && !this.pathItem;
    },
  },
  methods: {
    truncate,
    study(id: number) {
      if (this.pathItem?.id) {
        this.$router.push(`/study-path/study/${this.pathItem.id}`);
        return;
      }

      if (this.course!.type === ProductTypes.Path) {
        this.$router.push(`/study-path/${id}`);
        return;
      }

      this.$router.push(`/my-courses/study/${id}`);
    },
    showInfoClick() {
      this.$emit("infoShown", this.course, this.pathItem);
    },
    detail(id: number) {
      this.$router.push(`/my-courses/detail/${id}`);
    },
    async downloadStudioCertificate() {
      this.$api.userService.downloadCertificate(this.course!.userProductId!).then((response) => {
        const downloadLink = document.createElement("a");
        downloadLink.download = `${this.course?.name}.pdf`;
        downloadLink.href = window.URL.createObjectURL(new Blob([response.data]));
        downloadLink.click();
        downloadLink.remove();
      });
    },
  },
});

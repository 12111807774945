
import { PlayerTocItem } from "@/services/models";
import { defineComponent } from "vue";
import { size, findIndex } from "lodash";

interface PlayerMenuViewModel {
  framesetManager: any | null;
  frameLearnTask: HTMLIFrameElement | null;
  frameRoot: HTMLIFrameElement | null;
  framesetParentUI: HTMLIFrameElement | null;
  frameToc: HTMLIFrameElement | null;
  currentActivityId: number | null;
  updateHeight: (frameContent: HTMLIFrameElement, frameRoot: HTMLIFrameElement) => void;
}

export default defineComponent({
  emits: ["menuLoaded"],
  data() {
    return {
      framesetManager: null,
      frameLearnTask: null,
      frameRoot: null,
      currentActivityId: null,
    } as PlayerMenuViewModel;
  },
  props: {
    toc: Object as () => PlayerTocItem[],
  },
  computed: {
    rootItem(): PlayerTocItem | null {
      return this.toc ? this.toc[0] : null;
    },
    lessons(): PlayerTocItem[] {
      return this.rootItem?.items ?? [];
    },
    currentIndex(): number {
      return this.lessons.findIndex((item) => item.activityId === this.currentActivityId) + 1;
    },
    currentTocItem(): PlayerTocItem | null {
      return this.lessons.find((item) => item.activityId === this.currentActivityId) ?? null;
    },
  },
  mounted() {
    if (this.lessons.length > 0) this.currentActivityId = this.lessons[0].activityId ?? null;
  },
  methods: {
    init(
      framesetManager: any,
      frameRoot: HTMLIFrameElement,
      frameLearnTask: HTMLIFrameElement,
      updateHeight: (frameContent: HTMLIFrameElement, frameRoot: HTMLIFrameElement) => void,
    ) {
      this.framesetManager = framesetManager;
      this.frameLearnTask = frameLearnTask;
      this.frameRoot = frameRoot;
      this.updateHeight = updateHeight;

      this.framesetParentUI = frameLearnTask.contentWindow!.document.getElementById("framesetParentUI") as HTMLIFrameElement;
      this.framesetParentUI!.setAttribute("cols", "*, 0");
      this.frameToc = frameLearnTask.contentWindow!.document.getElementById("frameToc") as HTMLIFrameElement;

      const tocBody = this.frameToc!.contentWindow!.document.getElementById("tocBody");

      tocBody!.style.display = "none";

      setTimeout(() => {
        if (this.framesetManager.m_activityId) {
          /* eslint-disable camelcase */
          this.currentActivityId = parseInt(this.framesetManager.m_activityId);
        }

        this.$emit("menuLoaded");
      }, 1000);

      this.framesetManager.updateTocStatus = (name: string, value: string) => {
        if (this.isLesssonCompleted(name, value) || name.match(/.*exit.*/) != null) {
          /* eslint-disable camelcase */
          this.framesetManager.m_checkRating = true;
        }
      };
    },
    menuItemClick(activityId: number) {
      this.framesetManager.DoChoice(activityId.toString(), false, true);
      this.currentActivityId = activityId;
      setTimeout(() => {
        this.frameRoot!.style.height = "0px";
        this.updateHeight(this.frameToc!, this.frameRoot!);
      }, 1000);
    },
    isLesssonCompleted(name: string, value: string) {
      return (
        (name.match(/.*completion.*status.*/) != null && value === "completed") ||
        (name.match(/.*success.*status.*/) != null && value === "passed") ||
        (name.match(/.*lesson.*status.*/) != null && value === "passed")
      );
    },
  },
});

export declare class PlayerMenuComponent {
  init: (
    frmManager: any,
    frameRoot: HTMLIFrameElement,
    frameLearnTask: HTMLIFrameElement,
    updateHeight: (frameContent: HTMLIFrameElement, frameRoot: HTMLIFrameElement) => void,
  ) => void;
}

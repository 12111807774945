<template>
  <div class="d-flex align-items-center justify-content-center h-100">
    <div class="error-icon">
      <i class="bi bi-emoji-frown"></i>
    </div>
    <div class="error-text">
      <h1>404</h1>
      <h3>NOT FOUND</h3>
    </div>
  </div>
</template>

<style>
#app > div {
  height: 100% !important;
}

.error-icon {
  font-size: 8rem;
}
.error-text {
  margin-left: 20px;
}
</style>

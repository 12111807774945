<template>
  <div class="col d-flex align-items-stretch">
    <div class="card course-item course-item-placeholder flex-fill mx-3 mb-4">
      <div class="card-img">
        <img src="data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==" class="card-img-top" />
      </div>
      <div class="card-body d-flex">
        <div class="card-content d-flex flex-fill flex-column">
          <h5 class="card-title mb-0"><span class="placeholder col-6"></span></h5>
          <p class="card-text">
            <span class="placeholder col-7"></span>
            <span class="placeholder col-4"></span>
            <span class="placeholder col-4"></span>
            <span class="placeholder col-6"></span>
            <span class="placeholder col-8"></span>
          </p>
          <a href="#" tabindex="-1" class="btn btn-primary disabled placeholder col-6"></a>
        </div>
      </div>
    </div>
  </div>
</template>

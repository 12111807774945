/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AcademyCourseDetailModel {
  /** @format int32 */
  id?: number;

  /** @format date-time */
  createDate?: string;
  name?: string | null;
  description?: string | null;
  imageUrl?: string | null;

  /** @format double */
  price?: number | null;
  formatedPrice?: string | null;
  isPaid?: boolean;
  estimatedTime?: string | null;
  type?: ProductTypes;
  belongToUser?: boolean;
  comments?: CommentModelListResponse;
  ratings?: RatingBaseModelListResponse;
  ratingSummary?: RatingSummaryModel;
}

export interface AcademyCourseModel {
  /** @format int32 */
  id?: number;

  /** @format date-time */
  createDate?: string;
  name?: string | null;
  description?: string | null;
  imageUrl?: string | null;

  /** @format double */
  price?: number | null;
  formatedPrice?: string | null;
  isPaid?: boolean;
  estimatedTime?: string | null;
  type?: ProductTypes;
  belongToUser?: boolean;
}

export interface AcademyCourseModelListResponse {
  /** @format int32 */
  total?: number;
  data?: AcademyCourseModel[] | null;
}

export interface AcademyModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
  url?: string | null;
  logoUrl?: string | null;
  isPrivate?: boolean;
  allowRegistration?: boolean;
  settings?: AcademySettingsModel;
  ssoLoginUrl?: string | null;
  paymentMethods?: PaymentMethodModel[] | null;
  allowEcommerce?: boolean;
}

export interface AcademySettingsModel {
  colorPrimary?: string | null;
  colorSecondary?: string | null;
  colorButton?: string | null;
  colorButtonFont?: string | null;
  colorButtonHover?: string | null;
  colorSecondaryButton?: string | null;
  colorSecondaryButtonFont?: string | null;
  colorSecondaryButtonHover?: string | null;
  colorHeaderBg?: string | null;
  colorHeaderFont?: string | null;
  colorPrimaryBg?: string | null;
  colorPrimaryBgFont?: string | null;
  colorSecondaryBg?: string | null;
  colorSecondaryBgFont?: string | null;
  textHeaderTitle?: string | null;
  textHeader?: string | null;
  imageHeader?: string | null;
  imageFooter?: string | null;
  introVideoTitle?: string | null;
  introVideoSubTitle?: string | null;
  introVideoDesc?: string | null;
  introVideoUrl?: string | null;
  introThumbnail?: string | null;
  iconCourses?: string | null;
  iconTutorials?: string | null;
  iconGuides?: string | null;
  iconCertificates?: string | null;
  iconTime?: string | null;
  iconCoursesFinished?: string | null;
  iconTests?: string | null;
  iconBestScore?: string | null;
  colorFooterBg?: string | null;
  colorFooterBgFont?: string | null;
  footerCol1?: string | null;
  footerCol2?: string | null;
  footerCol3?: string | null;
  footerCol4?: string | null;
  footerCol5?: string | null;
  textCopyright?: string | null;
  imageLoginPage?: string | null;
  customCss?: string | null;
  headScripts?: string | null;
  textSearchBoxNoResults?: string | null;
  favicon?: string | null;
  textRegistrationBeforeCategories?: string | null;
  textRegistrationSelectCategories?: string | null;
  textSelectCategory?: string | null;
  textSelectTag?: string | null;
  textBackToCategories?: string | null;
  textBackToCourses?: string | null;
  textLoginAfterForm?: string | null;
  textLoginBeforeSso?: string | null;
  textRegistrationSuccess?: string | null;
  textRegistrationSuccessNotAllowedDomain?: string | null;
  tutorialsEnabled?: boolean;
  tutorialsOpenIn?: string | null;
  textTutorialsMenuLink?: string | null;
  textTutorialsTitle?: string | null;
  textTutorialsSubtitle?: string | null;
  textTutorialsBackToTutorials?: string | null;
  guidesEnabled?: boolean;
  textGuidesMenuLink?: string | null;
  textGuidesTitle?: string | null;
  textGuidesSubtitle?: string | null;
  textHomeMenuLink?: string | null;
  textCategoriesMenuLink?: string | null;
  textCategoriesPageTitle?: string | null;
  textCategoriesSubtitle?: string | null;
  textMyCoursesMenuLink?: string | null;
  textMyCoursesTitle?: string | null;
  textMyCoursesSubtitle?: string | null;
  textSearch?: string | null;
  textSearchWriteText?: string | null;
  guidesOpenMode?: string | null;
  progressEnabled?: boolean;
  textProgressTitle?: string | null;
  textProgressMenuLink?: string | null;
  homepageSectionNewCoursesEnabled?: boolean;
  textHomepageSectionNewCoursesTitle?: string | null;
  homepageSectionAssignedCoursesEnabled?: boolean;
  textHomepageSectionAssignedCoursesTitle?: string | null;
  homepageSectionProgressEnabled?: boolean;
  textHomepageSectionProgressTitle?: string | null;
  textHomepageSectionProgressCoursesCompleted?: string | null;
  textHomepageSectionProgressStudyTime?: string | null;
  textHomepageSectionProgressCoursesInProgress?: string | null;
  textHomepageSectionProgressAwardsEarned?: string | null;
  textHomepageSectionProgressSubtitle?: string | null;
  textSignOut?: string | null;
  textSignIn?: string | null;
  textSignInButton?: string | null;
  textRegistration?: string | null;
  textRegistrationTitle?: string | null;
  textRegistrationText?: string | null;
  textRegistrationButton?: string | null;
  textRegistrationAlreadyUser?: string | null;
  textRegistrationUserExists?: string | null;
  textOrSignWith?: string | null;
  textSignInWithSso?: string | null;
  textForgottenPassword?: string | null;
  textForgottenPasswordTitle?: string | null;
  textForgottenPasswordText?: string | null;
  textForgottenPasswordSubmit?: string | null;
  textForgottenPasswordBackToSignIn?: string | null;
  textConfirmPassword?: string | null;
  textWrongEmailOrPassword?: string | null;
  textForgottenPasswordLinkSent?: string | null;
  textPassword?: string | null;
  headerLayoutType?: string | null;
  tutorialBoxShowTitle?: boolean;
  guideBoxShowTitle?: boolean;
  enableEcommerceView?: boolean;
  coursesEnabled?: boolean;
  imageStudyPathTrophy?: string | null;
  imageStudyPathTrophyFinished?: string | null;
  textCourseFinished?: string | null;
  textCourseLocked?: string | null;
  textCourseWaitingForEvaluation?: string | null;
  textCourseStart?: string | null;
  textCourseOpen?: string | null;
  textPlayerHideMenu?: string | null;
  textBackToStudyPath?: string | null;
  textPlayerNextCourse?: string | null;
  textPlayerPreviousCourse?: string | null;
  textPlayerSavingCourseProgress?: string | null;
  textPlayerMaximize?: string | null;
  textPlayerMinimize?: string | null;
  textSignInToStudyCourse?: string | null;
  textRecommendedCourses?: string | null;
  textRecommendedCoursesNoData?: string | null;
  textStudyPath?: string | null;
  textCourseRequired?: string | null;
  textCourseNotRated?: string | null;
  textCourseInfo?: string | null;
  textGuidesDownload?: string | null;
  textTutorialsWatch?: string | null;
  textActivateAccountTitle?: string | null;
  textActivateAccountAccount?: string | null;
  textActivateAccountKnowPassword?: string | null;
  textActivateAccountNewPassword?: string | null;
  textActivateAccountActivate?: string | null;
  textActivateAccountLinkNotValid?: string | null;
  textActivateAccountWrongPassword?: string | null;
  textPlayerMenu?: string | null;
  textPlayerLesson?: string | null;
  headerObjectType?: string | null;
  headerCarouselItems?: string[] | null;
  headerCarouselAnimation?: string | null;
  headerCarouselInterval?: string | null;
  headerCarouselNavigationEnabled?: boolean;
  textMyProfile?: string | null;
  textMyProfileSaveProfile?: string | null;
  textMyProfileProfileSaved?: string | null;
  textNotifications?: string | null;
  textNotificationsDate?: string | null;
  textNotificationsTitle?: string | null;
  textNewNotifications?: string | null;
  textOpenedNotifications?: string | null;
  textNoNewNotifications?: string | null;
  textNoNotifications?: string | null;
  textNotificationsSeeAll?: string | null;
  textChangePassword?: string | null;
  textCurrentPassword?: string | null;
  textNewPassword?: string | null;
  textChangePasswordChanged?: string | null;
  textChangePasswordNotChanged?: string | null;
  textPhone?: string | null;
  textTitleBefore?: string | null;
  textTitleAfter?: string | null;
  textFirstName?: string | null;
  textLastName?: string | null;
  textEmail?: string | null;
  textBuyOneLicense?: string | null;
  textBuyMultipleLicense?: string | null;
  textTotalExclTax?: string | null;
  textTotalInclTax?: string | null;
  textConfirmOrderButton?: string | null;
  textCustomerAddress?: string | null;
  textCompanyCustomer?: string | null;
  textCompanyName?: string | null;
  textCompanyIdentificationNumber?: string | null;
  textVatNumber?: string | null;
  textCity?: string | null;
  textStreet?: string | null;
  textZip?: string | null;
  textState?: string | null;
  textChooseState?: string | null;
  textContinueWithShopping?: string | null;
  textClearShoppingCart?: string | null;
  textCheckout?: string | null;
  textAddedToCart?: string | null;
  textCannotAddToCart?: string | null;
  textAlreadyInCart?: string | null;
  textBuy?: string | null;
  textFree?: string | null;
  textLicenseDetail?: string | null;
  textAvaiableLicenses?: string | null;
  textWatingForActivation?: string | null;
  textActiveLicenses?: string | null;
  textTotalLicenses?: string | null;
  textAssignToUser?: string | null;
  textInvitationState?: string | null;
  textActivationDate?: string | null;
  textSentDate?: string | null;
  textFullname?: string | null;
  textInvitationRemove?: string | null;
  textLicensesPageTitle?: string | null;
  textLicensesSubtitle?: string | null;
  textCourseName?: string | null;
  textAction?: string | null;
  textLicenseDetailButton?: string | null;
  textAllPaidCourses?: string | null;
  textPaymentMethod?: string | null;
  textLicenseHistory?: string | null;
  textOrderHistory?: string | null;
  textExpirationDate?: string | null;
  textAresSearch?: string | null;
  textAresLabel?: string | null;
  textPurchaseOrder?: string | null;
  textDownloadInvoice?: string | null;
  textPaymentStatus?: string | null;
  textUnknowPaymentStatus?: string | null;
  textPaidPaymentStatus?: string | null;
  textPendingPaymentStatus?: string | null;
  textOrdersPageTitle?: string | null;
  textOrdersSubtitle?: string | null;
  textCustomOrderNumber?: string | null;
  textCreatedOnUtc?: string | null;
  textCourseCount?: string | null;
  textOrderStatus?: string | null;
  textTotalPrice?: string | null;
  textOrderDetailButton?: string | null;
  textPriceInclTax?: string | null;
  textPriceExclTax?: string | null;
  textOrderDetailPageTitle?: string | null;
  textOrderDetailPageSubTitle?: string | null;
  gamificationEnabled?: boolean;
  textGamification?: string | null;
  textGamificationObjectives?: string | null;
  textGamificationChallenges?: string | null;
  textGamificationStreaks?: string | null;
  textGamificationObjective?: string | null;
  textGamificationChallenge?: string | null;
  textGamificationStreak?: string | null;
  textGamificationObjectivesNoData?: string | null;
  textGamificationChallengesNoData?: string | null;
  textGamificationStreaksNoData?: string | null;
  textGamificationStateAll?: string | null;
  textGamificationStateActive?: string | null;
  textGamificationStateCompleted?: string | null;
  textGamificationStateNotCompleted?: string | null;
  textGamificationStateExpired?: string | null;
  textGamificationCurrentLevel?: string | null;
  textGamificationLeaderboard?: string | null;
  textGamificationBadges?: string | null;
  textGamificationBadgesNoData?: string | null;
  textEarnGamificationBadgesText?: string | null;
  textGamificationPoints?: string | null;
  textBackToGamification?: string | null;
  textGamificationFullName?: string | null;
  textGamificationPointsToNextLevel?: string | null;
  gamificationLeaderboardByGroups?: boolean;
  dashboardEnabled?: boolean;
  textDashboard?: string | null;
  textShowMore?: string | null;
  textFinishedCourses?: string | null;
  textAverageScore?: string | null;
  textStudyPaths?: string | null;
  textStudyPathsNoData?: string | null;
  textFindStudyPaths?: string | null;
  textStudyTime?: string | null;
  textCoursesNoData?: string | null;
  textFindCourses?: string | null;
  eventsEnabled?: boolean;
  textEvents?: string | null;
  textEventsMenuLink?: string | null;
  textEventsFree?: string | null;
  textEventsDetail?: string | null;
  textEventsBuy?: string | null;
  textEventsRegister?: string | null;
  textEventsAlreadyRegistredWarn?: string | null;
  textEventsCannotRegisterWarn?: string | null;
  textEventsCapacity?: string | null;
  textEventsCapacityAvailable?: string | null;
  textEventsGoToEventsPage?: string | null;
  textEventsInstanceLoadingError?: string | null;
  textEventsLector?: string | null;
  textEventsLocation?: string | null;
  textEventsParticipants?: string | null;
  textEventsPastInstanceWarn?: string | null;
  textEventsPrice?: string | null;
  textEventsRegisterClose?: string | null;
  textEventsRegisterConfirmTitle?: string | null;
  textEventsRegisterNo?: string | null;
  textEventsRegisterSuccessTitle?: string | null;
  textEventsRegisterYes?: string | null;
  textEventsSelectInstance?: string | null;
  textEventsTabContent?: string | null;
  textEventsTabDownload?: string | null;
  textEventsUrl?: string | null;
  textEventsInstance_N1?: string | null;
  textEventsInstance_N2_N4?: string | null;
  textEventsInstance_N5?: string | null;
  textEventRatings?: string | null;
  textEventNotRated?: string | null;
  courseDetailEnabled?: boolean;
  textCourseRatings?: string | null;
  textNoRatings?: string | null;
  textRatings?: string | null;
  textComments?: string | null;
  textAnswers?: string | null;
  textAnswer?: string | null;
  textClose?: string | null;
  textCloseAnswers?: string | null;
  textNoComments?: string | null;
  textWriteComment?: string | null;
  textMyCertificatesDownloadTooltip?: string | null;
}

export interface ActivateAccountModel {
  email?: string | null;
  tokenHash?: string | null;
}

export interface ActivateAccountRequest {
  password: string;
  passwordAgain?: string | null;
  tokenHash: string;
  knowMyPassword: boolean;
}

export interface AddressModel {
  /** @format int32 */
  countryId?: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  company?: string | null;
  city?: string | null;
  address?: string | null;
  zipPostalCode?: string | null;
  vatNumber?: string | null;
  companyIdentificationNumber?: string | null;
}

export interface AssigneeModel {
  email?: string | null;
}

export interface AuthUserModel {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;

  /** @format int32 */
  userId?: number;
  token?: JwtTokenModel;
  avatarUrl?: string | null;

  /** @format int32 */
  portalId?: number;
  lmsHubToken?: JwtTokenModel;
}

export interface CanRateProductModel {
  rated?: boolean;
  productFinished?: boolean;
  canRate?: boolean;
}

export interface CommentModel {
  /** @format int32 */
  id?: number;
  text?: string | null;

  /** @format date-time */
  createdDate?: string;
  fullName?: string | null;

  /** @format int32 */
  userId?: number;

  /** @format int32 */
  answersCount?: number;
}

export interface CommentModelListResponse {
  /** @format int32 */
  total?: number;
  data?: CommentModel[] | null;
}

export interface CourseCategoryModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
  description?: string | null;
  imageUrl?: string | null;
  hasGroups?: boolean;

  /** @format int32 */
  parentCategoryId?: number | null;
  parentCategoryName?: string | null;
}

export interface CourseCategoryModelListResponse {
  /** @format int32 */
  total?: number;
  data?: CourseCategoryModel[] | null;
}

export interface CourseTagModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
}

export interface CreateCommentRequest {
  text?: string | null;

  /** @format int32 */
  portalProductId?: number | null;

  /** @format int32 */
  parentCommentId?: number | null;
}

export interface CreateOrderRequest {
  addressModel?: AddressModel;
  orderNote?: string | null;
  purchaseOrder?: string | null;

  /** @format int32 */
  customerId?: number;

  /** @format int32 */
  paymentMethodId?: number;

  /** @format int32 */
  currencyId?: number;
  buyMultipleLicences?: boolean;
}

export interface CreateShoppingCartItem {
  /** @format int32 */
  productId?: number;

  /** @format int32 */
  quantity?: number;
}

export enum EmailTemplateTypes {
  Custom = "Custom",
  NewProduct = "NewProduct",
  RegistrationToPortal = "RegistrationToPortal",
  ReorderExpiredProduct = "ReorderExpiredProduct",
  ProductAssigned = "ProductAssigned",
  IntroduceStudent = "IntroduceStudent",
  NotifyPortalExpiration = "NotifyPortalExpiration",
  NotifyCourseExpiration = "NotifyCourseExpiration",
  SendVoucher = "SendVoucher",
  TrialPortalCreated = "TrialPortalCreated",
  VoucherCreatedAndWaintingForApproval = "VoucherCreatedAndWaintingForApproval",
  VoucherStatusChanged = "VoucherStatusChanged",
  ResponsiblePersonProductExpiration = "ResponsiblePersonProductExpiration",
  ResponsiblePersonContentReview = "ResponsiblePersonContentReview",
  ResponsiblePersonNewMessage = "ResponsiblePersonNewMessage",
  NewMessage = "NewMessage",
  NewTestEvaluation = "NewTestEvaluation",
  TestEvaluated = "TestEvaluated",
  LicenseAssigned = "LicenseAssigned",
  OrderPlaced = "OrderPlaced",
  OrderPaid = "OrderPaid",
  LicenseActivated = "LicenseActivated",
  GamificationBadgeEarned = "GamificationBadgeEarned",
}

export interface EventCategoryModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
  description?: string | null;
  imageUrl?: string | null;
}

export interface EventCategoryModelListResponse {
  /** @format int32 */
  total?: number;
  data?: EventCategoryModel[] | null;
}

export interface EventDetailModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
  description?: string | null;
  content?: string | null;
  imageUrl?: string | null;

  /** @format int32 */
  nextInstanceId?: number | null;
  ratingSummary?: RatingSummaryModel;
  ratings?: RatingBaseModelListResponse;
  categories?: number[] | null;
  tags?: number[] | null;
  files?: EventFileModel[] | null;
}

export interface EventFileModel {
  fileName?: string | null;
  originalFileName?: string | null;
  url?: string | null;
  mediaType?: MediaType;
}

export interface EventInstanceDateModel {
  /** @format int32 */
  id?: number;
  allDayEvent?: boolean;

  /** @format date-time */
  startDate?: string;

  /** @format date-time */
  endDate?: string;
}

export interface EventInstanceDateModelListResponse {
  /** @format int32 */
  total?: number;
  data?: EventInstanceDateModel[] | null;
}

export interface EventInstanceModel {
  /** @format int32 */
  id?: number;
  eventName?: string | null;

  /** @format int32 */
  eventId?: number;
  eventType?: EventType;
  eventLocation?: EventLocationModel;
  url?: string | null;
  lectors?: EventLectorModel[] | null;
  allDayEvent?: boolean;

  /** @format date-time */
  startDate?: string;

  /** @format date-time */
  endDate?: string;
  isPaid?: boolean;

  /** @format int32 */
  occupied?: number | null;

  /** @format int32 */
  capacity?: number | null;
  hideCapacity?: boolean;
  participants?: EventParticipantModel[] | null;
  hideParticipants?: boolean;
  canRegister?: boolean;
  registered?: boolean;
}

export interface EventInstanceModelListResponse {
  /** @format int32 */
  total?: number;
  data?: EventInstanceModel[] | null;
}

export enum EventInstanceShow {
  All = "All",
  Upcoming = "Upcoming",
}

export interface EventLectorModel {
  /** @format int32 */
  id?: number;
  firstName?: string | null;
  lastName?: string | null;
}

export interface EventLocationModel {
  name?: string | null;
  location?: string | null;
}

export interface EventModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
  description?: string | null;
  imageUrl?: string | null;

  /** @format float */
  rating?: number;
  nextInstance?: EventNextInstanceModel;

  /** @format int32 */
  upcomingInstancesCount?: number;
}

export interface EventModelListResponse {
  /** @format int32 */
  total?: number;
  data?: EventModel[] | null;
}

export interface EventNextInstanceModel {
  /** @format int32 */
  id?: number;
  isPaid?: boolean;

  /** @format date-time */
  startDate?: string;
  lectors?: EventLectorModel[] | null;
}

export interface EventParticipantModel {
  firstName?: string | null;
  lastName?: string | null;
  avatarUrl?: string | null;
}

export enum EventType {
  Local = "Local",
  Remote = "Remote",
}

export interface ForgottenPasswordModel {
  /** @format email */
  email: string;
}

export interface GamificationBadgeModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
  description?: string | null;
  badgeUrl?: string | null;

  /** @format date-time */
  earnedAt?: string;
}

export interface GamificationBadgeModelListResponse {
  /** @format int32 */
  total?: number;
  data?: GamificationBadgeModel[] | null;
}

export interface GamificationCurrentLevelModel {
  /** @format int32 */
  points?: number;
  currentLevel?: string | null;

  /** @format int32 */
  currentLevelPoints?: number;
  nextLevel?: string | null;

  /** @format int32 */
  nextLevelPoints?: number;
}

export interface GamificationLeaderboardItemModel {
  /** @format int32 */
  id?: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;

  /** @format int32 */
  pointsEarned?: number;

  /** @format int32 */
  position?: number;
}

export interface GamificationLeaderboardItemModelListResponse {
  /** @format int32 */
  total?: number;
  data?: GamificationLeaderboardItemModel[] | null;
}

export enum GamificationObjectiveType {
  Single = "Single",
  Multiple = "Multiple",
  Challenge = "Challenge",
  Streak = "Streak",
}

export interface GamificationStateModel {
  showObjectives?: boolean;
  showChallenges?: boolean;
  showStreaks?: boolean;
}

export enum GamificationTriggerType {
  Registration = "Registration",
  LoggedIn = "LoggedIn",
  RateCourse = "RateCourse",
  CommentCourse = "CommentCourse",
  CompleteCourse = "CompleteCourse",
  CompleteGamificationLevel = "CompleteGamificationLevel",
  GuideDownloaded = "GuideDownloaded",
  TutorialViewed = "TutorialViewed",
  CompleteAllCoursesInGroup = "CompleteAllCoursesInGroup",
  CompleteAllCoursesInCategory = "CompleteAllCoursesInCategory",
}

export interface GroupModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
  preSelected?: boolean;
}

export interface GuideModel {
  /** @format int32 */
  id?: number;

  /** @format date-time */
  createdDate?: string;
  name?: string | null;
  description?: string | null;
  imageUrl?: string | null;

  /** @format int32 */
  imageId?: number;

  /** @format int32 */
  fileId?: number;
  estimatedTime?: string | null;
}

export interface GuideModelListResponse {
  /** @format int32 */
  total?: number;
  data?: GuideModel[] | null;
}

export interface ChangePasswordRequest {
  currentPassword: string;
  newPassword: string;
  passwordAgain: string;
}

export interface CheckProductModel {
  canRateProduct?: CanRateProductModel;
  nextStudyPathItem?: PlayerCourseOverviewModel;
  previousStudyPathItem?: PlayerCourseOverviewModel;
}

export interface JwtTokenModel {
  access_token?: string | null;
  token_type?: string | null;

  /** @format int32 */
  expires_in?: number;
}

export interface LicenseModel {
  /** @format int32 */
  id?: number;
  customeOrderNumber?: string | null;

  /** @format int32 */
  orderId?: number;
  email?: string | null;
  fullName?: string | null;

  /** @format int32 */
  assignedUserId?: number | null;

  /** @format date-time */
  sendDate?: string | null;

  /** @format date-time */
  activateDate?: string | null;

  /** @format date-time */
  expirationDate?: string | null;
  state?: LicenseState;
}

export interface LicenseModelResult {
  success?: boolean;
  errors?: string[] | null;
}

export enum LicenseState {
  NotAssigned = "NotAssigned",
  Pending = "Pending",
  Active = "Active",
}

export interface LoginRequest {
  email: string;
  password: string;
}

export enum MediaType {
  Unknown = "Unknown",
  Image = "Image",
  Document = "Document",
  Video = "Video",
  Font = "Font",
}

export interface MyEventInstanceModel {
  /** @format int32 */
  id?: number;

  /** @format int32 */
  eventId?: number;
  eventName?: string | null;
  allDayEvent?: boolean;

  /** @format date-time */
  startDate?: string;

  /** @format date-time */
  endDate?: string;
}

export interface MyEventInstanceModelListResponse {
  /** @format int32 */
  total?: number;
  data?: MyEventInstanceModel[] | null;
}

export interface NotificationDetailModel {
  /** @format int32 */
  id?: number;

  /** @format date-time */
  createdDate?: string;
  title?: string | null;
  description?: string | null;
  opened?: boolean;
}

export interface NotificationModel {
  /** @format int32 */
  id?: number;

  /** @format date-time */
  createdDate?: string;
  title?: string | null;
  description?: string | null;

  /** @format int32 */
  productMessageId?: number | null;
  productOwner?: boolean;
  type?: EmailTemplateTypes;
}

export interface NotificationModelListResponse {
  /** @format int32 */
  total?: number;
  data?: NotificationModel[] | null;
}

export interface NotificationPanelModel {
  /** @format int32 */
  newCount?: number;
  notifications?: NotificationModel[] | null;
}

export interface OrderConfirmModel {
  success?: boolean;
  errors?: string[] | null;

  /** @format int32 */
  orderId?: number;
  paymentUrl?: string | null;
}

export interface OrderDetailModel {
  orderItems?: OrderItemModel[] | null;

  /** @format int32 */
  id?: number;
  customOrderNumber?: string | null;

  /** @format int32 */
  userId?: number;

  /** @format int32 */
  portalId?: number;

  /** @format int32 */
  orderStatusId?: number;

  /** @format int32 */
  paymentStatusId?: number;
  paymentMethodSystemName?: string | null;
  paymentMethodName?: string | null;

  /** @format int32 */
  currencyId?: number;

  /** @format double */
  currencyRate?: number;

  /** @format double */
  orderTotalnclTax?: number;

  /** @format double */
  orderTotalExclTax?: number;

  /** @format double */
  orderTax?: number;
  buyMultipleLicences?: boolean;

  /** @format int32 */
  languageId?: number;

  /** @format date-time */
  paidDateUtc?: string | null;

  /** @format date-time */
  createdOnUtc?: string;

  /** @format date-time */
  updatedOnUtc?: string | null;

  /** @format int32 */
  countryId?: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  company?: string | null;
  city?: string | null;
  address?: string | null;
  zipPostalCode?: string | null;
  vatNumber?: string | null;
  companyIdentificationNumber?: string | null;
  formatedOrderTotalInclTax?: string | null;
  formatedOrderTotalExclTax?: string | null;
}

export interface OrderItemModel {
  /** @format int32 */
  orderId?: number;

  /** @format int32 */
  productId?: number;

  /** @format double */
  unitPriceInclTax?: number;

  /** @format double */
  unitPriceExclTax?: number;

  /** @format double */
  priceInclTax?: number;

  /** @format double */
  priceExclTax?: number;
  formatedUnitPriceInclTax?: string | null;
  formatedUnitPriceExclTax?: string | null;
  formatedPriceInclTax?: string | null;
  formatedPriceExclTax?: string | null;

  /** @format int32 */
  qauntity?: number;
  productName?: string | null;
  productCode?: string | null;

  /** @format double */
  taxRate?: number;
  imageurl?: string | null;

  /** @format int32 */
  avaiableLicenses?: number;
}

export enum OrderStatus {
  All = "All",
  Pending = "Pending",
  Processing = "Processing",
  Complete = "Complete",
  Cancelled = "Cancelled",
}

export interface PaymentCallbackResult {
  success?: boolean;
  errors?: string[] | null;
  transactionId?: string | null;

  /** @format int32 */
  orderId?: number;
}

export interface PaymentMethodModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
  description?: string | null;
  logoUrl?: string | null;
}

export interface PaymentRePostModel {
  success?: boolean;
  errors?: string[] | null;

  /** @format int32 */
  orderId?: number;
  paymentUrl?: string | null;
}

export enum PaymentStatus {
  All = "All",
  Pending = "Pending",
  Authorized = "Authorized",
  Paid = "Paid",
  PartiallyRefunded = "PartiallyRefunded",
  Refunded = "Refunded",
  Voided = "Voided",
}

export interface PlayerCourseOverviewModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
  imageUrl?: string | null;
  locked?: boolean;

  /** @format int32 */
  opensIn?: number | null;

  /** @format int32 */
  closesIn?: number | null;
}

export interface PlayerTocItem {
  /** @format int64 */
  activityId?: number;
  hasVisibleChildren?: boolean;
  isTest?: boolean;
  isValidChoice?: boolean;
  isVisible?: boolean;
  items?: PlayerTocItem[] | null;
  status?: string | null;
  title?: string | null;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;

  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
}

export interface ProductLicenseDetailModel {
  /** @format int32 */
  avaiableLicenses?: number;

  /** @format int32 */
  totalLicenses?: number;
  productName?: string | null;

  /** @format int32 */
  productId?: number;
  watingForActivation?: LicenseModel[] | null;
  activeLicenses?: LicenseModel[] | null;
  expiredLicenses?: LicenseModel[] | null;
}

export interface ProductRatingCreateRequest {
  /** @format float */
  score?: number;
  comment?: string | null;
}

export enum ProductState {
  Studying = "Studying",
  New = "New",
  Finished = "Finished",
  Expired = "Expired",
  WaitingForEvaluation = "WaitingForEvaluation",
}

export enum ProductTypes {
  Course = "Course",
  CourseSet = "CourseSet",
  Path = "Path",
}

export interface RatingBaseModel {
  /** @format int32 */
  id?: number;

  /** @format date-time */
  createdDate?: string;
  fullName?: string | null;
  email?: string | null;

  /** @format float */
  score?: number;
  comment?: string | null;
}

export interface RatingBaseModelListResponse {
  /** @format int32 */
  total?: number;
  data?: RatingBaseModel[] | null;
}

export interface RatingSummaryItemModel {
  /** @format float */
  score?: number;

  /** @format int32 */
  count?: number;

  /** @format int32 */
  percents?: number;
}

export interface RatingSummaryModel {
  /** @format int32 */
  percents?: number;

  /** @format float */
  score?: number;

  /** @format int32 */
  total?: number;
  items?: RatingSummaryItemModel[] | null;
}

export interface RegisterModel {
  loginRestriction?: string[] | null;
  groups?: GroupModel[] | null;
  registerRequestEmail?: string | null;
}

export interface RegisterRequest {
  firstName: string;
  lastName: string;

  /** @format email */
  email: string;
  password?: string | null;
  confirmPassword?: string | null;
  groupIds?: number[] | null;
  reCaptchaToken: string;
}

export enum RegisterResult {
  Success = "Success",
  Exists = "Exists",
  DomainNotAllowed = "DomainNotAllowed",
  GroupNotFound = "GroupNotFound",
  RegistrationNowAllowed = "RegistrationNowAllowed",
  ReCaptchaNotValid = "ReCaptchaNotValid",
}

export interface ResetPasswordModel {
  password: string;
  passwordAgain: string;
  secureDataHash: string;
}

export enum Severity {
  Error = "Error",
  Warning = "Warning",
  Info = "Info",
}

export interface ShoppingCartItemModel {
  /** @format int32 */
  id?: number;

  /** @format int32 */
  productId?: number;

  /** @format int32 */
  quantity?: number;
  productName?: string | null;

  /** @format double */
  unitPriceInclTax?: number;

  /** @format double */
  unitPriceExclTax?: number;

  /** @format double */
  priceInclTax?: number;

  /** @format double */
  priceExclTax?: number;
  code?: string | null;
  warnings?: string[] | null;

  /** @format int32 */
  imageId?: number | null;
  imageUrl?: any;
}

export interface ShoppingCartModel {
  items?: ShoppingCartItemModel[] | null;
  warnings?: string[] | null;

  /** @format double */
  totalPriceInclTax?: number;

  /** @format double */
  totalPriceExclTax?: number;
  currencyCode?: string | null;
  message?: string | null;
  messageType?: string | null;
}

export enum SortOrder {
  Asc = "Asc",
  Desc = "Desc",
}

export interface StudyPathItemModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
  description?: string | null;
  required?: boolean;
  locked?: boolean;
  finished?: boolean;

  /** @format date-time */
  dateFrom?: string | null;

  /** @format date-time */
  dateTo?: string | null;
  hideHeader?: boolean;

  /** @format int32 */
  opensIn?: number | null;

  /** @format int32 */
  closesIn?: number | null;

  /** @format int32 */
  displayOrder?: number;

  /** @format int32 */
  portalProductId?: number | null;

  /** @format int32 */
  parentStudyPathItemId?: number | null;
  course?: UserCourseModel;
  items?: StudyPathItemModel[] | null;
  type?: StudyPathItemType;
}

export enum StudyPathItemType {
  Product = "Product",
  Group = "Group",
}

export interface StudyPathModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
  description?: string | null;

  /** @format int32 */
  progress?: number;

  /** @format float */
  rating?: number;
  finished?: boolean;
  items?: StudyPathItemModel[] | null;
}

export interface StudyTimeItem {
  month?: string | null;
  time?: TimeSpan;
}

export interface TimeSpan {
  /** @format int64 */
  ticks?: number;

  /** @format int32 */
  days?: number;

  /** @format int32 */
  hours?: number;

  /** @format int32 */
  milliseconds?: number;

  /** @format int32 */
  minutes?: number;

  /** @format int32 */
  seconds?: number;

  /** @format double */
  totalDays?: number;

  /** @format double */
  totalHours?: number;

  /** @format double */
  totalMilliseconds?: number;

  /** @format double */
  totalMinutes?: number;

  /** @format double */
  totalSeconds?: number;
}

export interface TutorialModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
  description?: string | null;
  longDescription?: string | null;

  /** @format date-time */
  createdDate?: string;
  imageUrl?: string | null;

  /** @format int32 */
  imageId?: number | null;
  videoUrl?: string | null;

  /** @format int32 */
  videoId?: number | null;
  estimatedTime?: string | null;
}

export interface TutorialModelListResponse {
  /** @format int32 */
  total?: number;
  data?: TutorialModel[] | null;
}

export interface UpdateShoppingCartItem {
  /** @format int32 */
  productId?: number;

  /** @format int32 */
  quantity?: number;
  forceDelete?: boolean;
}

export interface UpdateUserProfileRequest {
  firstName: string;
  lastName: string;
  phone?: string | null;
  titleBefore?: string | null;
  titleAfter?: string | null;
}

export interface UserAddressCreateRequest {
  /** @format int32 */
  countryId?: number;

  /** @format int32 */
  userId?: number;

  /** @format int32 */
  portalId?: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  company?: string | null;
  city?: string | null;
  street?: string | null;
  zipPostalCode?: string | null;
  vatNumber?: string | null;
  companyIdentificationNumber?: string | null;
}

export interface UserAddressModel {
  /** @format int32 */
  countryId?: number;

  /** @format int32 */
  userId?: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  company?: string | null;
  city?: string | null;
  street?: string | null;
  zipPostalCode?: string | null;
  vatNumber?: string | null;
  companyIdentificationNumber?: string | null;

  /** @format date-time */
  createDate?: string;
}

export interface UserAddressUpdateRequest {
  /** @format int32 */
  countryId?: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  company?: string | null;
  city?: string | null;
  street?: string | null;
  zipPostalCode?: string | null;
  vatNumber?: string | null;
  companyIdentificationNumber?: string | null;
}

export interface UserCourseModel {
  /** @format int32 */
  id?: number;

  /** @format int32 */
  userProductId?: number | null;
  name?: string | null;
  description?: string | null;
  imageUrl?: string | null;

  /** @format float */
  rating?: number;

  /** @format date-time */
  createDate?: string;

  /** @format date-time */
  activationDate?: string | null;

  /** @format date-time */
  expirationDate?: string | null;

  /** @format date-time */
  finishDate?: string | null;
  waitingForEvaluation?: boolean;

  /** @format int32 */
  daysLeft?: number;
  state?: ProductState;

  /** @format int32 */
  progress?: number;
  estimatedTime?: string | null;

  /** @format int32 */
  studyPathItemId?: number | null;
  type?: ProductTypes;
}

export interface UserCourseModelListResponse {
  /** @format int32 */
  total?: number;
  data?: UserCourseModel[] | null;
}

export interface UserGamificationObjectiveItemModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
  description?: string | null;

  /** @format int32 */
  pointsToEarn?: number;

  /** @format date-time */
  completedDate?: string | null;
  type?: GamificationObjectiveType;
  badgeUrl?: string | null;
  hasBadge?: boolean;

  /** @format int32 */
  count?: number;

  /** @format int32 */
  countCompleted?: number;
  completed?: boolean;
  expired?: boolean;
}

export interface UserGamificationObjectiveItemModelListResponse {
  /** @format int32 */
  total?: number;
  data?: UserGamificationObjectiveItemModel[] | null;
}

export interface UserGamificationObjectiveModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
  description?: string | null;

  /** @format int32 */
  pointsToEarn?: number;

  /** @format date-time */
  completedDate?: string | null;
  type?: GamificationObjectiveType;
  badgeUrl?: string | null;
  hasBadge?: boolean;
  userGamificationTriggers?: UserGamificationTriggerModel[] | null;

  /** @format int32 */
  count?: number;

  /** @format int32 */
  countCompleted?: number;
  expired?: boolean;
}

export interface UserGamificationTriggerModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
  description?: string | null;

  /** @format int32 */
  pointsToEarn?: number;

  /** @format date-time */
  completedDate?: string | null;
  type?: GamificationTriggerType;
  badgeUrl?: string | null;
  hasBadge?: boolean;

  /** @format int32 */
  count?: number;

  /** @format int32 */
  countCompleted?: number;

  /** @format int32 */
  portalProductId?: number | null;
  portalProductType?: ProductTypes;
  portalProductName?: string | null;
}

export interface UserLicenseModel {
  /** @format int32 */
  productId?: number;
  productName?: string | null;

  /** @format int32 */
  licenses?: number;

  /** @format int32 */
  avaiableLicenses?: number;
}

export interface UserLicenseModelListResponse {
  /** @format int32 */
  total?: number;
  data?: UserLicenseModel[] | null;
}

export interface UserOrderModel {
  /** @format int32 */
  id?: number;
  customerOrderNumber?: string | null;
  paymentStatus?: PaymentStatus;
  orderStatus?: OrderStatus;

  /** @format double */
  orderTotal?: number;

  /** @format date-time */
  createdOnUtc?: string;

  /** @format int32 */
  orderedItemCount?: number;
  formatedPrice?: any;
}

export interface UserOrderModelListResponse {
  /** @format int32 */
  total?: number;
  data?: UserOrderModel[] | null;
}

export interface UserProfileModel {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  titleBefore?: string | null;
  titleAfter?: string | null;
}

export interface UserProgressModel {
  /** @format int32 */
  bestScore?: number;

  /** @format int32 */
  courses?: number;

  /** @format int32 */
  tutorials?: number;

  /** @format int32 */
  guides?: number;

  /** @format int32 */
  certificates?: number;
  totalTime?: TimeSpan;

  /** @format int32 */
  finished?: number;

  /** @format int32 */
  averageScore?: number;

  /** @format int32 */
  finishedPercents?: number;

  /** @format int32 */
  tests?: number;
  chart?: StudyTimeItem[] | null;
}

export interface UserSecureUrlData {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;

  /** @format date-time */
  expirationDate?: string;

  /** @format int32 */
  portalId?: number;
}

export interface UserStudyModel {
  /** @format int32 */
  userProductId?: number;

  /** @format int32 */
  portalProductId?: number;
  url?: string | null;
  name?: string | null;
  description?: string | null;
  totalTime?: TimeSpan;
  tagNames?: string[] | null;

  /** @format int32 */
  categoryId?: number;
  categoryName?: string | null;

  /** @format int32 */
  studyPathId?: number | null;
  nextStudyPathItem?: PlayerCourseOverviewModel;
  previousStudyPathItem?: PlayerCourseOverviewModel;
  toc?: PlayerTocItem[] | null;
  locked?: boolean;
}

export interface ValidationFailure {
  propertyName?: string | null;
  errorMessage?: string | null;
  attemptedValue?: any;
  customState?: any;
  severity?: Severity;
  errorCode?: string | null;
  formattedMessageArguments?: any[] | null;
  formattedMessagePlaceholderValues?: Record<string, any>;
}

export type V1LicenseAssignCreatePayload = AssigneeModel[];

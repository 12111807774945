
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import type { EventModel } from "@/services/models";
import EventCard from "@/components/events/EventCard.vue";
import Pagination, { PaginationComponent } from "@/components/shared/Pagination.vue";
import SearchList from "@/components/shared/SearchList.vue";
import EventCategorySelect from "@/components/shared/EventCategorySelect.vue";
import { saveBackRoute } from "@/utils/common";
import EventModal, { EventModalComponent } from "@/components/events/EventModal.vue";
import { ApiClient } from "@/services/apiClient";
import { useRoute } from "vue-router";
import { useAcademySettings } from "@/init/store";

export default defineComponent({
  components: {
    EventCard,
    Pagination,
    SearchList,
    EventCategorySelect,
    EventModal,
  },
  setup() {
    const { settings } = useAcademySettings();
    const route = useRoute();

    const eventModal = ref(null as null | EventModalComponent);
    const pagination = ref(null as null | PaginationComponent);
    const events = ref([] as EventModel[]);
    const loaded = ref(false);
    const search = ref("");
    const filterCategoryId = ref(null as null | number);

    const load = async () => {
      if (!pagination.value) return;

      const response = await ApiClient.eventService.events({
        page: pagination.value.getPage(),
        pageSize: pagination.value.getPageSize(),
        search: search.value,
        categoryId: filterCategoryId.value,
      });

      pagination.value.setTotal(response.total ?? 0);
      events.value = response.data ?? [];
      loaded.value = true;
    };

    const reload = () => {
      if (!pagination.value) return;
      pagination.value.setPage(1);
    };

    const paginationLoad = async () => {
      window.scrollTo(0, 0);
      await load();
    };

    onMounted(async () => {
      if (route.query.search) search.value = route.query.search.toString();

      saveBackRoute();

      await load();

      watch(filterCategoryId, reload);
    });

    const noResultsText = computed(() => settings.textSearchBoxNoResults ?? "No events found.");
    const showNoResults = computed(() => events.value.length === 0 && loaded.value);

    const register = (params: { id: number; instanceId: number }) => {
      if (!eventModal.value) return;

      eventModal.value.init(params);
      eventModal.value.show();
    };

    return {
      eventModal,
      settings,
      search,
      showNoResults,
      noResultsText,
      paginationLoad,
      reload,
      pagination,
      filterCategoryId,
      events,
      register,
    };
  },
});

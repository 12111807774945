
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return { page: 1, total: 0 };
  },
  props: {
    pageSize: {
      type: Number,
      required: false,
      default: 10,
    },
  },
  methods: {
    next() {
      if (this.page == this.totalPages) return;
      this.page++;
      this.setPage(this.page);
    },
    previous() {
      if (this.page == 1) return;
      this.page--;
      this.setPage(this.page);
    },
    setPage(index: number) {
      this.page = index;
      this.$emit("reload");
    },
    getPage() {
      return this.page;
    },
    getPageSize() {
      return this.pageSize;
    },
    setTotal(total: number) {
      this.total = total;
    },
    getTotal() {
      return this.total;
    },
  },
  computed: {
    totalPages(): number {
      return Math.ceil(this.total / this.pageSize);
    },
    nextDisabled(): boolean {
      return this.page == this.totalPages;
    },
    previousDisabled(): boolean {
      return this.page == 1;
    },
    show(): boolean {
      return this.totalPages > 1;
    },
  },
});

export declare class PaginationComponent {
  getPage: () => number;
  getPageSize: () => number;
  setPage: (index: number) => number;
  setTotal: (total: number) => number;
  getTotal: () => number;
}

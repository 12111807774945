import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex payment-method align-items-center" }
const _hoisted_2 = { class: "ms-2" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "ms-2" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "ms-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.imageOrDefaultUrl,
        class: "logo-img"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("input", {
        type: "radio",
        value: _ctx.item.id,
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('methodSelected', _ctx.item.id))),
        name: "paymentMethod"
      }, null, 40, _hoisted_5)
    ]),
    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.item.name), 1)
  ]))
}

import { TutorialModel } from "@/services/models";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    tutorial: Object as () => TutorialModel,
  },
  mounted() {
    if (!this.$store.getters.settings.tutorialsEnabled) this.$router.push({ name: "Home" });
  },
  computed: {
    imageOrDefaultUrl(): string {
      return this.tutorial?.imageUrl ? this.tutorial.imageUrl : "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs=";
    },
  },
});

import { AxiosInstance, CancelToken } from "axios";
import { TutorialModel, TutorialModelListResponse } from "@/services/models";
import { createQueryString, ListRequest } from "@/utils/common";

export default class TutorialService {
  private api: AxiosInstance;
  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async getList(request: TutorialListRequest, cancelToken?: CancelToken): Promise<TutorialModelListResponse> {
    const response = await this.api.get("/api/v1/tutorials?" + createQueryString(request), {
      cancelToken,
    });
    return response.data;
  }

  async getById(id: number, cancelToken?: CancelToken): Promise<TutorialModel> {
    const response = await this.api.get("/api/v1/tutorials/" + id, {
      cancelToken,
    });
    return response.data;
  }

  async viewed(id: number, cancelToken?: CancelToken): Promise<void> {
    await this.api.post(`/api/v1/tutorials/${id}/viewed`, {
      cancelToken,
    });
  }
}

interface TutorialListRequest extends ListRequest {
  search: string;
  categoryId?: number | null;
}

import { AxiosInstance, CancelToken } from "axios";
import { ShoppingCartModel, CreateShoppingCartItem, UpdateShoppingCartItem } from "@/services/models";
import { createQueryString, ListRequest } from "@/utils/common";

export default class ShoppingCartService {
  private api: AxiosInstance;
  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async addToCart(request: CreateShoppingCartItem, cancelToken?: CancelToken): Promise<ShoppingCartModel> {
    const response = await this.api.post("/api/v1/shoppingCart/addToCart", request, {
      cancelToken,
    });
    return response.data;
  }

  async cart(cancelToken?: CancelToken): Promise<ShoppingCartModel> {
    const response = await this.api.get("/api/v1/shoppingCart/cart", {
      cancelToken,
    });
    return response.data;
  }

  async removeAllCart(cancelToken?: CancelToken): Promise<void> {
    const response = await this.api.delete("/api/v1/shoppingCart/removeCart", {
      cancelToken,
    });
    return response.data;
  }

  async updateCart(request: UpdateShoppingCartItem, cancelToken?: CancelToken): Promise<void> {
    const response = await this.api.put("/api/v1/shoppingCart/cart", request, {
      cancelToken,
    });
    return response.data;
  }
}

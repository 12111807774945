
import { CommentModel } from "@/services/models";
import { defineAsyncComponent, defineComponent } from "vue";
import CreateComment from "./CreateComment.vue";
import { dateTimeUtc } from "@/utils/filters";
import { CourseCommentsComponent, CourseCommentsComponentType } from "./CourseComments.vue";

export default defineComponent({
  components: {
    CreateComment,
    CourseComments: defineAsyncComponent<CourseCommentsComponentType>(() => import("./CourseComments.vue")),
  },
  props: {
    comment: {
      type: Object as () => CommentModel,
      required: true,
    },
    portalProductId: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.answersCount = this.comment.answersCount ?? 0;
  },
  data() {
    return {
      showAnswerForm: false,
      showAnswerList: false,
      answersCount: 0,
    };
  },
  methods: {
    dateTimeUtc,
    commentCreated(comment: CommentModel) {
      if (this.showAnswerList) {
        this.courseComments.appendComment(comment);
      }
      this.answersCount++;
    },
  },
  computed: {
    courseComments() {
      return this.$refs.courseComments as CourseCommentsComponent;
    },
  },
  watch: {
    "comment.answersCount"() {
      this.answersCount = this.comment.answersCount ?? 0;
    },
  },
});


import { defineComponent } from "vue";
import Spinner from "@/components/shared/Spinner.vue";
export default defineComponent({
  data() {
    return {
      invalidLink: false,
    };
  },
  components: {
    Spinner,
  },
  async mounted() {
    const tokenHash = this.$route.params.tokenHash;
    if (!tokenHash) {
      this.invalidLink = true;
      return;
    }
    try {
      const result = await await this.$api.accountService.confirmRegistration(tokenHash.toString());
      localStorage.setItem("authUser", JSON.stringify(result));
      this.$store.commit("loginSuccess", result);
      this.$store.dispatch("loadUserProgress");
      this.$router.push({ name: "Courses" });
    } catch {
      this.invalidLink = true;
    }
  },
});

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "objectives-list-row row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  class: "card-body d-flex flex-column align-items-center",
  style: {"position":"relative"}
}
const _hoisted_4 = {
  class: "position-absolute translate-middle badge rounded-pill bg-danger",
  style: {"top":"25px","right":"-40px"}
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "name" }
const _hoisted_7 = { class: "desc" }
const _hoisted_8 = { class: "completed pt-1 mt-auto" }
const _hoisted_9 = {
  key: 0,
  class: "text-center w-100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pagination = _resolveComponent("pagination")!
  const _component_objective_modal = _resolveComponent("objective-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col d-flex align-items-stretch",
          key: item.id
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["card objective flex-fill mb-4", { completed: item.completedDate }]),
            onClick: ($event: any) => (_ctx.openObjective(item))
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, " +" + _toDisplayString(item.pointsToEarn) + " " + _toDisplayString(_ctx.$getSettings("textGamificationPoints")), 1),
              _createElementVNode("img", {
                src: item.badgeUrl
              }, null, 8, _hoisted_5),
              _createElementVNode("div", _hoisted_6, _toDisplayString(item.name), 1),
              _createElementVNode("div", _hoisted_7, _toDisplayString(item.description), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(item.countCompleted) + "/" + _toDisplayString(item.count), 1)
            ])
          ], 10, _hoisted_2)
        ]))
      }), 128)),
      (_ctx.data.length == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.noDataText), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_pagination, {
      ref: "pagination",
      pageSize: 9,
      onReload: _ctx.load
    }, null, 8, ["onReload"]),
    _createVNode(_component_objective_modal, { ref: "objectiveModal" }, null, 512)
  ], 64))
}
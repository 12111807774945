import { AxiosInstance, CancelToken } from "axios";
import {
  ActivateAccountModel,
  ActivateAccountRequest,
  AuthUserModel,
  ChangePasswordRequest,
  ForgottenPasswordModel,
  LoginRequest,
  RegisterModel,
  RegisterRequest,
  RegisterResult,
  ResetPasswordModel,
  UpdateUserProfileRequest,
  UserProfileModel,
  UserSecureUrlData,
} from "@/services/models";

export default class AccountService {
  private api: AxiosInstance;
  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async login(request: LoginRequest, cancelToken?: CancelToken): Promise<AuthUserModel> {
    const response = await this.api.post("/api/v1/account/login", request, {
      cancelToken,
    });
    return response.data;
  }

  async register(request: RegisterRequest, cancelToken?: CancelToken): Promise<RegisterResult> {
    const response = await this.api.post("/api/v1/account/register", request, {
      cancelToken,
    });
    return response.data;
  }

  async registerInit(cancelToken?: CancelToken): Promise<RegisterModel> {
    const response = await this.api.get("/api/v1/account/register", {
      cancelToken,
    });
    return response.data;
  }

  async confirmRegistration(hash: string, cancelToken?: CancelToken): Promise<RegisterModel> {
    const response = await this.api.post("/api/v1/account/confirmRegistration/" + hash, {
      cancelToken,
    });
    return response.data;
  }

  async activate(request: ActivateAccountRequest, cancelToken?: CancelToken): Promise<AuthUserModel> {
    const response = await this.api.post("api/v1/account/activate", request, {
      cancelToken: cancelToken,
    });
    return response.data;
  }
  async verifyActivateLink(token: string, cancelToken?: CancelToken): Promise<ActivateAccountModel> {
    const response = await this.api.get(`api/v1/account/activate/${token}`, {
      cancelToken: cancelToken,
    });
    return response.data;
  }

  async forgottenPassword(request: ForgottenPasswordModel, cancelToken?: CancelToken): Promise<boolean> {
    const response = await this.api.post("api/v1/account/forgottenPassword", request, { cancelToken: cancelToken });
    return response.status === 200;
  }
  async verifyResetPasswordLink(token: string, cancelToken?: CancelToken): Promise<UserSecureUrlData> {
    const response = await this.api.get(`api/v1/account/resetPassword/${token}`, {
      cancelToken: cancelToken,
    });
    return response.data;
  }
  async resetPassword(request: ResetPasswordModel, cancelToken?: CancelToken): Promise<boolean> {
    const response = await this.api.post("api/v1/account/resetPassword", request, {
      cancelToken: cancelToken,
    });
    return response.status === 200;
  }

  async changePassword(request: ChangePasswordRequest, cancelToken?: CancelToken): Promise<void> {
    await this.api.post("api/v1/account/changePassword", request, {
      cancelToken: cancelToken,
    });
  }

  async getUserProfile(cancelToken?: CancelToken): Promise<UserProfileModel> {
    const response = await this.api.get("/api/v1/account/profile", {
      cancelToken,
    });
    return response.data;
  }

  async updateUserProfile(request: UpdateUserProfileRequest, cancelToken?: CancelToken): Promise<void> {
    await this.api.post("api/v1/account/profile", request, {
      cancelToken: cancelToken,
    });
  }
}

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="110" height="59" viewBox="0 0 110 59">
    <g id="CS" transform="translate(-1521 -1355)">
      <path
        id="Rectangle_153"
        data-name="Rectangle 153"
        d="M3.2,0H106.8A3.2,3.2,0,0,1,110,3.2v0A28.8,28.8,0,0,1,81.2,32H3.2A3.2,3.2,0,0,1,0,28.8V3.2A3.2,3.2,0,0,1,3.2,0Z"
        transform="translate(1521 1355)"
        fill="#454647" />
      <g id="Rectangle_154" data-name="Rectangle 154" transform="translate(1531 1382)" fill="#fff" stroke="#454647" stroke-width="1">
        <path
          d="M3.2,0H66.8A3.2,3.2,0,0,1,70,3.2V28.8A3.2,3.2,0,0,1,66.8,32h-38A28.8,28.8,0,0,1,0,3.2v0A3.2,3.2,0,0,1,3.2,0Z"
          stroke="none" />
        <path
          d="M3.347.5H66.653A2.847,2.847,0,0,1,69.5,3.347V28.653A2.847,2.847,0,0,1,66.653,31.5h-38A28.153,28.153,0,0,1,.5,3.347v0A2.847,2.847,0,0,1,3.347.5Z"
          fill="none" />
      </g>
      <text id="COMING" transform="translate(1531 1377)" fill="#fff" font-size="20" font-family="Gibson-Medium, Gibson" font-weight="500">
        <tspan x="0" y="0">COMING</tspan>
      </text>
      <text id="SOON" transform="translate(1547 1403)" fill="#454647" font-size="16" font-family="Gibson-Medium, Gibson" font-weight="500">
        <tspan x="0" y="0">SOON</tspan>
      </text>
    </g>
  </svg>
</template>

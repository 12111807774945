
import { NotificationPanelModel } from "@/services/models";
import { defineComponent } from "vue";
import { truncate } from "@/utils/filters";
import * as signalR from "@microsoft/signalr";
import NotificationIcon from "../shared/NotificationIcon.vue";

interface ShowGamificationBadgeHubRequest {
  badgeUrl: string;
  name: string;
  description: string;
  pointsEarned: number;
}

export default defineComponent({
  components: { NotificationIcon },
  data() {
    return {
      panel: {} as NotificationPanelModel,
      showModal: false,
      hubConnection: null as signalR.HubConnection | null,
    };
  },
  async mounted() {
    await this.load();
    await this.initHub();
  },
  unmounted() {
    this.hubConnection?.stop();
  },
  methods: {
    truncate,
    async load() {
      this.panel = await this.$api.notificationService.getPanel();
    },
    showBadge(request: ShowGamificationBadgeHubRequest) {
      const body = `<div class='d-flex align-items-center position-relative'>
        <img style='width: 60px; height: auto;' class='ms-2' src='${request.badgeUrl}' />
        <div class='px-2 flex-fill text-center'>${request.description}</div>
        <span class="position-absolute translate-middle badge rounded-pill bg-danger top-0 start-100"> +${request.pointsEarned}</span>
      </div>`;

      this.$toast.default(request.name, body);
    },
    initHub() {
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(process.env.VUE_APP_LMS_API_ENDPOINT + "/lmsHub", {
          accessTokenFactory: () => this.$store.getters.lmsHubToken,
          withCredentials: false,
        })
        .build();

      this.hubConnection.on("updateNotificationPanel", (panel: NotificationPanelModel) => {
        this.panel = panel;
      });

      this.hubConnection.on("showGamificationBadge", (request: ShowGamificationBadgeHubRequest) => {
        this.showBadge(request);
      });

      this.hubConnection.start();
    },
  },
});


import { delayCallback, delay } from "@/utils/common";
import { defineComponent } from "vue";
import SearchList, { SearchListComponent } from "@/components/shared/SearchList.vue";
import CourseList, { CourseListComponent } from "@/components/courses/CourseList.vue";
import CategoryList, { CategoryListComponent } from "@/components/courses/CategoryList.vue";
import TutorialList, { TutorialListComponent } from "@/components/tutorials/TutorialList.vue";
import GuideList, { GuideListComponent } from "@/components/guides/GuideList.vue";
import Spinner from "@/components/shared/Spinner.vue";

interface SearchViewModel {
  search: string;
  searchDelay: delayCallback;
  loaded: boolean;
  guideOption: SearchOption;
  tutorialOption: SearchOption;
  categoryOption: SearchOption;
  courseOption: SearchOption;
}

interface SearchOption {
  show: boolean;
  loading: boolean;
  results: number;
}

export default defineComponent({
  components: {
    SearchList,
    CourseList,
    CategoryList,
    TutorialList,
    GuideList,
    Spinner,
  },
  data() {
    return {
      search: "",
      loaded: false,
      searchDelay: delay(700),
      guideOption: { show: true, loading: true },
      tutorialOption: { show: true, loading: true },
      categoryOption: { show: true, loading: true },
      courseOption: { show: true, loading: true },
    } as SearchViewModel;
  },
  mounted() {
    this.updateSearch();
  },
  methods: {
    reload() {
      (this.$refs.courseList as CourseListComponent).load({
        search: this.search,
      });
      (this.$refs.categoryList as CategoryListComponent).load(
        {
          search: this.search,
        },
        null,
      );
      (this.$refs.tutorialList as TutorialListComponent).load({
        search: this.search,
      });
      (this.$refs.guideList as GuideListComponent).load({
        search: this.search,
      });
    },
    updateSearch() {
      this.search = this.$route.query.q?.toString() ?? "";
      (this.$refs.searchList as SearchListComponent).focus();
    },
    searchLoad() {
      this.searchDelay(() => {
        this.allOptions.forEach((option) => {
          option.show = true;
          option.results = 0;
          option.loading = true;
        });
        this.reload();
      });
    },
    guideListLoaded(total: number) {
      this.guideOption.results = total;
      this.guideOption.show = total > 0;
      this.guideOption.loading = false;
    },
    tutorialListLoaded(total: number) {
      this.tutorialOption.results = total;
      this.tutorialOption.show = total > 0;
      this.tutorialOption.loading = false;
    },
    categoryListLoaded(total: number) {
      this.categoryOption.results = total;
      this.categoryOption.show = total > 0;
      this.categoryOption.loading = false;
    },
    courseListLoaded(total: number) {
      this.courseOption.results = total;
      this.courseOption.show = total > 0;
      this.courseOption.loading = false;
    },
  },
  computed: {
    allOptions(): Array<SearchOption> {
      return [this.guideOption, this.tutorialOption, this.categoryOption, this.courseOption];
    },
    showNoResults(): boolean {
      return this.allOptions.every((item) => !item.show);
    },
    noResultsText(): string {
      return this.$store.state.academy.settings?.textSearchBoxNoResults ?? "Nothing found.";
    },
  },
  watch: {
    "$route.query.q"() {
      this.updateSearch();
    },
  },
});

import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5bbedc67"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col d-flex align-items-stretch" }
const _hoisted_2 = { class: "card event-item flex-fill mx-3 mb-4" }
const _hoisted_3 = { class: "card-img" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  src: "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs=",
  class: "card-img-top"
}
const _hoisted_6 = { class: "card-body d-flex" }
const _hoisted_7 = { class: "card-content d-flex flex-fill flex-column" }
const _hoisted_8 = { class: "card-title" }
const _hoisted_9 = { class: "card-rating" }
const _hoisted_10 = { class: "card-text" }
const _hoisted_11 = { class: "date" }
const _hoisted_12 = {
  key: 0,
  class: "lector"
}
const _hoisted_13 = {
  key: 1,
  class: "mt-2"
}
const _hoisted_14 = { class: "price mt-3 mb-3 d-flex flex-column flex-fill justify-content-end align-items-end" }
const _hoisted_15 = {
  key: 0,
  class: "uppercase"
}
const _hoisted_16 = { class: "buttons" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RatingInfo = _resolveComponent("RatingInfo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.event.imageUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.event.imageUrl,
              class: "card-img-top"
            }, null, 8, _hoisted_4))
          : (_openBlock(), _createElementBlock("img", _hoisted_5))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("h5", _hoisted_8, _toDisplayString(_ctx.event.name), 1),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_RatingInfo, {
              rating: _ctx.event.rating
            }, null, 8, ["rating"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            (_ctx.event.nextInstance)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("b", null, _toDisplayString(_ctx.formatDate(_ctx.event.nextInstance.startDate)), 1),
                    _createTextVNode(" " + _toDisplayString(_ctx.formatInstancesCount(_ctx.event.upcomingInstancesCount)), 1)
                  ]),
                  (_ctx.event.nextInstance.lectors && _ctx.event.nextInstance.lectors.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.event.nextInstance.lectors, (lector) => {
                          return (_openBlock(), _createElementBlock("span", {
                            key: lector.id
                          }, _toDisplayString(lector.firstName) + " " + _toDisplayString(lector.lastName), 1))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.event.description)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.formatDescription(_ctx.event.description)), 1))
              : _createCommentVNode("", true)
          ]),
          (_ctx.event.nextInstance)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", _hoisted_14, [
                  (!_ctx.event.nextInstance.isPaid)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.settings.textEventsFree), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("button", {
                    class: "btn btn-primary uppercase",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.goToEventInstance && _ctx.goToEventInstance(...args)), ["stop"]))
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.settings.textEventsDetail), 1)
                  ]),
                  _createElementVNode("button", {
                    class: "btn btn-secondary uppercase",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.register && _ctx.register(...args)), ["stop"]))
                  }, [
                    (_ctx.event.nextInstance.isPaid)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.settings.textEventsBuy), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.settings.textEventsRegister), 1))
                  ])
                ])
              ], 64))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}
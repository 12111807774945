
import type { MyEventInstanceModel } from "@/services/models";
import { EventInstanceShow } from "@/services/models";
import { defineComponent } from "vue";
import { formatDateTimeRange } from "@/components/events/common";

type MyEventInstance = Defined<MyEventInstanceModel> & { formattedDate: string };

export default defineComponent({
  data: () => ({
    eventInstances: [] as MyEventInstance[],
  }),
  async mounted() {
    await this.loadEventInstances();
  },
  methods: {
    async loadEventInstances() {
      const { data } = await this.$api.eventService.myInstanceList({
        show: EventInstanceShow.Upcoming,
        page: 1,
        pageSize: 3,
      });

      this.eventInstances = data.map((x) => ({ ...x, formattedDate: formatDateTimeRange(x) }));
    },
  },
});

import { AxiosInstance, CancelToken } from "axios";
import { GuideModel, GuideModelListResponse } from "@/services/models";
import { createQueryString, ListRequest, saveAs, showFile } from "@/utils/common";

export default class GuideService {
  private api: AxiosInstance;
  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async getList(request: GuideListRequest, cancelToken?: CancelToken): Promise<GuideModelListResponse> {
    const response = await this.api.get("/api/v1/guides?" + createQueryString(request), {
      cancelToken,
    });
    return response.data;
  }

  async getById(id: number, cancelToken?: CancelToken): Promise<GuideModel> {
    const response = await this.api.get("/api/v1/guides/" + id, {
      cancelToken,
    });
    return response.data;
  }

  async download(id: number, openNewTab?: boolean, cancelToken?: CancelToken): Promise<void> {
    const response = await this.api.get(`/api/v1/guides/${id}/download`, {
      responseType: "blob",
      cancelToken,
    });
    if (openNewTab) {
      showFile(response);
      return;
    }
    saveAs(response);
  }

  async downloaded(id: number, cancelToken?: CancelToken): Promise<void> {
    await this.api.post(`/api/v1/guides/${id}/downloaded`, {
      cancelToken,
    });
  }
}

interface GuideListRequest extends ListRequest {
  search: string;
  categoryId?: number | null;
}

import store from "@/init/store";
import Axios, { AxiosInstance } from "axios";
import qs from "qs";
import AcademyService from "./academyService";
import AccountService from "./accountService";
import UserService from "./userService";
import ShoppingCartService from "./shoppingCartService";
import OrderService from "./orderService";
import appBus from "@/appBus";
import router from "@/init/router";
import TutorialService from "./tutorialService";
import GuideService from "./guideService";
import LicenseService from "./licenseService";
import StudyPathService from "./studyPathService";
import NotificationService from "./notificationService";
import GamificationService from "./gamificationService";
import EventService from "./eventService";
import AcademyCourseService from "./academyCourseService";
import RatingService from "./ratingService";
import CommentService from "./commentService";

class ApiClientInstance {
  private api!: AxiosInstance;
  academyService!: AcademyService;
  accountService!: AccountService;
  userService!: UserService;
  shoppingCartService!: ShoppingCartService;
  tutorialService!: TutorialService;
  guideService!: GuideService;
  studyPathService!: StudyPathService;
  notificationService!: NotificationService;
  gamificationService!: GamificationService;
  orderService!: OrderService;
  licenseService!: LicenseService;
  eventService!: EventService;
  academyCourseService!: AcademyCourseService;
  ratingService!: RatingService;
  commentService!: CommentService;
  constructor() {
    this.refreshClient();
  }
  refreshClient() {
    const headers: Record<string, string> = {};
    if (store.state.user) {
      headers["Authorization"] = "Bearer " + store.state.user?.token?.access_token;
    }

    if (process.env.VUE_APP_PORTAL_ID) {
      headers["X-PortalId"] = process.env.VUE_APP_PORTAL_ID;
    }

    this.api = Axios.create({
      baseURL: process.env.VUE_APP_API_ENDPOINT,
      paramsSerializer: (params) => qs.stringify(params),
      headers,
    });

    this.academyService = new AcademyService(this.api);
    this.accountService = new AccountService(this.api);
    this.userService = new UserService(this.api);
    this.shoppingCartService = new ShoppingCartService(this.api);
    this.guideService = new GuideService(this.api);
    this.tutorialService = new TutorialService(this.api);
    this.orderService = new OrderService(this.api);
    this.licenseService = new LicenseService(this.api);
    this.studyPathService = new StudyPathService(this.api);
    this.notificationService = new NotificationService(this.api);
    this.gamificationService = new GamificationService(this.api);
    this.eventService = new EventService(this.api);
    this.academyCourseService = new AcademyCourseService(this.api);
    this.commentService = new CommentService(this.api);
    this.ratingService = new RatingService(this.api);

    this.api.interceptors.request.use((config) => {
      appBus.emit("showOverlay", true);
      return config;
    });

    this.api.interceptors.response.use(
      (response) => {
        appBus.emit("showOverlay", false);
        return response;
      },
      async (err) => {
        appBus.emit("showOverlay", false);

        if (err.response.status === 401) {
          await store.dispatch("logout");
          router.push({ name: "Login" });
          // , () => {
          //   NotifyService.error("Access denied.");
          // }
        }

        // if (err.response.status === 404) {
        //   NotifyService.error("Not found.");
        //   router.push({ name: "NotFound" });
        // }

        // if (err.response.status === 500) {
        //   NotifyService.error(err.response.data.title);
        // }

        return Promise.reject(err);
      },
    );
  }
}

export const ApiClient = new ApiClientInstance();
export type ApiClient = typeof ApiClient;


import { defineComponent } from "vue";
import { AcademySettingsModel, CourseCategoryModel } from "@/services/models";
// import SearchList from "@/components/shared/SearchList.vue";
// import CategorySelect from "@/components/shared/CategorySelect.vue";
import CategoryList, { CategoryListComponent } from "@/components/courses/CategoryList.vue";
import { delay, delayCallback, ListOptions } from "@/utils/common";

interface CategoriesViewModel {
  listOptions: ListOptions;
  searchDelay: delayCallback;
  categoryId: number | null;
  category: CourseCategoryModel | null;
}

export default defineComponent({
  components: {
    CategoryList,
  },
  data() {
    return {
      listOptions: { search: "" },
      searchDelay: delay(450),
      categoryId: null,
      category: null,
    } as CategoriesViewModel;
  },
  async mounted() {
    if (!this.$store.getters.settings.coursesEnabled) {
      this.$router.push({ name: "Home" });
      return;
    }

    if (this.$route.params.id) {
      this.categoryId = parseInt(this.$route.params.id.toString());
      this.category = await this.$api.academyService.getCourseCategory(this.categoryId);
    }

    if (this.$route.query.search) this.listOptions.search = this.$route.query.search.toString();

    await this.load();
  },
  methods: {
    async load() {
      await this.categoryList.load(this.listOptions, this.categoryId);
    },
    searchCategory() {
      this.searchDelay(() => {
        this.categoryList.setOptions(this.listOptions);
        this.categoryList.reload(this.categoryId);
      });
    },
    back() {
      if (this.category) {
        if (this.category.parentCategoryId) {
          this.$router.push("/category/" + this.category.parentCategoryId);
          return;
        }

        this.$router.push("/courses");
      }
    },
  },
  computed: {
    categoryList(): CategoryListComponent {
      return this.$refs.categoryList as CategoryListComponent;
    },
    settings() {
      return this.$store.state.academy.settings as AcademySettingsModel;
    },
  },
});


import { CourseCategoryModel } from "@/services/models";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    category: Object as () => CourseCategoryModel,
  },
  computed: {
    imageOrDefaultUrl(): string {
      return this.category?.imageUrl ? this.category.imageUrl : "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs=";
    },
  },
});

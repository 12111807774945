import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col d-flex align-items-stretch" }
const _hoisted_2 = { class: "card category-item flex-fill mx-3 mb-4 link" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = { class: "card-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.imageOrDefaultUrl,
        class: "card-img-top"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.category.name), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.category.description), 1)
      ])
    ])
  ]))
}
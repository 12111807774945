
import { TutorialModel } from "@/services/models";
import { defineComponent } from "vue";

interface TutorialDetailViewModel {
  model: TutorialModel;
}

export default defineComponent({
  data() {
    return {
      model: {},
    } as TutorialDetailViewModel;
  },
  async mounted() {
    if (!this.$store.getters.settings.tutorialsEnabled) this.$router.push({ name: "Home" });

    if (!this.$route.params.id) this.$router.push(`/tutorials`);
    const id = parseInt(this.$route.params.id.toString());

    this.model = await this.$api.tutorialService.getById(id);

    this.$api.tutorialService.viewed(id);
  },
  methods: {
    back() {
      this.$router.push(`/tutorials`);
    },
  },
});


import { defineComponent } from "vue";
import Modal from "@/components/shared/Modal.vue";
import { GamificationObjectiveType, ProductTypes, UserGamificationObjectiveModel, UserGamificationTriggerModel } from "@/services/models";
import { dateTimeUtc } from "@/utils/filters";

export default defineComponent({
  components: { Modal },
  data() {
    return {
      showModal: false,
      loading: false,
      objective: {} as UserGamificationObjectiveModel,
    };
  },
  methods: {
    dateTimeUtc,
    async show(id: number) {
      this.objective = await this.$api.gamificationService.objective(id);
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    async openProduct(trigger: UserGamificationTriggerModel) {
      if (trigger.portalProductType == ProductTypes.Path) {
        await this.$router.push("/study-path/" + trigger.portalProductId);
        return;
      }

      await this.$router.push("/my-courses/study/" + trigger.portalProductId);
    },
  },
  computed: {
    modalTitle() {
      switch (this.objective.type) {
        case GamificationObjectiveType.Challenge:
          return this.$getSettings("textGamificationChallenge");
        case GamificationObjectiveType.Streak:
          return this.$getSettings("textGamificationStreak");
        default:
          return this.$getSettings("textGamificationObjective");
      }
    },
  },
});

export declare class ObjectiveModalComponent {
  show: (id: number) => void;
  hide: () => void;
}

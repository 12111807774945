
import { AcademyCourseModel } from "@/services/models";
import { defineComponent } from "vue";
import { truncate } from "@/utils/filters";
import AcademyCourseButton from "@/components/courses/AcademyCourseButton.vue";

interface AcademyCourseViewModel {
  showCartModal: boolean;
}

export default defineComponent({
  components: { AcademyCourseButton },
  props: {
    course: Object as () => AcademyCourseModel,
    categoryId: Number,
  },
  data() {
    return {
      showCartModal: false,
    } as AcademyCourseViewModel;
  },
  methods: {
    truncate,
    detail(id: number) {
      this.$router.push(`/courses/${this.categoryId}/detail/${id}`);
    },
  },
  computed: {
    imageOrDefaultUrl(): string {
      return this.course?.imageUrl ? this.course.imageUrl : "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs=";
    },
  },
});

import { createApp } from "vue";
import services from "@/init/services";
import App from "./App.vue";
import router from "./init/router";
import store from "./init/store";

import "@popperjs/core";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "vue-multiselect/dist/vue-multiselect.css";
import "@/components/layout/styles.scss";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faStarHalf as fasStarHalf,
  faSave,
  faKey,
  faCloudDownloadAlt as fasCloudDownloadAlt,
  faExternalLinkAlt as fasExternalLinkAlt,
  faRoute as fasRoute,
  faCrown,
  faCertificate,
} from "@fortawesome/free-solid-svg-icons";
import { faStarHalf as farStarHalf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueCountdown from "@chenfengyuan/vue-countdown";
import VueClickAway from "vue3-click-away";

library.add(fasStarHalf, farStarHalf, faSave, faKey, fasCloudDownloadAlt, fasExternalLinkAlt, fasRoute, faCrown, faCertificate);

import tooltip from "v-tooltip";
import "v-tooltip/dist/v-tooltip.css";

tooltip.options.themes.tooltip.delay.show = 0;

createApp(App)
  .use(store)
  .use(router)
  .use(services)
  .use(tooltip)
  .use(VueClickAway)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("vue-countdown", VueCountdown)
  .mount("#app");


import { defineComponent } from "vue";
import { OrderDetailModel } from "@/services/models";
import moment from "moment";

interface OrderDetailViewModel {
  model: OrderDetailModel;
}

export default defineComponent({
  data() {
    return {
      model: new Object() as OrderDetailModel,
    } as OrderDetailViewModel;
  },
  async mounted() {
    if (!this.$route.params.id) this.$router.push(`/home`);

    const id = parseInt(this.$route.params.id.toString());

    this.model = await this.$api.orderService.getById(id);
  },
  computed: {
    paymentStatus(): string {
      const expr = this.$data.model.paymentStatusId;
      let output = "";
      switch (expr) {
        case 10:
          output = this.$store.getters.settings.textPendingPaymentStatus;
          break;
        case 20:
        case 30:
          output = this.$store.getters.settings.textPaidPaymentStatus;
          break;
        default:
          output = this.$store.getters.settings.textUnknowPaymentStatus;
      }

      return output;
    },
  },
  methods: {
    moment,
    detail(id: number) {
      this.$router.push(`/my-licenses/license/${id}`);
    },
    rePostPayment(id: number) {
      this.$api.orderService
        .rePostPayment(this.$data.model.id!)
        .then((result) => {
          if (result.paymentUrl) {
            window.location.href = result.paymentUrl!;
          } else {
            this.$router.push("/order/detail/" + result.orderId);
          }
        })
        .catch(() => {
          //empty
        });
    },
    orderHistory() {
      this.$router.push("/orders");
    },
    licenseHistory() {
      this.$router.push("/licenses");
    },
    async downloadInvoice() {
      await this.$api.orderService.downloadInvoice(this.$data.model.id!);
    },
  },
});


import { defineComponent } from "vue";
import CircleProgress from "@/components/progress/CircleProgress.vue";

export default defineComponent({
  components: { CircleProgress },
  props: {
    percents: Number,
  },
});


import { ProductTypes, UserCourseModel } from "@/services/models";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    course: Object as () => UserCourseModel,
    pathItemId: Number,
    locked: Boolean,
  },
  methods: {
    study(id: number) {
      if (this.pathItemId) {
        this.$router.push(`/study-path/study/${this.pathItemId}`);
        return;
      }

      if (this.course!.type === ProductTypes.Path) {
        this.$router.push(`/study-path/${id}`);
        return;
      }

      this.$router.push(`/my-courses/study/${id}`);
    },
  },
});

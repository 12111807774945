
import { GamificationLeaderboardItemModel } from "@/services/models";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      data: [] as GamificationLeaderboardItemModel[],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const response = await this.$api.gamificationService.leaderboard({
        search: "",
        page: 1,
        pageSize: 10,
      });
      this.data = response.data!;
    },
  },
  computed: {
    winners(): GamificationLeaderboardItemModel[] {
      return this.data.slice(0, 3);
    },
    others(): GamificationLeaderboardItemModel[] {
      return this.data.slice(3, this.data.length);
    },
  },
});

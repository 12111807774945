
import { ShoppingCartItemModel } from "@/services/models";
import { defineComponent } from "vue";
import ShoppingItem from "@/components/shoppingCart/ShoppingCartItem.vue";
import { truncate } from "@/utils/filters";

interface ShoppingCartPreviewViewModel {
  toggle: boolean;
}

export default defineComponent({
  components: { ShoppingItem },
  data() {
    return {
      toggle: false,
    } as ShoppingCartPreviewViewModel;
  },
  methods: {
    truncate,
    removeAllItems() {
      this.$store.dispatch("removeAllCartItems");
    },
    onClickAway(event: any) {
      this.toggle = false;
    },
    checkout() {
      this.toggle = false;
      this.$store.dispatch("showShoppingCart");
    },

    removeItem() {
      this.$store.dispatch("removeAllCartItems");
    },
  },
  computed: {
    isCartEmpty(): boolean {
      return this.$store.getters.isCartEmpty as boolean;
    },
    cart(): ShoppingCartItemModel[] {
      console.log(this.$store.getters.shoppingCart);
      return this.$store.getters.shoppingCart.items as ShoppingCartItemModel[];
    },
    cartItems(): number {
      return this.$store.getters.cartQuantity;
    },
    totalPrice(): string {
      return `${Number(this.$store.getters.shoppingCart.totalPriceInclTax).toFixed(2)} ${this.$store.getters.shoppingCart.currencyCode}`;
    },
    totalPriceExcludeVat(): string {
      return `${Number(this.$store.getters.shoppingCart.totalPriceExclTax).toFixed(2)} ${this.$store.getters.shoppingCart.currencyCode}`;
    },
  },
});


import { UserLicenseModel } from "@/services/models";
import { defineComponent } from "vue";
import moment from "moment";

export default defineComponent({
  props: {
    license: Object as () => UserLicenseModel,
  },
  methods: {
    moment,
    licenseDetail(id: number) {
      this.$router.push("/my-licenses/license/" + id);
    },
  },
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container page-dashboard pb-5" }
const _hoisted_2 = { class: "text-center my-5 page-title" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-8" }
const _hoisted_5 = {
  key: 0,
  class: "row row-cols-2"
}
const _hoisted_6 = { class: "col d-flex" }
const _hoisted_7 = { class: "col d-flex" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "col-md-4" }
const _hoisted_11 = {
  key: 0,
  class: "col-md-12"
}
const _hoisted_12 = {
  key: 1,
  class: "col-md-12"
}
const _hoisted_13 = { class: "row row-cols-1" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "col" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-md-5 d-flex" }
const _hoisted_18 = {
  key: 0,
  class: "col-md-7 d-flex"
}
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col-md-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_current_level = _resolveComponent("current-level")!
  const _component_badge_panel = _resolveComponent("badge-panel")!
  const _component_related_courses = _resolveComponent("related-courses")!
  const _component_events_panel = _resolveComponent("events-panel")!
  const _component_finished_courses_stat = _resolveComponent("finished-courses-stat")!
  const _component_average_score_stat = _resolveComponent("average-score-stat")!
  const _component_study_paths_panel = _resolveComponent("study-paths-panel")!
  const _component_study_time_chart = _resolveComponent("study-time-chart")!
  const _component_my_courses_panel = _resolveComponent("my-courses-panel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$getSettings("textDashboard")), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (this.$getSettings('gamificationEnabled'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_current_level, { class: "w-100" })
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_badge_panel, { class: "w-100" })
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_related_courses, {
              renderAsCard: true,
              maxCols: 3,
              showPlaceholders: true
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        (_ctx.$getSettings('eventsEnabled'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createVNode(_component_events_panel)
            ]))
          : _createCommentVNode("", true),
        (_ctx.progress)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_finished_courses_stat, {
                    percents: _ctx.progress.finishedPercents
                  }, null, 8, ["percents"])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_average_score_stat, {
                    percents: _ctx.progress.averageScore
                  }, null, 8, ["percents"])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_study_paths_panel, { class: "w-100" })
      ]),
      (_ctx.progress)
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
            _createVNode(_component_study_time_chart, {
              class: "w-100",
              chartData: _ctx.progress.chart
            }, null, 8, ["chartData"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_my_courses_panel)
      ])
    ])
  ]))
}
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "col-md-5" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "col-md-7" }
const _hoisted_5 = { class: "d-flex" }
const _hoisted_6 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RatingInfo = _resolveComponent("RatingInfo")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_user_course_button = _resolveComponent("user-course-button")!
  const _component_modal = _resolveComponent("modal")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_modal, {
    ref: "courseInfoModal",
    showFooter: false,
    title: _ctx.$store.getters.settings.textCourseInfo,
    size: 'lg'
  }, {
    "modal-body": _withCtx(() => [
      (_ctx.info)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                class: "img-fluid",
                src: _ctx.info.imageUrl
              }, null, 8, _hoisted_3)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h3", null, _toDisplayString(_ctx.info.name), 1),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_RatingInfo, {
                  noRatingText: _ctx.$store.getters.settings.textCourseNotRated,
                  rating: _ctx.info.rating
                }, null, 8, ["noRatingText", "rating"]),
                (_ctx.info.type === 'Path')
                  ? _withDirectives((_openBlock(), _createBlock(_component_font_awesome_icon, {
                      key: 0,
                      icon: "route"
                    }, null, 512)), [
                      [_directive_tooltip, _ctx.$store.getters.settings.textStudyPath]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", null, _toDisplayString(_ctx.info.estimatedTime), 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.pathItem?.description), 1),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_user_course_button, {
                  course: _ctx.info,
                  pathItemId: _ctx.pathItem?.id,
                  locked: _ctx.pathItem?.locked
                }, null, 8, ["course", "pathItemId", "locked"])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}
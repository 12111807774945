
import { defineComponent } from "vue";
import { ActivateAccountRequest } from "@/services/models";
import { useVuelidate } from "@vuelidate/core";
import { required, sameAs, minLength } from "@vuelidate/validators";

class ActivateAccountViewModel {
  form: ActivateAccountRequest;
  email: string;
  isValid: boolean;
  message: string;
  constructor() {
    this.form = {
      password: "",
      passwordAgain: "",
      tokenHash: "",
      knowMyPassword: false,
    };
    this.email = "";
    this.isValid = true;
    this.message = "";
  }
}

export default defineComponent({
  data() {
    return new ActivateAccountViewModel();
  },
  setup() {
    return { v$: useVuelidate() };
  },
  mounted() {
    if (!this.$route.params.token) {
      return;
    }

    this.$api.accountService
      .verifyActivateLink(this.$route.params.token.toString())
      .then((data) => {
        this.email = data.email!;
        this.form.tokenHash = this.$route.params.token.toString();
      })
      .catch(() => {
        this.isValid = false;
      });
  },
  methods: {
    send() {
      this.clearMessage();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.$api.accountService
          .activate(this.form)
          .then((result) => {
            localStorage.setItem("authUser", JSON.stringify(result));
            this.$store.commit("loginSuccess", result);
            this.$store.dispatch("loadUserProgress");
            this.$router.push({ name: "Home" });
          })
          .catch(() => {
            this.message = this.$store.getters.settings.textActivateAccountWrongPassword;
          });
      }
    },
    clearMessage() {
      this.message = "";
    },
  },
  validations() {
    const passwordAgainValidation = this.form.knowMyPassword
      ? {}
      : {
          required,
          sameAsPassword: sameAs(this.form.password),
        };

    return {
      form: {
        password: {
          required,
          minLength: minLength(6),
        },
        passwordAgain: passwordAgainValidation,
      },
    };
  },
});

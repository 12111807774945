import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "modal-content",
  class: "modal-content",
  style: {"margin-left":"20px","width":"70%"}
}
const _hoisted_2 = { class: "modal-body d-flex flex-column justify-content-between" }
const _hoisted_3 = { id: "items" }
const _hoisted_4 = {
  class: "nav nav-tabs",
  id: "myTab",
  role: "tablist"
}
const _hoisted_5 = {
  class: "nav-item",
  role: "presentation"
}
const _hoisted_6 = {
  class: "nav-item",
  role: "presentation"
}
const _hoisted_7 = {
  class: "tab-content mt-3 overflow-auto",
  id: "cart-item-list"
}
const _hoisted_8 = {
  class: "tab-pane fade show active",
  id: "buy-for-me",
  role: "tabpanel",
  "aria-labelledby": "buy-for-me-tab"
}
const _hoisted_9 = {
  class: "tab-pane fade",
  id: "buy-for-others",
  role: "tabpanel",
  "aria-labelledby": "buy-for-others-tab"
}
const _hoisted_10 = { id: "paymentMethods mb-3" }
const _hoisted_11 = {
  key: 0,
  class: "cart-totals"
}
const _hoisted_12 = { class: "pt-3 preview-total-exclude-vat mt-auto d-flex justify-content-between" }
const _hoisted_13 = { class: "price" }
const _hoisted_14 = { class: "preview-total-include-vat mt-auto d-flex justify-content-between" }
const _hoisted_15 = { class: "total-price-title" }
const _hoisted_16 = { class: "total-price" }
const _hoisted_17 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_shopping_item = _resolveComponent("shopping-item")!
  const _component_payment_method_component = _resolveComponent("payment-method-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", _hoisted_5, [
            _createElementVNode("a", {
              class: "nav-link active",
              id: "buy-for-me-tab",
              "data-bs-toggle": "tab",
              href: "#buy-for-me",
              role: "tab",
              "aria-controls": "home",
              "aria-selected": "true",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Choose(false)))
            }, _toDisplayString(_ctx.textBuyOneLicense), 1)
          ]),
          _createElementVNode("li", _hoisted_6, [
            _createElementVNode("a", {
              class: "nav-link",
              id: "buy-for-others-tab",
              "data-bs-toggle": "tab",
              href: "#buy-for-others",
              role: "tab",
              "aria-controls": "profile",
              "aria-selected": "false",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Choose(true)))
            }, _toDisplayString(_ctx.textBuyMultipleLicense), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cart, (item) => {
              return (_openBlock(), _createBlock(_component_shopping_item, {
                key: item.id,
                item: item
              }, null, 8, ["item"]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cart, (item) => {
              return (_openBlock(), _createBlock(_component_shopping_item, {
                allowChangeQuantity: true,
                key: item.id,
                item: item
              }, null, 8, ["item"]))
            }), 128))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        (Array.isArray(_ctx.cart) && _ctx.cart.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", null, _toDisplayString(_ctx.textTotalExclTax), 1),
                _createElementVNode("div", null, [
                  _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.totalPriceExclVat), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.textTotalInclTax), 1),
                _createElementVNode("div", null, [
                  _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.totalPriceInclVat), 1)
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentMethods, (item) => {
          return (_openBlock(), _createBlock(_component_payment_method_component, {
            key: item.id,
            item: item,
            onMethodSelected: _ctx.PaymentMethodSelected
          }, null, 8, ["item", "onMethodSelected"]))
        }), 128)),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("button", {
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.SendOrder()), ["stop"])),
            class: _normalizeClass([this.$data.isPaymentMethodSelected ? '' : 'disabled', "btn btn-primary"])
          }, _toDisplayString(_ctx.textConfirmOrderButton), 3)
        ])
      ])
    ])
  ]))
}
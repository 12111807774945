import router from "./router";
import { ApiClient } from "@/services/apiClient";
import { createStore, Store, useStore } from "vuex";
import { readonly } from "vue";
import {
  AcademyModel,
  AuthUserModel,
  UserProgressModel,
  ShoppingCartModel,
  ShoppingCartItemModel,
  CreateShoppingCartItem,
  UpdateShoppingCartItem,
  AddressModel,
  AcademySettingsModel,
} from "@/services/models";

export interface AppStateModel {
  academy: AcademyModel;
  user: AuthUserModel | null;
  progress: UserProgressModel | null;
  showShoppingCart?: boolean;
  shoppingCart: ShoppingCartModel;
  address: AddressModel;
}

const store = createStore<AppStateModel>({
  state: {
    academy: {},
    user: localStorage.getItem("authUser") ? (JSON.parse(localStorage.getItem("authUser")!) as AuthUserModel) : null,
    progress: null,
    showShoppingCart: false,
    shoppingCart: {},
    address: {},
  },
  mutations: {
    useShoppingCart(state, display: boolean) {
      console.log(state.showShoppingCart);
      if (state.showShoppingCart) {
        state.showShoppingCart = false;
      } else {
        state.showShoppingCart = true;
      }
    },
    initAcademySuccess(state, model: AcademyModel) {
      state.academy = model;
      const root = document.querySelector(":root") as HTMLElement;
      Object.entries(model.settings!).forEach(([key, value]) => {
        if (key.indexOf("image") >= 0 || key.indexOf("color") >= 0) {
          if (value?.indexOf("http") === 0) {
            root.style.setProperty(`--${key}`, `url(${value})`);
          } else {
            root.style.setProperty(`--${key}`, value);
          }
        }
      });

      document.title = state.academy.name ?? "Scormium Academy";

      if (state.academy.settings?.favicon) {
        const linkFavicon = document.createElement("link");
        linkFavicon.rel = "icon";
        linkFavicon.href = state.academy.settings?.favicon;
        document.head.appendChild(linkFavicon);
      }

      const headScripts = new DOMParser().parseFromString(`<html><head>${model.settings!.headScripts!}</head></html>`, "text/html");

      for (let i = 0; i < headScripts.scripts.length; i++) {
        const script = headScripts.scripts[i];
        const scriptTag = document.createElement("script");
        scriptTag.appendChild(document.createTextNode(script.innerHTML));
        document.head.appendChild(scriptTag);
      }
    },
    loginSuccess(state, model: AuthUserModel) {
      state.user = model;
      ApiClient.refreshClient();

      ApiClient.shoppingCartService.cart().then((result) => {
        state.shoppingCart = result;
      });
    },
    logoutSuccess(state) {
      state.user = null;
      state.progress = null;
      router.push({ name: "Login" });
    },
    setUserProgress(state, model: UserProgressModel) {
      state.progress = model;
    },
    UPDATE_CART_ITEMS(state, payload) {
      state.shoppingCart = payload;
    },
    updateAddress(state, address: AddressModel) {
      state.address = address;
    },
  },
  actions: {
    async initAcademy({ commit }) {
      const result = await ApiClient.academyService.init();

      if (!result) {
        await router.push("/404");
        return;
      }

      commit("initAcademySuccess", result);
    },
    logout({ commit }) {
      localStorage.removeItem("authUser");
      commit("logoutSuccess");
    },
    async loadUserProgress({ commit }) {
      const result = await ApiClient.userService.getProgress();
      commit("setUserProgress", result);
    },
    showShoppingCart({ commit }) {
      commit("useShoppingCart", true);
    },
    hideShoppingCart({ commit }) {
      commit("useShoppingCart", false);
    },
    /* shopping cart */
    async getCartItems({ commit }) {
      try {
        if (this.state.academy.allowEcommerce) {
          const result = await ApiClient.shoppingCartService.cart();
          commit("UPDATE_CART_ITEMS", result);
        }
      } catch (exception) {
        // empty
      }
    },
    async lastOrderAddress({ commit }) {
      try {
        const result = await ApiClient.orderService.lastOrderAddress();
        commit("updateAddress", result);
      } catch (exception) {
        // empty
      }
    },
    addCartItem({ commit }, shoppingCart: ShoppingCartModel) {
      commit("UPDATE_CART_ITEMS", shoppingCart);
    },
    async removeCartItem({ commit }, productId: number) {
      const request = new Object() as UpdateShoppingCartItem;
      request.forceDelete = true;
      request.quantity = 0;
      request.productId = productId;

      const response = await ApiClient.shoppingCartService.updateCart(request);
      commit("UPDATE_CART_ITEMS", response);
    },
    async removeAllCartItems({ commit }) {
      const emptyCart = new Object() as ShoppingCartModel;
      emptyCart.items = new Object() as ShoppingCartItemModel[];
      emptyCart.totalPriceExclTax = 0;
      emptyCart.totalPriceInclTax = 0;
      emptyCart.warnings = [];

      const response = await ApiClient.shoppingCartService.removeAllCart();

      commit("UPDATE_CART_ITEMS", emptyCart);
    },
  },
  modules: {},
  getters: {
    lmsHubToken: (state) => state.user?.lmsHubToken?.access_token,
    loggedIn: (state) => !!state.user,
    fullName: (state) => `${state.user?.firstName} ${state.user?.lastName}`,
    settings: (state) => state.academy.settings!,
    currentPortalId: (state) => {
      if (state.user) {
        return state.user.portalId;
      } else {
        return -1;
      }
    },
    loggedUserId: (state) => {
      if (state.user) {
        return state.user.userId;
      } else {
        return -1;
      }
    },
    paymentMethods: (state) => state.academy.paymentMethods!,
    showShoppingCart: (state) => state.showShoppingCart,
    shoppingCart: (state) => state.shoppingCart,
    address: (state) => state.address,
    allowEcommerce: (state) => state.academy.allowEcommerce!,
    isCartEmpty: (state) => {
      if (Array.isArray(state.shoppingCart.items) && state.shoppingCart.items.length) {
        return false;
      } else {
        return true;
      }
    },
    isProductInCart: (state) => (productId: number) => {
      try {
        const item = state.shoppingCart.items!.find((i) => i.productId === productId);
        console.log(item);
        if (item) return true;
        else return false;
      } catch (exception) {
        return false;
      }
    },

    cartQuantity: (state) => {
      return state.shoppingCart.items!.reduce((acc, cartItem) => {
        return 1 + acc;
      }, 0);
    },
  },
});

export default store;

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $store: Store<AppStateModel>;
  }
}

export const useAcademySettings = () => {
  const store = useStore<AppStateModel>();
  const settings = store.getters.settings as AcademySettingsModel | undefined;

  if (!settings) throw "Settings not initialized";

  const proxy = new Proxy(settings, {
    get: (obj: Record<string | symbol, unknown>, prop) => {
      /**
       * bug in TS (https://github.com/Microsoft/TypeScript/issues/24587)
       * should be solved in TS 4.4.0+
       */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (typeof prop === "symbol") return obj[prop];

      // pass VueJS stuff without checking
      if (prop.startsWith("__")) return obj[prop];

      if (prop in obj) return obj[prop];
      else {
        console.error(`Missing setting '${prop}'!`);
        return undefined;
      }
    },
  });

  return { settings: readonly(proxy) };
};

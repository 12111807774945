<script>
import { provide, ref } from "vue";

export default {
  name: "Tabs",
  setup() {
    const active = ref(0);
    const tabs = ref([]);

    function selectTab(tab) {
      this.active = tab;
    }

    provide("tabsState", {
      active,
      tabs,
    });

    return {
      tabs,
      active,
      selectTab,
    };
  },
};
</script>

<template>
  <ul class="nav nav-tabs" v-bind="$attrs">
    <li v-for="(tab, i) of tabs" :key="i" class="nav-item" @click="selectTab(i)">
      <a class="nav-link" :class="{ active: active === i }" href="#">
        <i :class="tab.props.icon" v-if="tab.props.icon"></i> {{ tab.props.title }}
      </a>
    </li>
  </ul>
  <div>
    <slot />
  </div>
</template>

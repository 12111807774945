
import { defineComponent } from "vue";
import SearchList from "@/components/shared/SearchList.vue";
import CategorySelect from "@/components/shared/CategorySelect.vue";
import { delay, delayCallback, ListOptions } from "@/utils/common";
import GuideList, { GuideListComponent } from "@/components/guides/GuideList.vue";
import { AcademySettingsModel } from "@/services/models";

interface GuidesViewModel {
  listOptions: ListOptions;
  searchDelay: delayCallback;
}

export default defineComponent({
  components: {
    SearchList,
    CategorySelect,
    GuideList,
  },
  data() {
    return {
      searchDelay: delay(450),
      listOptions: { categoryId: null, search: "" },
    } as GuidesViewModel;
  },
  async mounted() {
    if (!this.$store.getters.settings.guidesEnabled) {
      this.$router.push({ name: "Home" });
      return;
    }

    if (this.$route.query.search) this.listOptions.search = this.$route.query.search.toString();

    await this.load();
  },
  methods: {
    async load() {
      await this.guideList.load(this.listOptions);
    },
    searchGuides() {
      this.searchDelay(() => {
        this.guideList.setOptions(this.listOptions);
        this.guideList.reload();
      });
    },
  },
  computed: {
    guideList(): GuideListComponent {
      return this.$refs.guideList as GuideListComponent;
    },
    settings() {
      return this.$store.state.academy.settings as AcademySettingsModel;
    },
  },
  watch: {
    "listOptions.categoryId"() {
      this.load();
    },
  },
});

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-inline-flex fs-6 text-warning rating-stars", [{ 'not-rated': _ctx.rating === 0 }, _ctx.starSize]])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stars, (star) => {
      return (_openBlock(), _createElementBlock("i", {
        key: star,
        class: _normalizeClass([star, "bi me-1"])
      }, null, 2))
    }), 128))
  ], 2)), [
    [_directive_tooltip, _ctx.ratingText]
  ])
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { id: "shopping-cart-login" }
const _hoisted_2 = { class: "form-group border p-3" }
const _hoisted_3 = { class: "w-auto px-2" }
const _hoisted_4 = {
  key: 0,
  class: ""
}
const _hoisted_5 = {
  key: 1,
  class: "flex-fill"
}
const _hoisted_6 = { class: "shopping-cart-login-buttons d-flex justify-content-between" }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("fieldset", _hoisted_2, [
      _createElementVNode("legend", _hoisted_3, _toDisplayString(_ctx.textSignIn), 1),
      (!_ctx.$store.getters.loggedIn)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.login()), ["stop"])),
              class: "btn btn-primary me-3"
            }, _toDisplayString(_ctx.textSignInButton), 1),
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.register()), ["stop"])),
              class: "btn btn-secondary"
            }, _toDisplayString(_ctx.textRegistrationButton), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", null, [
                _createElementVNode("img", {
                  style: {"height":"50px"},
                  src: _ctx.avatarUrl,
                  alt: "mdo",
                  class: "rounded-circle avatar"
                }, null, 8, _hoisted_7),
                _createTextVNode(" " + _toDisplayString(_ctx.$store.getters.fullName), 1)
              ]),
              _createElementVNode("button", {
                type: "button",
                onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.logout()), ["prevent"])),
                class: "btn btn-primary font-weight-bold"
              }, _toDisplayString(_ctx.textSignOut), 1)
            ])
          ]))
    ])
  ]))
}

import { defineComponent } from "vue";
import Modal, { ModalComponent } from "@/components/shared/Modal.vue";
import { StudyPathItemModel, UserCourseModel } from "@/services/models";
import RatingInfo from "@/components/rating/RatingInfo.vue";
import UserCourseButton from "@/components/courses/UserCourseButton.vue";

export default defineComponent({
  data() {
    return {
      info: {} as UserCourseModel,
      pathItem: null as StudyPathItemModel | null,
    };
  },
  components: { Modal, RatingInfo, UserCourseButton },
  methods: {
    init(course: UserCourseModel, pathItem: StudyPathItemModel | null) {
      this.info = course;
      this.pathItem = pathItem;
    },
    show() {
      this.modal.showModal();
    },
  },
  computed: {
    modal(): ModalComponent {
      return this.$refs.courseInfoModal as ModalComponent;
    },
    description(): string {
      return this.pathItem?.description ?? this.info.description ?? "";
    },
  },
});

export declare class CourseInfoModalComponent {
  show: () => void;
  init: (course: UserCourseModel, pathItem: StudyPathItemModel | null) => void;
}


import { ChangePasswordRequest } from "@/services/models";
import useVuelidate from "@vuelidate/core";
import { minLength, required, sameAs } from "@vuelidate/validators";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      form: {
        currentPassword: "",
        newPassword: "",
        passwordAgain: "",
      } as ChangePasswordRequest,
      changed: false,
      error: "",
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        currentPassword: {
          required,
        },
        newPassword: {
          required,
          minLength: minLength(6),
        },
        passwordAgain: {
          sameAs: sameAs(this.form.newPassword),
        },
      },
    };
  },
  methods: {
    async change() {
      this.error = "";
      this.v$.$touch();

      if (this.v$.$invalid) return;

      try {
        await this.$api.accountService.changePassword(this.form);
        this.changed = true;
      } catch {
        this.error = this.$store.getters.settings.textChangePasswordNotChanged;
      }
    },
  },
});

import { AxiosInstance, CancelToken } from "axios";
import { AddressModel, ProductLicenseDetailModel } from "@/services/models";
import { createQueryString, ListRequest } from "@/utils/common";

export default class LicenseService {
  private api: AxiosInstance;
  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async getById(id: number, cancelToken?: CancelToken): Promise<ProductLicenseDetailModel> {
    const response = await this.api.get("/api/v1/license/" + id, {
      cancelToken,
    });
    return response.data;
  }

  async getAssigners(search: string, cancelToken?: CancelToken): Promise<Array<AddressModel>> {
    const response = await this.api.get("/api/v1/license/assigners/?search=" + search, {
      cancelToken,
    });
    return response.data;
  }

  async removeLicense(licenseId: number, cancelToken?: CancelToken): Promise<ProductLicenseDetailModel> {
    const response = await this.api.post("/api/v1/license/remove/" + licenseId, {
      cancelToken,
    });
    return response.data;
  }

  async activateLicense(token: string, cancelToken?: CancelToken): Promise<ProductLicenseDetailModel> {
    const response = await this.api.post("/api/v1/license/activate/" + token, {
      cancelToken,
    });
    return response.data;
  }

  async assingLicense(
    productId: number,
    assignerEmails: Array<AddressModel>,
    cancelToken?: CancelToken,
  ): Promise<ProductLicenseDetailModel> {
    const response = await this.api.post("/api/v1/license/assign/" + productId, assignerEmails, {
      cancelToken,
    });
    return response.data;
  }
}


import ShoppingCartAddress, { ShoppingCartAddressComponent } from "@/components/shoppingCart/ShoppingCartAddress.vue";
import ShoppingCartItemList from "@/components/shoppingCart/ShoppigCartItemList.vue";
import { PaymentMethodModel, CreateOrderRequest } from "@/services/models";
import { defineComponent } from "vue";
import { Modal } from "bootstrap";
import _ from "lodash";

export default defineComponent({
  components: { ShoppingCartAddress, ShoppingCartItemList },
  data() {
    return {
      id: _.uniqueId("modal"),
      modalInstance: null as Modal | null,
      buyMultipleLicences: false,
    };
  },
  props: {
    title: String,
    modelValue: Boolean,
    size: {
      type: String as () => "sm" | "lg" | "xl" | "",
      default: "xl",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    closeOnOutsideClick: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    const modalElement = document.getElementById(this.id);
    if (modalElement) {
      this.modalInstance = new Modal(modalElement);
      modalElement.addEventListener("hide.bs.modal", () => {
        this.$store.dispatch("hideShoppingCart");
      });
    }
  },
  computed: {
    show: {
      get(): boolean {
        return this.modelValue;
      },
      set(value: boolean) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.showModal();
      } else {
        this.hideModal();
      }
    },
  },
  methods: {
    multipleLicences(buyMultipleLicences: boolean) {
      this.buyMultipleLicences = buyMultipleLicences;
    },

    sendOrder(paymentMethod: PaymentMethodModel) {
      const shopppingAddress = this.$refs.shoppingCartAddress as ShoppingCartAddressComponent;
      const isAddressValid = shopppingAddress.validateAddress();

      let orderNote = shopppingAddress.getOrderNote();
      let purchaseOrder = shopppingAddress.getPurchaseOrder();

      if (isAddressValid) {
        const order = new Object() as CreateOrderRequest;
        order.addressModel = this.$store.getters.address;
        order.paymentMethodId = paymentMethod.id;
        // order.shoppingCartItems = this.$store.getters.shoppingCart;
        order.customerId = this.$store.getters.loggedUserId;
        order.currencyId = 1;
        order.buyMultipleLicences = this.buyMultipleLicences;
        order.orderNote = orderNote;
        order.purchaseOrder = purchaseOrder;

        this.$api.orderService
          .placeOrder(order)
          .then((result) => {
            if (result.paymentUrl) {
              window.location.href = result.paymentUrl!;
            } else {
              this.hideModal();
              this.$store.dispatch("getCartItems");
              this.$router.push("/order/detail/" + result.orderId);
            }
          })
          .catch(() => {
            //empty
          });
      }
    },
    showModal() {
      this.modalInstance?.show();
    },
    hideModal() {
      this.modalInstance?.hide();
    },
  },
  unmounted() {
    this.modalInstance?.dispose();
  },
});

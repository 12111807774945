
import { defineComponent } from "vue";
import Modal from "@/components/shared/Modal.vue";
import Rating from "@/components/rating/Rating.vue";
import { useVuelidate } from "@vuelidate/core";
import { minValue } from "@vuelidate/validators";

interface RateProductModalViewModel {
  id: number;
  rating: number;
  comment: string;
  loading: boolean;
  showModal: boolean;
  productName: string;
}

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    Modal,
    Rating,
  },
  data() {
    return {
      showModal: false,
      loading: false,
      productName: "",
      rating: 0,
      comment: "",
    } as RateProductModalViewModel;
  },
  methods: {
    add() {
      this.v$.$touch();

      if (this.v$.$invalid) return;

      this.$api.userService
        .rate(this.id, {
          score: this.rating,
          comment: this.comment,
        })
        .then(() => {
          //
          this.hide();
        })
        .catch(() => {
          //
        });
    },
    show(id: number, name: string) {
      this.id = id;
      this.productName = name;
      this.comment = "";
      this.rating = 0;
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
  },
  validations() {
    return {
      rating: {
        minValue: minValue(0.5),
      },
    };
  },
});

export declare class RateProductModalComponent {
  show: (id: number, name: string) => void;
  hide: () => void;
}

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 g-3" }
const _hoisted_2 = {
  key: 0,
  class: "h4 text-primary text-center mt-3 no-results-text"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_category = _resolveComponent("category")!
  const _component_pagination = _resolveComponent("pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
        return (_openBlock(), _createBlock(_component_category, {
          key: category.id,
          category: category,
          onClick: _withModifiers(($event: any) => (_ctx.selectCategory(category)), ["stop"])
        }, null, 8, ["category", "onClick"]))
      }), 128))
    ]),
    (_ctx.showNoResults && _ctx.showNoResultText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", { innerHTML: _ctx.noResultsText }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_pagination, {
        ref: "pagination",
        onReload: _ctx.paginationLoad,
        pageSize: _ctx.pageSize
      }, null, 8, ["onReload", "pageSize"])
    ])
  ], 64))
}

import { defineComponent, PropType } from "vue";
export default defineComponent({
  data() {
    return {
      circle: {} as SVGCircleElement,
      circumference: 0,
    };
  },
  props: {
    percent: Number,
    diameter: {
      type: Number,
      required: false,
      default: 200,
    },
    innerStrokeWidth: {
      type: Number,
      required: false,
      default: 10,
    },
    strokeWidth: {
      type: Number,
      required: false,
      default: 15,
    },
    type: {
      type: String as PropType<"primary" | "secondary" | "button" | "button-hover">,
      default: "secondary",
    },
    center: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    radius(): number {
      return this.diameter / 2;
    },
    innerCircleDiameter(): number {
      return this.diameter - this.innerStrokeWidth * 2;
    },
    innerCircleRadius(): number {
      return this.innerCircleDiameter / 2;
    },
    innerCircleStyle() {
      return {
        width: `${this.innerCircleDiameter}px`,
      };
    },
    containerStyle(): Record<string, string> {
      return {
        height: `${this.diameter}px`,
        width: `${this.diameter}px`,
      };
    },
  },
  mounted() {
    this.circle = this.$refs.ringCircle as SVGCircleElement;
    const radius = this.circle.r.baseVal.value;
    this.circumference = radius * 2 * Math.PI;
    this.circle.style.strokeDasharray = `${this.circumference} ${this.circumference}`;
    this.circle.style.strokeDashoffset = `${this.circumference}`;

    if (this.percent) this.setProgress(this.percent);
  },
  methods: {
    setProgress(percent: number) {
      const offset = this.circumference - (percent / 100) * this.circumference;
      this.circle.style.strokeDashoffset = `${offset}`;
    },
  },
  watch: {
    percent() {
      if (this.percent) this.setProgress(this.percent);
    },
  },
});

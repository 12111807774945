
import { delay } from "@/utils/common";
import { Dropdown } from "bootstrap";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      search: "",
      delaySearch: delay(450),
    };
  },
  mounted() {
    this.searchDropdownElement.addEventListener("shown.bs.dropdown", () => {
      (this.$refs.inputSearch as HTMLElement).focus();
    });
  },
  computed: {
    searchDropdownElement(): HTMLElement {
      return document.getElementById("searchDropdown") as HTMLElement;
    },
    dropdown(): Dropdown | null {
      const element = document.getElementById("searchDropdown");
      return Dropdown.getInstance(this.searchDropdownElement);
    },
  },
  methods: {
    searchChanged() {
      this.delaySearch(() => {
        this.dropdown?.hide();
        const q = this.search;
        this.search = "";
        this.$router.push({ name: "Search", query: { q: q } });
      });
    },
  },
});

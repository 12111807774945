import { AxiosInstance, CancelToken } from "axios";
import { AcademyCourseModelListResponse, AcademyCourseDetailModel } from "@/services/models";
import { createQueryString, ListRequest } from "@/utils/common";

export default class AcademyCourseService {
  private api: AxiosInstance;
  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async getList(request: CoursesListRequest, cancelToken?: CancelToken): Promise<AcademyCourseModelListResponse> {
    const response = await this.api.get("/api/v1/academyCourses?" + createQueryString(request), {
      cancelToken,
    });
    return response.data;
  }

  async getDetail(id: number, cancelToken?: CancelToken): Promise<AcademyCourseDetailModel> {
    const response = await this.api.get(`/api/v1/academyCourses/${id}`, {
      cancelToken,
    });
    return response.data;
  }
}

interface CoursesListRequest extends ListRequest {
  search: string;
  categoryId?: number | null;
  tagId?: number | null;
}

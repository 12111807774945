import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { value: null }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("select", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tagId) = $event)),
    class: "form-select"
  }, [
    _createElementVNode("option", _hoisted_1, _toDisplayString(_ctx.$store.getters.settings.textSelectTag), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item) => {
      return (_openBlock(), _createElementBlock("option", {
        key: item.id,
        value: item.id
      }, _toDisplayString(item.name), 9, _hoisted_2))
    }), 128))
  ], 512)), [
    [_vModelSelect, _ctx.tagId]
  ])
}
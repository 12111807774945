
import { GamificationCurrentLevelModel } from "@/services/models";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      model: {} as GamificationCurrentLevelModel,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.model = await this.$api.gamificationService.currentLevel();
    },
  },
  computed: {
    progressPercent(): number {
      if (!this.model.points) return 0;

      const pointsDifference = (this.model.nextLevelPoints ?? 0) - (this.model.currentLevelPoints ?? 0);

      if (!pointsDifference) return 0;

      return Math.round(((this.model.points - (this.model.currentLevelPoints ?? 0)) / pointsDifference) * 100);
    },
    pointsToCompleteNextLevel(): number {
      return (this.model.nextLevelPoints ?? 0) - (this.model.points ?? 0);
    },
  },
});

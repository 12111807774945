
import { defineComponent } from "vue";
import SectionIntro from "@/components/home/SectionIntro.vue";
import SectionCourses from "@/components/home/SectionCourses.vue";
import SectionProgress from "@/components/home/SectionProgress.vue";
import { saveBackRoute } from "@/utils/common";

export default defineComponent({
  name: "Home",
  components: {
    SectionIntro,
    SectionCourses,
    SectionProgress,
  },
  mounted() {
    saveBackRoute();
  },
});


import moment from "moment";
import { ApiClient } from "@/services/apiClient";
import { EventInstanceDateModel, EventInstanceModel, EventInstanceShow, EventType } from "@/services/models";
import { first } from "lodash";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { formatDateTimeRange } from "./common";
import EventRegisterModal, { EventRegisterModalComponent } from "./EventRegisterModal.vue";
import { useAcademySettings } from "@/init/store";

type SelectedInstance = null | (Id<EventInstanceModel> & { available: null | number; past: boolean });

export default defineComponent({
  components: {
    EventRegisterModal,
  },
  props: {
    id: Number,
    eventId: {
      type: Number,
      required: true,
    },
    hideParticipants: Boolean,
  },
  emits: ["idchange", "register"],
  setup(props, { emit }) {
    const { settings } = useAcademySettings();

    const upcomingInstancesDates = ref([] as Id<EventInstanceDateModel>[]);
    const selectedInstanceId = ref(props.id);
    const selectedInstance = ref(null as SelectedInstance);
    const hasFailed = ref(false);
    const registerModal = ref(null as null | EventRegisterModalComponent);

    const loadInstancesDates = async () => {
      const response = await ApiClient.eventService.instancesDates({
        eventId: props.eventId,
        show: EventInstanceShow.Upcoming,
        page: 1,
        pageSize: 10,
      });

      upcomingInstancesDates.value = response.data;
    };

    const loadSelectedInstance = async (id: number) => {
      const response = await ApiClient.eventService.instance(id);

      if (response.eventId !== props.eventId) throw "Not found";

      const getAvailable = () => {
        if (response.hideCapacity) return null;

        const capacity = response.capacity ?? 0;
        const occupied = response.occupied ?? 0;

        const available = capacity - occupied;
        return available >= 0 ? available : 0;
      };

      const past = moment().diff(response.startDate) >= 0;
      selectedInstance.value = Object.assign(response, { available: getAvailable(), past });
    };

    onMounted(async () => {
      if (selectedInstanceId.value) {
        try {
          await Promise.all([loadInstancesDates(), loadSelectedInstance(selectedInstanceId.value)]);
        } catch {
          hasFailed.value = true;
        }
      } else {
        try {
          await loadInstancesDates();
          const firstInstance = first(upcomingInstancesDates.value);
          if (firstInstance) {
            await loadSelectedInstance(firstInstance.id);
            selectedInstanceId.value = firstInstance.id;
            emit("idchange", { id: firstInstance.id, init: true });
          }
        } catch {
          hasFailed.value = true;
        }
      }

      watch(selectedInstanceId, async (id) => {
        if (!id) return;
        if (selectedInstance.value?.id === id) return;

        emit("idchange", { id, init: false });

        try {
          await loadSelectedInstance(id);
        } catch {
          hasFailed.value = true;
        }
      });
    });

    const instancesDates = computed(() => {
      let dates = upcomingInstancesDates.value.map((x) => ({
        id: x.id!,
        date: formatDateTimeRange(x),
      }));

      if (selectedInstance.value && !upcomingInstancesDates.value.some((x) => x.id === selectedInstance.value?.id)) {
        const selectedInstanceDate = {
          id: selectedInstance.value?.id,
          date: formatDateTimeRange(selectedInstance.value),
        };

        dates = [selectedInstanceDate, ...dates];
      }

      return dates;
    });

    const register = () => {
      if (!registerModal.value || !selectedInstance.value) return;

      emit("register");

      registerModal.value.init(selectedInstance.value);
      registerModal.value.show();
    };

    const onRegistered = async () => {
      if (!selectedInstanceId.value) return;

      try {
        await loadSelectedInstance(selectedInstanceId.value);
      } catch {
        hasFailed.value = true;
      }
    };

    return { settings, hasFailed, selectedInstanceId, selectedInstance, EventType, instancesDates, registerModal, register, onRegistered };
  },
});

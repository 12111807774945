import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_menu = _resolveComponent("top-menu")!
  const _component_search_dropdown = _resolveComponent("search-dropdown")!
  const _component_shopping_cart_header_preview = _resolveComponent("shopping-cart-header-preview")!
  const _component_notifications_dropdown = _resolveComponent("notifications-dropdown")!
  const _component_user_menu = _resolveComponent("user-menu")!
  const _component_page_header = _resolveComponent("page-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_page_footer = _resolveComponent("page-footer")!
  const _component_shopping_cart = _resolveComponent("shopping-cart")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_page_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_top_menu),
        _createVNode(_component_search_dropdown),
        _createVNode(_component_shopping_cart_header_preview),
        _createVNode(_component_notifications_dropdown),
        _createVNode(_component_user_menu)
      ]),
      _: 1
    }, 512), [
      [_vShow, !_ctx.layoutOptions.maximizeContent]
    ]),
    _createVNode(_component_router_view, {
      key: _ctx.$route.path,
      class: _normalizeClass({ 'maximize-content': _ctx.layoutOptions.maximizeContent })
    }, null, 8, ["class"]),
    _withDirectives(_createVNode(_component_page_footer, null, null, 512), [
      [_vShow, !_ctx.layoutOptions.maximizeContent]
    ]),
    _createVNode(_Transition, { name: "modal" }, {
      default: _withCtx(() => [
        (!_ctx.loading)
          ? (_openBlock(), _createBlock(_component_shopping_cart, {
              key: 0,
              modelValue: this.$store.getters.showShoppingCart,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.$store.getters.showShoppingCart) = $event))
            }, null, 8, ["modelValue"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}
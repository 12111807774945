
import { AcademyCourseModel, SortOrder } from "@/services/models";
import { defineComponent } from "vue";
import AcademyCourse from "@/components/courses/AcademyCourse.vue";
import Pagination, { PaginationComponent } from "@/components/shared/Pagination.vue";
import { ListOptions } from "@/utils/common";

interface CourseListViewModel {
  courses: AcademyCourseModel[];
  loaded: boolean;
  listOptions: ListOptions;
}

export default defineComponent({
  components: { AcademyCourse, Pagination },
  emits: ["listLoaded"],
  props: {
    pageSize: Number,
    scrollTop: Boolean,
    showNoResultText: Boolean,
  },
  data() {
    return {
      listOptions: {},
      courses: [],
      loaded: false,
    } as CourseListViewModel;
  },
  methods: {
    async load(options: ListOptions) {
      this.setOptions(options);
      this.loadInternal();
    },
    async loadInternal() {
      const response = await this.$api.academyCourseService.getList({
        page: this.pagination.getPage(),
        pageSize: this.pagination.getPageSize(),
        search: this.listOptions.search ?? "",
        tagId: this.listOptions.tagId ?? null,
        categoryId: this.listOptions.categoryId ?? null,
        sortBy: "Name",
        sortOrder: SortOrder.Asc,
      });
      this.pagination.setTotal(response.total!);
      this.courses = response.data!;
      this.loaded = true;
      this.$emit("listLoaded", response.total!);
    },
    setOptions(options: ListOptions) {
      this.listOptions = options;
    },
    reload() {
      this.pagination.setPage(1);
    },
    async paginationLoad() {
      if (this.scrollTop) window.scrollTo(0, 0);
      await this.loadInternal();
    },
  },
  computed: {
    pagination(): PaginationComponent {
      return this.$refs.pagination as PaginationComponent;
    },
    showNoResults(): boolean {
      return this.courses.length === 0 && this.loaded;
    },
    noResultsText(): string {
      return this.$store.state.academy.settings?.textSearchBoxNoResults ?? "No courses found.";
    },
  },
});

export declare class CourseListComponent {
  load: (options: ListOptions) => void;
  reload: () => void;
  setOptions: (options: ListOptions) => void;
}


import { AcademyCourseDetailModel } from "@/services/models";
import { defineComponent } from "vue";
import Tabs from "@/components/shared/tabs/Tabs.vue";
import Tab from "@/components/shared/tabs/Tab.vue";
import RatingSummary from "@/components/rating/RatingSummary.vue";
import RatingList, { RatingListComponent } from "@/components/rating/RatingList.vue";
import CourseComments from "@/components/courses/CourseComments.vue";
import RatingSummaryInfo from "@/components/rating/RatingSummaryInfo.vue";
import AcademyCourseButton from "@/components/courses/AcademyCourseButton.vue";
import { saveBackRoute } from "@/utils/common";

export default defineComponent({
  components: {
    Tabs,
    Tab,
    RatingSummary,
    RatingList,
    CourseComments,
    RatingSummaryInfo,
    AcademyCourseButton,
  },
  data() {
    return {
      categoryId: null as number | null,
      courseId: 0,
      detail: {} as AcademyCourseDetailModel,
    };
  },
  async mounted() {
    if (
      (this.$route.name == "CourseDetail" && (!this.$route.params.id || !this.$route.params.courseId)) ||
      (this.$route.name === "MyCourseDetail" && !this.$route.params.courseId)
    ) {
      this.$router.push("/");
    }
    if (this.$route.params.id) {
      this.categoryId = parseInt(this.$route.params.id.toString());
    }
    this.courseId = parseInt(this.$route.params.courseId.toString());

    this.detail = await this.$api.academyCourseService.getDetail(this.courseId);

    saveBackRoute();

    if (this.detail.ratings) {
      this.courseRatingList.initList(this.detail.ratings);
    } else {
      this.courseRatingList.load();
    }

    if (this.detail.comments) {
      this.courseComments.initList(this.detail.comments);
    } else {
      this.courseComments.load();
    }
  },
  methods: {
    back() {
      if (this.categoryId) {
        this.$router.push(`/courses/${this.categoryId}`);
        return;
      }
      this.$router.push(`/my-courses`);
    },
  },
  computed: {
    courseRatingList() {
      return this.$refs.courseRatingList as RatingListComponent;
    },
    courseComments() {
      return this.$refs.courseComments as RatingListComponent;
    },
    imageOrDefaultUrl(): string {
      return this.detail?.imageUrl ? this.detail.imageUrl : "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs=";
    },
  },
});


import { defineComponent } from "vue";
import AcademyCourse from "@/components/courses/AcademyCourse.vue";
import { SortOrder, UserCourseModel } from "@/services/models";
import { elementsSameHeight } from "@/utils/common";

interface SectionCoursesViewModel {
  assignedCourses: UserCourseModel[];
  newCourses: UserCourseModel[];
}

export default defineComponent({
  components: { AcademyCourse },
  data() {
    return {
      assignedCourses: [],
      newCourses: [],
    } as SectionCoursesViewModel;
  },
  async mounted() {
    if (this.$store.getters.loggedIn) {
      if (this.$store.getters.settings.homepageSectionNewCoursesEnabled) {
        this.newCourses = (
          await this.$api.academyCourseService.getList({
            page: 1,
            pageSize: this.showTwoColumn ? 2 : 4,
            search: "",
            sortBy: "CreateDate",
            sortOrder: SortOrder.Desc,
          })
        ).data!;
      }
      if (this.$store.getters.settings.homepageSectionAssignedCoursesEnabled) {
        this.assignedCourses = (
          await this.$api.userService.getCoursesList({
            page: 1,
            pageSize: this.showTwoColumn ? 2 : 4,
            search: "",
            sortBy: "ActivationDate",
            sortOrder: SortOrder.Desc,
          })
        ).data!;
      }
    }
    this.$nextTick(() => {
      elementsSameHeight("course-item");
    });
  },
  computed: {
    showTwoColumn(): boolean {
      return (
        this.$store.getters.settings.homepageSectionAssignedCoursesEnabled && this.$store.getters.settings.homepageSectionNewCoursesEnabled
      );
    },
    hideSection(): boolean {
      return (
        !this.$store.getters.settings.homepageSectionAssignedCoursesEnabled &&
        !this.$store.getters.settings.homepageSectionNewCoursesEnabled
      );
    },
  },
});

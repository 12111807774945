
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    modelValue: String,
  },
  emits: ["update:modelValue", "callback"],
  methods: {
    focus() {
      (this.$refs.inputSearch as HTMLElement).focus();
    },
  },
  watch: {
    search() {
      this.$emit("callback");
    },
  },
  computed: {
    search: {
      get(): string {
        return this.modelValue ?? "";
      },
      set(value: string) {
        this.$emit("update:modelValue", value);
      },
    },
  },
});

export declare class SearchListComponent {
  focus: () => void;
}

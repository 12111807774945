
import { UpdateUserProfileRequest, UserProfileModel } from "@/services/models";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      form: {} as UserProfileModel,
      error: "",
      saved: false,
    };
  },
  async mounted() {
    this.form = await this.$api.accountService.getUserProfile();
  },
  validations() {
    return {
      form: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
      },
    };
  },
  methods: {
    async save() {
      this.error = "";
      this.saved = false;
      const { email, ...request } = this.form;
      try {
        await this.$api.accountService.updateUserProfile(request as UpdateUserProfileRequest);
        this.saved = true;
      } catch {
        this.error = "Profile was not updated.";
      }
    },
  },
});

import { createQueryString, ListRequest } from "@/utils/common";
import { AxiosInstance, CancelToken } from "axios";
import { NotificationDetailModel, NotificationModelListResponse, NotificationPanelModel } from "./models";

interface NotificationsListRequest extends ListRequest {
  opened: boolean;
}

export default class NotificationService {
  private api: AxiosInstance;
  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async getList(request: NotificationsListRequest, cancelToken?: CancelToken): Promise<NotificationModelListResponse> {
    const response = await this.api.get("/api/v1/notifications?" + createQueryString(request), { cancelToken });
    return response.data;
  }

  async getPanel(cancelToken?: CancelToken): Promise<NotificationPanelModel> {
    const response = await this.api.get("/api/v1/notifications/panel", {
      cancelToken,
    });
    return response.data;
  }

  async getDetailById(id: number, cancelToken?: CancelToken): Promise<NotificationDetailModel> {
    const response = await this.api.get("/api/v1/notifications/" + id, {
      cancelToken,
    });
    return response.data;
  }

  async markAsRead(id: number, cancelToken?: CancelToken): Promise<void> {
    await this.api.put(
      `/api/v1/notifications/${id}/markAsRead`,
      {},
      {
        cancelToken,
      },
    );
  }
}

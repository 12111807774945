
import { defineComponent } from "vue";
import { CourseCategoryModel } from "@/services/models";
import SearchList from "@/components/shared/SearchList.vue";
import TagSelect from "@/components/shared/TagSelect.vue";
import { delay, delayCallback, ListOptions, saveBackRoute } from "@/utils/common";
import CourseList, { CourseListComponent } from "@/components/courses/CourseList.vue";

interface CoursesViewModel {
  search: string;
  tagId: number | null;
  categoryId: number;
  category: CourseCategoryModel;
  searchDelay: delayCallback;
  listOptions: ListOptions;
}

export default defineComponent({
  components: {
    SearchList,
    TagSelect,
    CourseList,
  },
  data() {
    return {
      category: {},
      searchDelay: delay(450),
      listOptions: { tagId: null, search: "" },
    } as CoursesViewModel;
  },
  async mounted() {
    if (!this.$store.getters.settings.coursesEnabled) {
      this.$router.push({ name: "Home" });
      return;
    }

    saveBackRoute();

    if (this.$route.query.search) this.search = this.$route.query.search.toString();

    if (!this.$route.params.id) {
      this.backToLibrary();
      return;
    }

    this.listOptions.categoryId = parseInt(this.$route.params.id.toString());

    this.category = await this.$api.academyService.getCourseCategory(this.listOptions.categoryId);

    await this.load();
  },
  methods: {
    async load() {
      await this.courseList.load(this.listOptions);
    },
    searchCourse() {
      this.searchDelay(() => {
        this.courseList.setOptions(this.listOptions);
        this.courseList.reload();
      });
    },
    backToLibrary() {
      if (this.category.parentCategoryId) {
        this.$router.push("/category/" + this.category.parentCategoryId);
        return;
      }

      this.$router.push("/courses");
    },
  },
  computed: {
    courseList(): CourseListComponent {
      return this.$refs.courseList as CourseListComponent;
    },
  },
  watch: {
    "listOptions.tagId"() {
      this.load();
    },
  },
});

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventInstancePanel = _resolveComponent("EventInstancePanel")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    ref: "modal",
    title: _ctx.settings.textEventsSelectInstance,
    size: 'md',
    closeOnOutsideClick: true,
    hideCloseButton: false,
    showFooter: false,
    class: "eventModal"
  }, {
    "modal-body": _withCtx(() => [
      (_ctx.id && _ctx.instanceId)
        ? (_openBlock(), _createBlock(_component_EventInstancePanel, {
            id: _ctx.instanceId,
            eventId: _ctx.id,
            hideParticipants: true,
            key: _ctx.instanceId
          }, null, 8, ["id", "eventId"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { scope: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("th", _hoisted_1, [
      _createVNode(_component_router_link, {
        to: { name: 'OrderDetail', params: { id: _ctx.order.id } }
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.order.customerOrderNumber), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("td", null, _toDisplayString(_ctx.order.createdOnUtc ? _ctx.moment(_ctx.order.createdOnUtc).format("DD. MM. YYYY") : "N/A"), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.order.orderedItemCount), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.order.paymentStatus), 1),
    _createElementVNode("td", null, _toDisplayString(_ctx.order.formatedPrice), 1),
    _createElementVNode("td", null, [
      _createElementVNode("button", {
        class: "btn btn-primary",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.orderDetail(_ctx.order.id)), ["stop"]))
      }, _toDisplayString(_ctx.$store.getters.settings.textOrderDetailButton), 1)
    ])
  ]))
}

import { defineComponent } from "vue";
import Multiselect from "vue-multiselect";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required, email, sameAs } from "@vuelidate/validators";
import { AcademySettingsModel, GroupModel, RegisterModel, RegisterRequest, RegisterResult } from "@/services/models";
import { load, ReCaptchaInstance, getInstance } from "recaptcha-v3";

interface RegistrationViewModel {
  model: RegisterRequest;
  init: RegisterModel;
  selectedGroups: GroupModel[];
  registered: boolean;
  exists: boolean;
  captcha: ReCaptchaInstance | null;
  error: string;
}

const isDomain = (domainList?: string[] | null) =>
  helpers.withParams(
    { type: "isDomain", value: domainList },
    (value) =>
      domainList?.some((item: string) => {
        const email = value as string;
        const result = email.endsWith(item);
        return result;
      }) ?? true,
  );

export default defineComponent({
  data() {
    return {
      model: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        reCaptchaToken: "",
      },
      init: { groups: [] },
      selectedGroups: [],
      registered: false,
      exists: false,
      captcha: null,
      error: "",
    } as RegistrationViewModel;
  },
  setup() {
    return { v$: useVuelidate() };
  },
  components: { Multiselect },
  async mounted() {
    await this.registerInit();

    this.captcha = getInstance();

    if (!this.captcha) {
      this.captcha = await load(process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY!, {
        autoHideBadge: true,
      });
    }

    this.captcha.showBadge();
  },
  unmounted() {
    this.captcha?.hideBadge();
  },
  methods: {
    async send() {
      this.v$.$touch();

      if (this.v$.$invalid) return;
      const { groupIds, ...request } = this.model;

      const captchaToken = (await this.captcha?.execute("login")) ?? "";

      const result = await this.$api.accountService.register({
        ...request,
        groupIds: this.selectedGroups?.map((m) => m.id!) ?? [],
        reCaptchaToken: captchaToken,
      });

      if (result == RegisterResult.Exists) {
        this.exists = true;
        return;
      }

      if (result == RegisterResult.Success) {
        this.registered = true;
        return;
      }

      switch (result) {
        case RegisterResult.ReCaptchaNotValid:
          this.error = "Captcha is not valid.";
          break;
        default:
          this.error = "An error occurred during registration.";
          break;
      }
    },
    async registerInit() {
      this.init = await this.$api.accountService.registerInit();

      this.selectedGroups = this.init.groups?.filter((f) => f.preSelected === true) ?? [];
    },
  },
  computed: {
    domainsText(): string {
      return this.init.loginRestriction?.join(",") ?? "";
    },
    isAllowedDomain(): boolean {
      return (
        this.init.loginRestriction?.some((item: string) => {
          return this.model.email.endsWith(item);
        }) ?? true
      );
    },
    settings(): AcademySettingsModel {
      return this.$store.state.academy.settings!;
    },
  },
  validations() {
    const passwordValidation = this.isAllowedDomain
      ? {
          required,
        }
      : {};

    const confirmPasswordValidation = this.isAllowedDomain
      ? {
          required,
          sameAsPassword: sameAs(this.model.password),
        }
      : {};

    return {
      model: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        email: {
          required,
          email,
          //domain: isDomain(this.init.loginRestriction),
        },
        password: passwordValidation,
        confirmPassword: confirmPasswordValidation,
      },
    };
  },
});
